import analyticsEvents from '../constants/analyticsEvents';
import socketEvents from '../constants/socketEvents';
import configSocket from '../helpers/environment';
import logger from '../helpers/logger';
import { updateMarketplaceButtonData } from '../redux/actions/marketplace.actions';
import { updateSearchSettings } from '../redux/actions/search.actions';
import { setSocketInitialized, setSocketReceipt } from '../redux/actions/socket.actions';
import analytics from './analytics.service';
import storage from './localStorage.service';
import { locationAPI } from './restapi.service';
import {initUserConfig, marketplacesWithRelevancy} from './user.service';
import axios from 'axios';

let socket;

export const send = (route, message) =>
  socket?.send(
    JSON.stringify({
      route,
      ...message,
    })
  );

const onSearchMessage = (message, dispatch) => {
  const marketplaces = marketplacesWithRelevancy.getMarketplaces();
  const results = message.data.map(result => ({ ...result, relevancyScore: marketplaces[result.agent], term: message.term }));
  dispatch(updateMarketplaceButtonData(message));

  if (results === 'none') {
    return;
  }

  const tags = message.tags.map((item) => ({ ...item, tag: item.tag.toLowerCase() }));
  dispatch(updateSearchSettings({ searchResults: results, tags }));

  const prices = results.map((item) => +item.price);

  const totalValue = prices.reduce((acc, price) => acc + price, 0);
  const averagePrice = totalValue / results.length;

  const minPrice = Math.min(...prices);
  const maxPrice = Math.max(...prices);

  analytics.pushEvent(analyticsEvents.itemsReceived, {
    marketplace: message.store,
    item_count: message.data !== 'none' ? message.data.length : 0,
    items_total_value: totalValue,
    items_average_price: averagePrice,
    items_min_price: minPrice,
    items_max_price: maxPrice,
    category_found: receipt.getReceipt().label[0],
    probability: receipt.getReceipt().prob[0],
    category_given: receipt.getReceipt().category,
  });
};

const onReceiptMessage = ({ data }, dispatch) => {
  if (data?.route === socketEvents.search) {
    // eslint-disable-next-line no-unused-vars
    receipt.setReceipt(data);
    dispatch(setSocketReceipt(data));
  }
};

const sendUserDataIfExists = async () => {
  const token = storage.getUserJwtToken();

  if (token) {
    await send(socketEvents.userData, { token });
  }
};

const onConfigMessage = ({ data }, dispatch) => {
  initUserConfig(data, dispatch);
};

export const receipt = (function () {
  let receiptMessage = {};

  return {
    setReceipt(message) {
      try {
        const fullcats = message.fullcats[0];
        receiptMessage = {
          ...message,
          fullcats
        };
      } catch {
        receiptMessage = message
      }
    },

    getReceipt() {
      return receiptMessage;
    },
  };
})();

export const initSocket = (dispatch) => {
  socket = new WebSocket(configSocket.socketApiUrl);

  socket.onclose = () => {
    initSocket(dispatch);
    dispatch(setSocketInitialized(false));
  };

  return new Promise((resolve) => {
    socket.onopen = async () => {
      dispatch(setSocketInitialized(true));
      socket.onmessage = ({ data }) => {
        const message = JSON.parse(data);
        switch (message.route) {
          case socketEvents.search:
            return onSearchMessage(message, dispatch, receipt);
          case socketEvents.receipt:
            return onReceiptMessage(message, dispatch, receipt);
          case socketEvents.config:
            return onConfigMessage(message, dispatch);
          default:
            return null;
        }
      };

      await sendUserDataIfExists();
      resolve();
    };
  });
};

export const locate = (address = '') => {
  if (address) {
    const currentAddress = address.toLowerCase().includes('uk') ? address : `${address} UK`;

    logger.log(`socket.locate ${currentAddress}`);
    return locationAPI.getLocation('', '', currentAddress);
  }


  return new Promise((resolve, reject) =>
    navigator.geolocation.getCurrentPosition(
      (position) => resolve(locationAPI.getLocation(position.coords.latitude, position.coords.longitude)),
      reject
    )
  );
};

export const fetchResultCards = async () => {
  const  {data} = await axios.get('https://rumage.com/wp-json/wp/v2/resultcard/?acf_format=standard');
  return data
}
export const fetchInterstitials = async () => {
  const  {data} = await axios.get('https://rumage.com/wp-json/wp/v2/interstitial/?per_page=100&acf_format=standard');
  return data
}
