import { useEffect, useState } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { Card } from 'primereact/card';
import { InputText } from 'primereact/inputtext';
import { Slider } from 'primereact/slider';
import { locate } from 'services/api.service';
import { setSocketUserLocation } from 'redux/actions/socket.actions';
import helpers from 'helpers';
import locationQualities from 'constants/locationQualities';
import { showToast } from 'redux/actions/app.actions';
import notificationEvents from 'constants/notificationEvents';
import sadIcon from 'assets/img/sad-smile.png';
import StyledButton from './StyledButton/StyledButton';

import 'styles/locationFilter.css';
import {locationAPI} from '../services/restapi.service';
import {setAnonLocation} from '../services/locations';

const LOCATION_SEARCH_FIELD_SYMBOLS_MAX_LIMIT = 120;
const LOCATION_SEARCH_FIELD_SYMBOLS_MIN_LIMIT = 3;
const FULL_POST_CODE_MIN_LENGTH = 5;
const UNCONFIRMED_LOCATION_QUALITIES = [
  locationQualities.badPostcode,
  locationQualities.unverified,
  locationQualities.verifying,
  locationQualities.poor,
];
const GEOLOCATION_POSITION_ERROR = {
  PERMISSION_DENIED: 1,
};

const LocationFeedback = ({feedback, location, detectLocation}) => {
  switch (feedback) {
    case locationQualities.poor:
      return (
        <div className="current-location-button" onClick={detectLocation}>
          <div className="location-input-icon">
            <i className="pi pi-map-marker" onClick={detectLocation} />
          </div>
          <StyledButton
            className="location-button"
            label="Near me"
            useOnlyPropClass
          />
        </div>
      );
    case locationQualities.badPostcode:
      return (
        <div className="current-location-button">
          <div className="location-input-icon">
            <img src={sadIcon} alt="sad-icon" />
          </div>
          <span>Please provide a street address</span>
        </div>
      );
    case locationQualities.approximate:
      if (location.postcode.length < FULL_POST_CODE_MIN_LENGTH) {
        return (
          <div className="current-location-button">
            <span>Provide a full postcode for accurate results from all marketplaces.</span>
          </div>
        );
      }
      return null;
    case locationQualities.verifying:
      return (
        <div className="current-location-button">
          <div className="location-input-icon">
            <i className="pi pi-spin pi-spinner" />
          </div>
          <span>Checking address...</span>
        </div>
      );
    case locationQualities.unverified:
      return (
        <span>
          <span>Click to verify address</span>
        </span>
      );
    default:
      return null;
  }
};

const LocationCard = ({ userLocation, deleteLocation, slot, addingLocation, setAddingLocation }) => {
  const [location, setLocation] = useState(userLocation);
  const [mile, setMile] = useState(userLocation?.range ? userLocation.range : 50);
  const [address, setAddress] = useState(userLocation?.term ? userLocation.term : '');
  const [label, setLabel] = useState(helpers.makeLocationLabel(userLocation));
  const [feedback, setFeedback] = useState(helpers.getFeedbackFromLocation(userLocation));
  const {locations} = useSelector(state => state.socket.config.user)
  const dispatch = useDispatch();
  const socket = useSelector((state) => state.socket);
  const getCurrentLocation = () => {
    return new Promise((resolve, reject) =>
      navigator.geolocation.getCurrentPosition(
        (position) => resolve(locationAPI.getLocation(position.coords.latitude, position.coords.longitude)),
        reject
      )
    );}

  useEffect(()=>{
    slot === 1 && addingLocation &&  getCurrentLocation()
      .then(onLocate)
      .catch((error) => checkAccessGeodata(error));
  },[])

  useEffect(() => {
    if (feedback !== locationQualities.approximate) {
      return;
    }

    if (location.postcode.length < FULL_POST_CODE_MIN_LENGTH) {
      setLabel('');
    }
  }, [feedback, location])
  const handleLocationAddress = (e) => {
    if (e.target.value.length <= LOCATION_SEARCH_FIELD_SYMBOLS_MAX_LIMIT) {
      setAddress(e.target.value);
    }
    if (e.target.value === '') {
      setFeedback(locationQualities.poor);
      setLabel('');
    }
  };

  const changeRange = (e) => {
    setMile(e.value);
    setLocation({ ...location, range: e.value });
  };

  const checkAccessGeodata = (error) => {
    if (error.code === GEOLOCATION_POSITION_ERROR.PERMISSION_DENIED) {
      dispatch(
        showToast({
          text: 'To automatically determine your location, please change the location settings for this site.',
          type: notificationEvents.warn,
        }),
      );
      setFeedback(locationQualities.poor);
    } else {
      setFeedback(locationQualities.badPostcode);
    }
  };
  const onLocate = ({ data }) => {
    const range = data.range ? data.range : mile;
    const updatedLocation = {
      ...location, ...data, range, postcode: helpers.formatPostcode(data.postcode),
    };

    if (locations.find(location=> location.postcode === updatedLocation.postcode)) {
      dispatch(showToast({ text: 'You already have set this location.', type: notificationEvents.error }));
      setLocation('')
      setAddress('')
      setFeedback(locationQualities.poor)
    }
    else{
      setLocation(updatedLocation);
      setFeedback(helpers.getFeedbackFromLocation(updatedLocation));
      setAddress(updatedLocation.term);
      if (data.postcode) {
        setAnonLocation(socket, updatedLocation)
        dispatch(setSocketUserLocation({ data: updatedLocation, slot }));
      }
      setAddingLocation(false)
    }
  };

  const detectLocation = () => {
    if (location && location.term === address && address) {
      return;
    }
    setFeedback(locationQualities.verifying);
    if (helpers.isAPostcode(address) && !helpers.validatePostcode(address)) {
      setFeedback(locationQualities.badPostcode);

      return;
    }

    locate(address)
      .then(onLocate)
      .catch((error) => checkAccessGeodata(error));
  };

  const blurAddress = async () => {
    if (address.length < LOCATION_SEARCH_FIELD_SYMBOLS_MIN_LIMIT) {
      setFeedback(locationQualities.badPostcode);
      return;
    }

    detectLocation();
  };

  const updateCurrentLocation = () => {
    const updatedLocation = { ...location, range: mile };

    setLocation(updatedLocation);

    if (location.postcode) {
      dispatch(setSocketUserLocation({ data: updatedLocation, slot }));
      document.cookie = `location=${JSON.stringify(updatedLocation)}`;
    }
  };

  return (
    <Card className="locations-wrapper location-card">
      <button
        type="button"
        onClick={() => deleteLocation(slot)}
        className="location-close-button clear-button"
      >
        <i className="pi pi-times-circle" />
      </button>
      <div className="location-filter-field">
        <div className="location-input-wrapper">
          <div className="location-input">
            <div className="location-input-icon">
              <i className="pi pi-map-marker" />
            </div>
            <div className="location-input-text">
              <InputText
                className={`address-input ${
                  UNCONFIRMED_LOCATION_QUALITIES.includes(feedback) ? '' : 'confirmed'
                }`}
                placeholder={`Street Address ${slot}`}
                value={address}
                onChange={(e) => handleLocationAddress(e)}
                onKeyUp={(e) => (e.key === 'Enter' ? blurAddress(address) : null)}
                onBlur={(e) => blurAddress(e.target.value)}
              />
              <span
                className={`address-indicator ${
                  UNCONFIRMED_LOCATION_QUALITIES.includes(feedback) ? '' : 'confirmed'
                }`}
              >
                <i className="pi pi-check-circle" />
              </span>
            </div>
          </div>
          {label ? <div className="address-label">{label}</div> : null}
          <LocationFeedback feedback={feedback} detectLocation={detectLocation}
            location={location} />
        </div>
        <div className="location-slider">
          <Slider
            value={mile}
            onChange={(e) => changeRange(e)}
            onSlideEnd={updateCurrentLocation}
            max={50}
            min={1}
          />
          <span>
            {mile}
            {' '}
            miles
          </span>
        </div>
      </div>
    </Card>
  );
};

export default LocationCard;
