import StyledButton from 'components/StyledButton/StyledButton';

import 'styles/searchFilter.css';
import orderValues from '../../constants/orderValues';
import {Checkbox} from 'primereact/checkbox';
import {useDispatch} from 'react-redux';
import {setSearchFilter} from '../../redux/actions/search.actions';
import {useState} from 'react';
import ReactDOM from 'react-dom';
import {Backdrop} from '../Backdrop/Backdrop';

const SortContainer = ({ sortFilterShown, toggleSortFilters }) => {
  const dispatch = useDispatch();
  const [selected, setSelected] = useState('');

  const orderOptions = [
    { name: 'Best Match', value: orderValues.bestMatch },
    { name: 'Price Low to High', value: orderValues.priceFromLowToHigh },
    { name: 'Price High to Low', value: orderValues.priceFromHighToLow },
    { name: 'Nearest', value: orderValues.distance },
    { name: 'Newest', value: orderValues.newest },
  ];

  const handleSorting = (e) => {
    if (selected === e.target.value) {
      setSelected('');
      dispatch(setSearchFilter(''));
    }
    else {
      setSelected(e.target.value);
      dispatch(setSearchFilter(e.target.value));
    }
  }

  const isChecked = (value) => {
    return selected === value;
  }

  return (
    <>
      {sortFilterShown && ReactDOM.createPortal(
        <Backdrop onBackdropClick={toggleSortFilters} />,
        document.getElementById('backdrop-root')
      )}
      <div key="filterContainer" className={`sorting-filters search-filter${sortFilterShown ? ' open' : ''}`}>
        <h3 className="filters-title">SORT</h3>
        <div className="filters-wrapper">
          <div className="agents-checkboxes">
            {orderOptions.map((order) => {
              return (
                <div key={order.name} className="sort-checkbox search-checkbox">
                  <Checkbox
                    className="filter-item-checkbox"
                    value={order.value}
                    checked={isChecked(order.value)}
                    onChange={handleSorting}
                  />
                  <div className="sorting-filter-entries">
                    <span>{order.name}</span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="filter-buttons-wrapper sorting">
          <StyledButton label="Done" className="filter-button" onClick={toggleSortFilters} useOnlyPropClass />
        </div>
      </div>
    </>
  );
};

export default SortContainer;
