import { useState } from 'react';
import { useSelector } from 'react-redux';
import { subscriptionManageApi } from 'services/restapi.service';
import analytics from 'services/analytics.service';
import analyticsEvents from 'constants/analyticsEvents';
import StyledButton from 'components/StyledButton/StyledButton';

import './SubscriptionManageButton.css';

const SubscriptionManageButton = ({
  label = 'Manage Subscription',
  chargebeeId,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const jwtToken = useSelector((state) => state.user.user.idToken?.jwtToken);
  const {
    hasSubscription,
    tier,
    username
  } = useSelector((state) => state.socket.config.user);

  const handleSubscriptionManageButton = () => {
    setIsLoading(true);

    analytics.pushEvent(analyticsEvents.launch, {
      rumage_user_id: username,
      target: hasSubscription ? 'chargebee_selfserve' : 'chargebee_checkout',
      tier,
    });

    subscriptionManageApi
      .getRedirectUrl(jwtToken, hasSubscription, chargebeeId)
      .then((url) => window.open(url, '_self'))
      .finally(() => setIsLoading(false));
  };

  return (
    <StyledButton
      disabled={isLoading}
      loading={isLoading}
      iconPos="right"
      label={label}
      className="subscription-manage-button"
      onClick={handleSubscriptionManageButton}
    />
  );
};

export default SubscriptionManageButton;
