export const isProduction = () => process.env.STAGE === 'production';

const config = {
  socketApiUrl: process.env.REACT_APP_SOCKET_API_URL,
  restApiUrl: process.env.REACT_APP_REST_API_URL,
  logoUrl: process.env.REACT_APP_REST_API_LOGO_URL,
  gtagCode: process.env.REACT_APP_GTAG_CODE,
  authConfigRegion: process.env.REACT_APP_AUTH_CONFIG_REGION,
  authConfigUserPoolId: process.env.REACT_APP_AUTH_CONFIG_USERPOOLID,
  authConfigUserPoolWebClientId: process.env.REACT_APP_AUTH_CONFIG_USERPOOLWEBCLIENTID,
  authConfigIdentityPoolId: process.env.REACT_APP_AUTH_CONFIG_IDENTITYPOOLID,
  facebookId: process.env.REACT_APP_FACEBOOK_ID,
  marketplaceSearchDelay: 20000,
  domainPrefix: process.env.REACT_APP_AUTH_CONFIG_DOMAIN_PREFIX,
  googleSecretKey: process.env.REACT_APP_GOOGLE_SECRET_KEY,
  env: process.env.REACT_APP_ENV,
};

export default config;
