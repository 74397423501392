const locationQualities = {
  poor: 'poor',
  badPostcode: 'bad postcode',
  approximate: 'approximate',
  good: 'good',
  rooftop: 'rooftop',
  verifying: 'verifying',
  unverified: 'unverified',
};

export default locationQualities;
