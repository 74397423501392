import { createAction } from '@reduxjs/toolkit';
import {
  SET_SOCKET_CONFIG,
  SET_SOCKET_USER_LOCATION,
  DELETE_USER_LOCATION_ITEM,
  SET_SOCKET_RECEIPT,
  CLEAR_SOCKET_REDUCER,
  SET_EMAIL_NOTIFICATION,
  SET_SOCKET_INITIALIZED,
} from 'redux/actions/types/socket.types';

export const setSocketConfig = createAction(SET_SOCKET_CONFIG);
export const setSocketUserLocation = createAction(SET_SOCKET_USER_LOCATION);
export const deleteUserLocation = createAction(DELETE_USER_LOCATION_ITEM);
export const setSocketReceipt = createAction(SET_SOCKET_RECEIPT);
export const clearSocketReducer = createAction(CLEAR_SOCKET_REDUCER);
export const setEmailNotification = createAction(SET_EMAIL_NOTIFICATION);
export const setSocketInitialized = createAction(SET_SOCKET_INITIALIZED);
