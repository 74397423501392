import { useHistory } from 'react-router';
import parseUrlQuery from '../helpers/parseUrlQuery';

const useUrlQuery = () => {
  const history = useHistory();
  const { location: { search: query } } = history;

  return parseUrlQuery(query);
};

export default useUrlQuery;
