import { createAction } from '@reduxjs/toolkit';
import {
  INIT_MARKETPLACE_BUTTONS,
  SET_INIT_MARKETPLACES_BUTTONS,
  UPDATE_MARKETPLACE_BUTTON_DATA,
  SET_MARKETPLACE_PANEL_VISIBILITY,
  SET_RESULTS_DEFAULT_VALUE,
  CLEAR_MARKETPLACE_PANEL_REDUCER,
} from 'redux/actions/types/marketplace.types';

export const initMarketplaceButtons = createAction(INIT_MARKETPLACE_BUTTONS);
export const setInitMarketplacesButtons = createAction(SET_INIT_MARKETPLACES_BUTTONS);
export const updateMarketplaceButtonData = createAction(UPDATE_MARKETPLACE_BUTTON_DATA);
export const setMarketplacePanelVisibility = createAction(SET_MARKETPLACE_PANEL_VISIBILITY);
export const setResultsDefaultValue = createAction(SET_RESULTS_DEFAULT_VALUE);
export const clearMarketplacePanelReducer = createAction(CLEAR_MARKETPLACE_PANEL_REDUCER);
