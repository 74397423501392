import { createSearchResultSet } from '../services/search.service';
import urlQueries from '../constants/urlQueries';
import base64Mapper from './base64Mapper';

const { search, searchId, equal, and, searchInfo } = urlQueries;
const createShareLink = (id, info) =>
  `${window.location.origin}${search}${searchId}${equal}${id}${and}${searchInfo}${equal}${encodeURIComponent(
    base64Mapper.mapToString(info)
  )}`;

const getLink = async (linkProps) => {
  const { linkType, jwtToken, id, searchState, term, submitted } = linkProps;
  const resultSet = await createSearchResultSet(jwtToken, id, searchState);

  return createShareLink(resultSet.id, { linkType, term, submitted });
};

export default getLink;
