import { setSocketConfig } from '../redux/actions/socket.actions';
import { initMarketplaceButtons } from '../redux/actions/marketplace.actions';

export const isAnon = (socket) => socket.config?.user.tier === 'anon';

export const getUserLocationsPostcode = (socket) => socket.config?.user.locations
  .map((loc) => (loc.postcode ? `${loc.postcode}@${loc.range || 50}` : null))
  .filter((item) => item);

export const marketplacesWithRelevancy = (function () {
  let marketplaces = {};

  return {
    setMarketplaces(message) {
      marketplaces = message;
    },

    getMarketplaces() {
      return marketplaces;
    },
  };
})();

export const initUserConfig = (userConfig, dispatch) => {
  marketplacesWithRelevancy.setMarketplaces(userConfig.service.marketplaces.reduce(
    (acc, val) => {
      acc[val.id] = Number(val.relevancyScore) || 1
      return acc;
    }, {})
  )
  dispatch(
    setSocketConfig({
      ...userConfig,
      user: {
        ...userConfig.user,
        marketplaces: userConfig.service.marketplaces.map((marketplace) => marketplace.id),
      },
    }),
  );
  dispatch(initMarketplaceButtons(userConfig.service.marketplaces));
};
