import StyledButton from './StyledButton/StyledButton';

import 'styles/shortcutButton.css';

const ShortcutButton = ({
  click,
  children,
  icon,
  tooltip,
  isSelected
}) => (
  <StyledButton
    className={`p-button-outlined shortcut-button main-color ${isSelected ? 'selected' : ''}`}
    icon={icon}
    iconPos="right"
    onClick={click}
    tooltip={tooltip}
    tooltipOptions={{ position: 'bottom' }}
    useOnlyPropClass
  >
    {children}
  </StyledButton>
);

export default ShortcutButton;
