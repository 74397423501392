import React, {useEffect, useState} from 'react';
import { Card } from 'primereact/card';

import '../styles/waitResults.css';
import {fetchInterstitials} from '../services/api.service';
import getRandomValue from '../helpers/getRandomValue';
import StyledButton from './StyledButton/StyledButton';
import analytics from '../services/analytics.service';
import analyticsEvents from '../constants/analyticsEvents';
import {useSelector} from 'react-redux';

const  WaitResults = () => {
  const [interstitial, setInterstitial] = useState({})
  const [hasVoted, setHasVoted]= useState(false)

  const { socket } = useSelector((state) => state);
  const username = socket.config?.user.username;

  const buttons = interstitial?.acf?.buttons || ''
  const buttonsArray = buttons ? buttons.split('\r\n') : []

  const onButtonClick = (value)=> {
    analytics.pushEvent(analyticsEvents.interstitial, {
      rumage_user_id : username || null,
      interstitialId: interstitial.id,
      event_label : `${buttons}?=${value}`
    });
    setHasVoted(true)
  }

  useEffect( ()=>{
    fetchInterstitials().then(res => {
      const randomNumber = getRandomValue(res.length)
      setInterstitial(res[randomNumber-1])
    })
  },[])

  return (
    <div className="wait-results-wrapper">
      <div>
        <i className="pi pi-spin pi-spinner" style={{fontSize: '2em'}}/>
      </div>
      <Card className="wait-results">
        <div className="wait-results-title">{interstitial?.title?.rendered}</div>
        <div className='wait-results-content' dangerouslySetInnerHTML={{ __html: interstitial?.content?.rendered }} />
        {buttonsArray.length > 0 &&
          <div className='wait-results-buttons'>
            {!hasVoted ?
              buttonsArray.map((button, index) =>
                (<StyledButton
                  key={index}
                  className='button'
                  value={button}
                  onClick={(e) => onButtonClick(e.target.value)}
                >
                  {button}
                </StyledButton>)
              )
              :
              <i className='pi pi-check-circle' style={{fontSize: '2.5rem', color: 'green'}} />
            }
          </div>
        }
      </Card>
    </div>)
};

export default WaitResults;
