export const SET_SEARCH_VALUE = 'SET_SEARCH_VALUE';
export const SET_SEARCH_RESULTS = 'SET_SEARCH_RESULTS';
export const CLEAR_SEARCH_RESULTS = 'CLEAR_SEARCH_RESULTS';
export const SET_SEARCH_SHOW_HISTORY = 'SET_SEARCH_SHOW_HISTORY';
export const UPDATE_SEARCH_SETTINGS = 'UPDATE_SEARCH_SETTINGS';
export const FILTER_SEARCH_AGENTS = 'FILTER_SEARCH_AGENTS';
export const FILTER_SEARCH_RESULTS = 'FILTER_SEARCH_RESULTS';
export const FILTER_SEARCH_LOCATIONS = 'FILTER_SEARCH_LOCATIONS';
export const SET_SEARCH_FILTER = 'SET_SEARCH_FILTER';
export const SET_SEARCH_PRICE_FILTER = 'SET_SEARCH_PRICE_FILTER';
export const SET_SEARCH_LOCATIONS = 'SET_SEARCH_LOCATIONS';
export const SET_SEARCH_AGENTS_ITEM = 'SET_SEARCH_AGENTS_ITEM';
export const SET_SEARCH_LOCATIONS_ITEM = 'SET_SEARCH_LOCATIONS_ITEM';
export const REMOVE_SEARCH_LOCATIONS_ITEM = 'REMOVE_SEARCH_LOCATIONS_ITEM';
export const SET_SEARCH_FILTERED_BY_MARKETPLACE_RESULTS = 'SET_SEARCH_FILTERED_BY_MARKETPLACE_RESULTS';
export const SET_SEARCH_AGENTS_ITEM_FROM_MARKETPLACE_PANEL = 'SET_SEARCH_AGENTS_ITEM_FROM_MARKETPLACE_PANEL';
export const CLEAR_SEARCH_REDUCER = 'CLEAR_SEARCH_REDUCER';
export const ADD_SHORTCUT_TAG = 'ADD_SHORTCUT_TAG';
export const REMOVE_SHORTCUT_TAG = 'REMOVE_SHORTCUT_TAG';
export const CLEAR_PRICE_FILTER = 'CLEAR_PRICE_FILTER';
export const SET_ALL_AGENTS_CHECKED_STATUS = 'SET_ALL_AGENTS_CHECKED_STATUS';
export const PUT_ALL_LOCATIONS_INTO_FILTERED_LOCATIONS = 'PUT_ALL_LOCATIONS_INTO_FILTERED_LOCATIONS';
export const APPLY_SEARCH_FILTERS = 'APPLY_SEARCH_FILTERS';
export const SHOW_SECOND_NUDGE_MESSAGE = 'SHOW_SECOND_NUDGE_MESSAGE';
export const SET_TFIDF_MATRIX = 'SET_TFIDF_MATRIX';
