import { Checkbox } from 'primereact/checkbox';
import { useDispatch, useSelector } from 'react-redux';
import {
  filterSearchLocations,
  setSearchLocationsItem,
  putAllLocationsIntoFilteredLocations,
} from 'redux/actions/search.actions';
import StyledButton from 'components/StyledButton/StyledButton';

import 'styles/searchFilter.css';
import routes from '../constants/routes';
import { useHistory } from 'react-router';

const FilterLocations = () => {
  const history = useHistory();
  const { search } = useSelector((store) => store);
  const { locations, filteredLocations } = search;
  const dispatch = useDispatch();

  const allLocationsButtonChecked = locations.length === filteredLocations.length;

  const updateFilterLocations = (location) => {
    dispatch(setSearchLocationsItem(location.postcode));
    dispatch(filterSearchLocations());
  };

  const toggleLocationsButton = (value) => () => {
    dispatch(putAllLocationsIntoFilteredLocations(value));
  };

  const renderItem = (item) => (
    <div className="search-checkbox" key={item.postcode}>
      <Checkbox
        inputId={`filter-location-checkbox-${item.term}`}
        className="filter-item-checkbox"
        value={item.postcode}
        checked={item.checked}
        onChange={() => updateFilterLocations(item)}
      />
      <label htmlFor={`filter-location-checkbox-${item.term}`} className={`filter-location-logo location-${item.term}`}>
        {item.city} {item.showIcon ? <div className="icon-delivery-white" /> : item.postcode}
      </label>
    </div>
  );

  const editLocationsClick = (event) => {
    event.preventDefault();
    history.push(routes.locations);
  };

  return (
    <div className="filter-section">
      <div className="filter-section-header location-section-title">
        <div>
          <span>Manage</span>
          <i className="pi pi-pencil" onClick={editLocationsClick} />
          {filteredLocations.length && !allLocationsButtonChecked ? (
            <span className="filterCount">({filteredLocations.length})</span>
          ) : null}
        </div>
        <div className="search-all-items">
          <StyledButton
            className="p-button-text"
            style={{fontFamily: 'NunitoSansRegular'}}
            label="All"
            onClick={toggleLocationsButton(true)}
            useOnlyPropClass
          />
          <StyledButton
            className="p-button-text"
            label="Clear"
            style={{fontFamily: 'NunitoSansRegular'}}
            onClick={toggleLocationsButton(false)}
            useOnlyPropClass
          />
        </div>
      </div>
      <div className="location-checkboxes">
        {locations.map((location) => {
          if (!location.postcode) {
            return false;
          }

          return renderItem(location);
        })}
      </div>
    </div>
  );
};

export default FilterLocations;
