import { useHistory } from 'react-router';

import 'styles/customButton.css';
import StyledButton from './StyledButton/StyledButton';

const BackButton = ({routeRedirect}) => {
  const history = useHistory();

  const handleBackButton = (event) => {
    event.preventDefault();
    if (!window.history.state){
      history.push(routeRedirect)
    } else {
      history.goBack();
    }
  };

  return (
    <StyledButton
      label="Back"
      icon="pi pi-angle-left"
      className="back-button"
      onClick={handleBackButton}
      useOnlyPropClass
    />
  );
};

export default BackButton;
