import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ToggleMenuContext from 'contexts/toggleMenuContext';
import { showToast } from 'redux/actions/app.actions';
import notificationEvents from 'constants/notificationEvents';
import systemMessages from 'constants/systemMessages';
import analytics from 'services/analytics.service';
import analyticsEvents from 'constants/analyticsEvents';
import getMailtoRef from 'helpers/getMailToRef';
import { shareLink } from 'services/facebook.service';
import { selectJwtToken, selectSearchState, selectSocketReceipt, selectTier } from 'redux/store/selectors';
import getLink from 'helpers/getLink';
import StyledButton from './StyledButton/StyledButton';

const shareSearchLinkTypes = {
  copyLink: 'copyLink',
  email: 'email',
  facebook: 'facebook',
  whatsApp: 'whatsApp',
};

const shareMailSubject = 'Look what I’ve just found on Rumage!';
const getSearchDescription = (searchTerm) => `Here’s what Rumage found today while searching for "${searchTerm}".`;
const getShareMailBody = (searchTerm, link) => `${getSearchDescription(searchTerm)}


Why buy new when you can Rumage for pre-loved treasure and save the planet’s resources. Let us do the leg work for you, while you get on with life?

Sign up, Rumage is free to use!

Be a savvy shopper, save the planet and save a packet!

The #GoRumage Team

${link}`;

const ShareSearchList = ({ itemLink }) => {
  const toggleMenu = useContext(ToggleMenuContext);
  const [link, setLink] = useState(null);
  const dispatch = useDispatch();
  const { searchId: id, term, submitted } = useSelector(selectSocketReceipt);
  const tier = useSelector(selectTier);
  const jwtToken = useSelector(selectJwtToken);
  const searchState = useSelector(selectSearchState);
  const { username } = useSelector(state => state.socket.config.user)

  const linkProps = {
    id,
    term,
    jwtToken,
    submitted,
    searchState,
  };

  const buttonStyle = {
    alignItems: 'center',
    justifyContent: 'center',
  };

  useEffect(() => {
    getLink({ ...linkProps, linkType: shareSearchLinkTypes.copyLink }).then(setLink);
  }, [id, term, jwtToken, submitted, searchState]);

  const copyLink = async () => {
    try {
      await navigator.clipboard.writeText(itemLink ? itemLink : link);
      dispatch(
        showToast({
          text: systemMessages.copyLinkSuccess,
          type: notificationEvents.success,
        })
      );
    } catch (error) {
      dispatch(showToast({ text: error.message, type: notificationEvents.error }));
    }
  };
  const sendEmail = async () => {
    const anchorElement = document.createElement('a');
    const link = itemLink ? itemLink : await getLink({ ...linkProps, linkType: shareSearchLinkTypes.email });

    anchorElement.href = getMailtoRef({
      subject: shareMailSubject,
      body: getShareMailBody(term, link),
    });

    anchorElement.click();
  };
  const shareFacebook = async () => {
    shareLink(
      itemLink ? itemLink : await getLink({ ...linkProps, linkType: shareSearchLinkTypes.facebook }),
      getSearchDescription(term)
    );
  };
  const shareWhatsApp = async () => {
    const link = itemLink ? itemLink : await getLink({ ...linkProps, linkType: shareSearchLinkTypes.whatsApp });

    window.open(`https://api.whatsapp.com/send/?text=${encodeURIComponent(getShareMailBody(term, link))}`, '_blank');
  };
  const pushAnalytics = (linkType) =>
    analytics.pushEvent(analyticsEvents.shareList, {
      rumage_user_id: username,
      search_term: term,
      channel: linkType,
      item_count: searchState.filteredSearchResults.length,
      tier,
    });
  const getCallbackWithLoadingState = (callback, linkType) => async () => {
    await callback();
    pushAnalytics(linkType);
    toggleMenu();
  };
  const shareLinksConfig = [
    {
      title: 'Facebook',
      id: shareSearchLinkTypes.facebook,
      icon: 'pi pi-facebook',
      color: '#4267B2',
      callback: getCallbackWithLoadingState(shareFacebook, shareSearchLinkTypes.facebook),
    },
    {
      title: 'WhatsApp',
      id: shareSearchLinkTypes.whatsApp,
      icon: 'pi pi-whatsapp',
      color: '#075E54',
      callback: getCallbackWithLoadingState(shareWhatsApp, shareSearchLinkTypes.whatsApp),
    },
    {
      title: 'Email',
      id: shareSearchLinkTypes.email,
      icon: 'pi pi-envelope',
      color: 'red',
      callback: getCallbackWithLoadingState(sendEmail, shareSearchLinkTypes.email),
    },
    {
      title: 'Copy Link',
      id: shareSearchLinkTypes.copyLink,
      icon: 'pi pi-external-link',
      color: 'black',
      callback: getCallbackWithLoadingState(copyLink, shareSearchLinkTypes.copyLink),
    },
  ];

  return (
    <>
      {shareLinksConfig.map((linkConfig) => {
        return (
          <StyledButton
            key={linkConfig.id}
            onClick={linkConfig.callback}
            className="p-button-secondary p-button-text"
            style={{ ...buttonStyle, color: linkConfig.color }}
            useOnlyPropClass
          >
            {linkConfig.title}&#160;&#160;
            <span className={linkConfig.icon} />
          </StyledButton>
        );
      })}
    </>
  );
};

export default ShareSearchList;
