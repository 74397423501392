import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import FormContainer from 'components/FormContainer';
import StyledButton from 'components/StyledButton/StyledButton';
import Form from 'components/Form';
import SocialButtons from 'components/SocialButtons';
import { showToast } from 'redux/actions/app.actions';
import { signup } from 'services/auth.service';
import routes from 'constants/routes';
import notificationEvents from 'constants/notificationEvents';
import analytics from 'services/analytics.service';
import analyticsEvents from 'constants/analyticsEvents';
import {
  emailFieldConfiguration,
  passwordFieldConfiguration,
  confirmPasswordFieldConfiguration,
} from 'constants/defaultFieldsConfigurations';
import systemMessages from 'constants/systemMessages';

import 'styles/customInput.css';
import FormParagraph from './FormParagraph/FormParagraph';

const fieldsConfig = [emailFieldConfiguration, passwordFieldConfiguration, confirmPasswordFieldConfiguration];

const Signup = ({ location }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const showBanner = location?.state?.showBanner;

  const handleSignup = async (data) => {
    if (data.password !== data.confirmPassword) {
      dispatch(showToast({ text: 'Password Confirmation Mismatch.', type: notificationEvents.error }));
      return;
    }

    const user = await signup(data, dispatch, history);

    if (!user) {
      return;
    }

    analytics.pushEvent(analyticsEvents.signup, {
      rumage_user_id: user.userSub
    });
    history.push(routes.confirmEmail);
  };

  const headerButton = (
    <StyledButton
      label="Login"
      onClick={() => history.push(routes.login)}
      className="login-signup-toggle-button"
      useOnlyPropClass
    />
  );

  return (
    <FormContainer
      formTitle="Sign up"
      bannerText={showBanner ? systemMessages.signUpBanner : null}
      headerButton={headerButton}
    >
      <FormParagraph>{systemMessages.signupMessage}</FormParagraph>
      <Form fieldsConfig={fieldsConfig} onSubmit={handleSignup} buttonLabel="Register">
        <FormParagraph>
          Password should have a mix of numbers, uppercase, lowercase and special characters.
        </FormParagraph>
        <FormParagraph>
          By registering, you accept our{' '}
          <a href="https://rumage.com/terms/" target="_blank" rel="noreferrer">
            Terms &amp; Conditions
          </a>{' '}
          and{' '}
          <a href="https://rumage.com/privacy/" target="_blank" rel="noreferrer">
            Privacy Policy
          </a>
        </FormParagraph>
      </Form>
      <FormParagraph className="align-center">or</FormParagraph>
      <SocialButtons />
    </FormContainer>
  );
};

export default Signup;
