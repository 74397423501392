import ItemCard from 'components/ItemCard/ItemCard';
import QuickView from 'components/QuickView/QuickView';
import makeId from 'helpers/makeId';
import withInitialization from 'higherOrderComponents/withInitialization';
import { useState } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { useLocation } from 'react-router';
import './Favorites.css';
import { isMobile } from 'react-device-detect';
import ItemCardMobile from '../ItemCard/ItemCardMobile';
import {deleteAllFavoriteItems} from '../../redux/actions/user.actions';
import {favoritesApi} from '../../services/restapi.service';

const Favorites = ({ limit, className, isMainPage }) => {
  const [showQuickView, setShowQuickView] = useState(false);
  const [quickViewInfo, setQuickViewInfo] = useState({});
  const favoriteItems = useSelector((state) => state.user.favoriteItems);
  const token = useSelector((state) => state.user.user.idToken?.jwtToken);
  const userId = useSelector((state) => state.socket.config?.user.username)
  const { pathname } = useLocation();
  const dispatch = useDispatch()
  const onCardClick = (itemInfo, marketPlaceInfo) => {
    setQuickViewInfo({ itemInfo, marketPlaceInfo });
    setShowQuickView(true);
  };
  const onClearAllClick = async () => {
    await favoritesApi.deleteAllFavoriteItems(userId, token)
    dispatch(deleteAllFavoriteItems());
  };

  return (
    <>
      {showQuickView && (
        <QuickView
          itemInfo={quickViewInfo.itemInfo}
          marketPlaceInfo={quickViewInfo.marketPlaceInfo}
          setShowQuickViewFalse={() => setShowQuickView(false)}
        />
      )}
      <div className={className || 'favorites-panel'}>
        {pathname !== '/favourites' ? <div className="recently-viewed__title-group">
          <h2>Favourites</h2>
          <p onClick={onClearAllClick}>Clear all</p>
        </div> : null}
        {favoriteItems.length ? (
          <div className="results-wrapper">
            {favoriteItems
              .map((item) => (
                <div key={makeId()} className="favorites-card-wrapper">
                  {isMobile && !isMainPage ? (
                    <ItemCardMobile
                      itemInfo={item.itemInfo}
                      marketPlaceInfo={item.marketPlaceInfo}
                      onClick={() => onCardClick(item.itemInfo, item.marketPlaceInfo)}
                    />
                  ) : (
                    <ItemCard
                      itemInfo={item.itemInfo}
                      marketPlaceInfo={item.marketPlaceInfo}
                      onClick={() => onCardClick(item.itemInfo, item.marketPlaceInfo)}
                    />
                  )}
                </div>
              ))
              .slice(0, limit || favoriteItems.length)}
          </div>
        ) : (
          <p style={{ padding: 20 }}>
            Just tap <span className="pi pi-fw pi-heart" style={{ color: '#d7007f' }} /> when you’ve tapped on an item
            to add it to your favourites
          </p>
        )}
      </div>
    </>
  );
};

export default withInitialization(Favorites);
