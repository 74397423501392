import orderValues from '../constants/orderValues';
import shippingTypes from '../constants/shippingTypes';
import keyboardKeys from '../constants/keyboardKeys';
import { setSearchValue } from '../redux/actions/search.actions';
import sortByRelevanceAndDistance, {
  sortByRelevanceAndDistanceBase, sortByRelevanceAndDistanceCosine, sortByRelevanceAndDistanceJW,
  sortByRelevanceAndDistanceLevenshtein, sortByRelevanceAndDistanceTrigram
} from './relevance';
import { distance } from './distance';
import locationQualities from 'constants/locationQualities';

const helpers = {
  pluralise(count, word) {
    const esEnding = ['search'];
    if (count !== 1) {
      if (esEnding.includes(word.toLowerCase())) {
        return `${word}es`;
      }
      return `${word}s`;
    }
    return word;
  },

  getMarketplaceInfo(marketplaces, agentNames) {
    return marketplaces.find((market) => market.id === agentNames);
  },

  handleKeyDownSearchHistoryEvent(
    event,
    historyRef,
    currentHistoryFocused,
    setCurrentHistoryFocused,
    searchInputId,
    dispatch
  ) {
    const elementsCount = historyRef.current?.children.length;
    let next = currentHistoryFocused;

    if (!elementsCount) {
      return;
    }

    if (event.key === keyboardKeys.arrowDown || event.key === keyboardKeys.arrowUp) {
      if (event.key === keyboardKeys.arrowDown) {
        next++;
      }
      if (event.key === keyboardKeys.arrowUp) {
        next--;
      }
      if (next < 0) {
        next = elementsCount - 1;
      }
      if (next >= elementsCount) {
        next = 0;
      }

      const el = historyRef.current?.children[next];

      if (!el) {
        return;
      }

      el.focus();
      el.scrollIntoView(false);
      setCurrentHistoryFocused(next);

      return;
    }

    if (event.target.id !== searchInputId && event.code !== keyboardKeys.enter) {
      const searchInput = document.getElementById(searchInputId);

      if (searchInput) {
        dispatch(setSearchValue(searchInput.value));
        searchInput.focus();
      }
    }
  },

  capitalise(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  },

  capitalizeFirstLetter(string) {
    return string.replace(/(^|\s)\S/g, (firstLatter) => firstLatter.toUpperCase());
  },

  stringf(string, replacements) {
    return replacements.reduce((p, c) => p.replace(/%s/, c), string);
  },

  getFrequency(frequency = '') {
    const frequencies = {
      hourly: 'hourly',
      ampm: 'twice daily',
      daily: 'daily',
      weekly: 'weekly',
    };
    return frequency === '' ? frequencies : frequencies[frequency];
  },

  veryifyPostcode(postcode) {
    const pattern = /^([A-Za-z][A-Ha-hJ-Yj-y]?[0-9][A-Za-z0-9]? ?[0-9][A-Za-z]{2}|[Gg][Ii][Rr] ?0[Aa]{2})$/gm;
    const valid = pattern.test(postcode);

    return valid;
  },

  sortProducts(results, type = orderValues.bestMatch, searchTerm, tfidfMatrix, ) {
    switch (type) {
      case orderValues.priceFromLowToHigh:
        return this.sortByPriceAsc(results);
      case orderValues.priceFromHighToLow:
        return this.sortByPriceDesc(results);
      case orderValues.distance:
        return distance(results);
      case orderValues.newest:
        return this.sortByNewest(results);
      case orderValues.bestMatch:
      default:
        return this.sortByRelevance(results, searchTerm, tfidfMatrix);
    }
  },

  getUniqueArrObjects(array) {
    return array.reduce((unique, item) => {
      const duplicateItem = unique.find((object) => object.tag === item.tag);

      if (!duplicateItem) {
        return [...unique, item];
      }

      unique.splice(unique.indexOf(duplicateItem), 1);

      return [...unique, { ...duplicateItem, ids: [...duplicateItem.ids, ...item.ids] }];
    }, []);
  },

  sortByPriceAsc(results) {
    return results.sort((a, b) => Number(a.price) - Number(b.price));
  },

  sortByPriceDesc(results) {
    return results.sort((a, b) => Number(b.price) - Number(a.price));
  },

  sortByNewest(results) {
    const getPostedTimestamp = (resultItem) => parseInt(resultItem.posted || 0, 10);

    return results.sort((left, right) => getPostedTimestamp(right) - getPostedTimestamp(left));
  },

  isTitleApplicableByTags(title, tags) {
    return !tags.length || tags.some((tag) => title.includes(tag));
  },

  increaseTitleMatchWeight(results, searchTerm) {
    return results.map(item => {
      if (item.title.toLowerCase().includes(searchTerm.toLowerCase())) {
        item.distance += 0.2;
      }
      return item;
    }).sort((a, b) => b.distance - a.distance)
  },

  selectSortingAndSort(results, searchTerm, tfidfMatrix) {
    const urlParams = new URLSearchParams(window.location.search);
    const sortingAlgorithm = urlParams.get('sort');
    switch (sortingAlgorithm) {
      case 'base':
        return sortByRelevanceAndDistanceBase(results, searchTerm)
      case 'levenshtein':
        return sortByRelevanceAndDistanceLevenshtein(results, searchTerm)
      case 'cosine':
        return sortByRelevanceAndDistanceCosine(results, searchTerm)
      case 'trigram':
        return sortByRelevanceAndDistanceTrigram(results, searchTerm)
      case 'jaroWinkler':
        return sortByRelevanceAndDistanceJW(results, searchTerm)
      case 'tdfid':
        return sortByRelevanceAndDistance(results, searchTerm, tfidfMatrix);
      default:
        return sortByRelevanceAndDistanceTrigram(results, searchTerm, tfidfMatrix);
    }
  },

  // Get an array sorted by relevance and distance
  sortByRelevance(results, searchTerm, tfidfMatrix) {
    const initialSorting = this.selectSortingAndSort(results, searchTerm, tfidfMatrix);
    return  this.increaseTitleMatchWeight(initialSorting, searchTerm);
  },

  getCookie(name) {
    function escape(s) {
      return s.replace(/([.*+?^${}()|[\]/\\])/g, '\\$1');
    }

    const match = document.cookie.match(RegExp(`(?:^|;\\s*)${escape(name)}=([^;]*)`));

    return match ? match[1] : null;
  },
  validatePostcode(postcode) {
    // eslint-disable-next-line
    return /^(GIR[ ]?0AA|((AB|AL|B|BA|BB|BD|BH|BL|BN|BR|BS|BT|CA|CB|CF|CH|CM|CO|CR|CT|CV|CW|DA|DD|DE|DG|DH|DL|DN|DT|DY|E|EC|EH|EN|EX|FK|FY|G|GL|GY|GU|HA|HD|HG|HP|HR|HS|HU|HX|IG|IM|IP|IV|JE|KA|KT|KW|KY|L|LA|LD|LE|LL|LN|LS|LU|M|ME|MK|ML|N|NE|NG|NN|NP|NR|NW|OL|OX|PA|PE|PH|PL|PO|PR|RG|RH|RM|S|SA|SE|SG|SK|SL|SM|SN|SO|SP|SR|SS|ST|SW|SY|TA|TD|TF|TN|TQ|TR|TS|TW|UB|W|WA|WC|WD|WF|WN|WR|WS|WV|YO|ZE)(\d[\dA-Z]?[ ]?\d[ABD-HJLN-UW-Z]{2}))|BFPO[ ]?\d{1,4})$/i.test(
      postcode
    );
  },
  isAPostcode(postcode) {
    return /^[A-Z]{1,2}[0-9][0-9A-Z]?\s?[0-9][A-Z]{2}$/i.test(postcode);
  },

  getItemCenter(item) {
    if (item.center) {
      return item.center.replace(/\s+/g, '');
    }
    return item.shipping === shippingTypes.collect ? shippingTypes.delivery : item.shipping;
  },

  formatPostcode(postcode) {
    return postcode ? postcode.replace(' ', '') : postcode;
  },

  setIntervalImmediately(func, interval) {
    func();
    return setInterval(func, interval);
  },

  isSaleTypeAuction(saleType) {
    return saleType?.toLowerCase() === 'auction';
  },

  makeLocationLabel(location) {
    return location && location.city && `${location.city} ${location.postcode}`;
  },

  getFeedbackFromLocation(location) {
    if (location?.quality) {
      return location?.quality.toLocaleLowerCase();
    }

    return location?.term ? locationQualities.badPostcode : locationQualities.poor;
  },
};

export default helpers;
