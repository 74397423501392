import { federatedSignIn } from 'services/auth.service';
import StyledButton from 'components/StyledButton/StyledButton';

import 'styles/socialButtons.css';

const socialNetworks = [
  { name: 'Facebook', icon: '/assets/img/icon-facebook.png' },
  { name: 'Google', icon: '/assets/img/icon-google.png' },
];

const SocialButtons = () => (
  <div className="social-buttons-wrapper">
    {socialNetworks.map((socialNetwork) => (
      <StyledButton
        className="social-button"
        onClick={() => federatedSignIn(socialNetwork.name)}
        key={socialNetwork.name}
        useOnlyPropClass
      >
        <img src={socialNetwork.icon} alt={socialNetwork.name} />
      </StyledButton>
    ))}
  </div>
);

export default SocialButtons;
