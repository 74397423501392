import validationMessages from './validationMessages';
import validationPatterns from './validationPatterns';

const passwordMinLength = 6;
export const requiredValidator = {
  value: true,
  message: validationMessages.required,
};
export const emailPatternValidator = {
  value: validationPatterns.email,
  message: validationMessages.format,
};
export const passwordMinLengthValidator = {
  value: passwordMinLength,
  message: `${validationMessages.minLength} ${passwordMinLength}`,
};
