import auth from '@aws-amplify/auth';
import notificationEvents from 'constants/notificationEvents';
import routes from 'constants/routes';
import systemMessages from 'constants/systemMessages';
import config from 'helpers/environment';
import getHost from 'helpers/getHost';
import { setUserAlerts } from 'redux/actions/alerts.actions';
import { setAppInitialized, showToast } from 'redux/actions/app.actions';
import { clearState } from 'redux/actions/global.actions';
import {initUser, setFavoriteItems, setUserHistory, setMappingFile} from 'redux/actions/user.actions';
import storage from 'services/localStorage.service';
import { alertAPI, configAPI, favoritesApi, historyApi } from 'services/restapi.service';
import { initUserConfig } from 'services/user.service';
import {setTfIdfMatrix} from 'redux/actions/search.actions';

auth.configure({
  region: config.authConfigRegion,
  userPoolId: config.authConfigUserPoolId,
  userPoolWebClientId: config.authConfigUserPoolWebClientId,
  identityPoolId: config.authConfigIdentityPoolId,
  oauth: {
    domain: `${config.domainPrefix}.auth.eu-west-1.amazoncognito.com`,
    scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
    redirectSignIn: `${getHost()}${routes.home}`,
    redirectSignOut: `${getHost()}${routes.login}`,
    responseType: 'code',
  },
  cookieStorage: {
    domain: config.env === 'local' ? 'localhost' : '.rumage.com',
    secure: true,
    path: '/',
    expires: 365,
  }
});

const transformEmail = (email) => email.toLowerCase();

const handleAuthRequest = async (request, successMessage, dispatch) => {
  try {
    const user = await request;
    showToast({
      text: successMessage,
      type: notificationEvents.success,
    });

    return user || {};
  } catch (error) {
    dispatch(showToast({ text: error.message, type: notificationEvents.error }));

    return null;
  }
};

export const resendSignup = async (email, dispatch) => {
  try {
    await auth.resendSignUp(transformEmail(email));
    dispatch(
      showToast({
        text: `New verification code sent to ${email}`,
        type: notificationEvents.info,
      })
    );

    return true;
  } catch (error) {
    return ;
  }
};

export const signup = async ({ email, password }, dispatch, history) => {
  try {
    return await auth.signUp({ username: transformEmail(email), password });
  } catch (error) {
    if (error.code === 'UsernameExistsException') {
      if (!(await resendSignup(email, dispatch))) {
        history.push('/login');
        dispatch(
          showToast({
            text: 'This email is already registered. Try another or login?',
            type: notificationEvents.error,
          })
        );
      }
      return;
    }

    const message = error.message.includes('Value at "password" failed to satisfy constraint')
      ? 'Please make your password more complex.'
      : error.message;

    dispatch(showToast({ text: message, type: notificationEvents.error }));

    return null;
  }
};

export const login = async ({ email, password }, dispatch) => {
  const user = await auth.signIn(transformEmail(email), password);
  if (!user) {
    return dispatch(
      showToast({
        text: 'No user found',
        type: notificationEvents.error,
      })
    );
  }

  storage.setIsUserLoggedIn(true);

  document.cookie = 'search=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  document.cookie = 'searchCount=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';

  return user;
};

export const confirm = ({ email, verificationCode }, dispatch) =>
  handleAuthRequest(
    auth.confirmSignUp(transformEmail(email), verificationCode),
    systemMessages.authVerificationSuccess,
    dispatch
  );

export const forgot = (username, dispatch) =>
  handleAuthRequest(auth.forgotPassword(transformEmail(username)), systemMessages.authForgotPasswordEmailSentSuccess, dispatch);

export const updateEmail = async (email, dispatch) => {
  const user = await auth.currentAuthenticatedUser({ bypassCache: true });
  try {
    return await auth.updateUserAttributes(user, { email });
  } catch (error) {
    dispatch(
      showToast({
        text: error.message,
        type: notificationEvents.error,
      })
    );
  }
};

export const confirmUpdatedEmail = async (verificationCode) =>
  auth.verifyCurrentUserAttributeSubmit('email', verificationCode);

export const reset = ({ email, resetCode, newPassword }, dispatch) =>
  handleAuthRequest(
    auth.forgotPasswordSubmit(transformEmail(email), resetCode, newPassword),
    systemMessages.authResetSuccess,
    dispatch
  );

export const init = async (dispatch) => {
  let userConfig = null;

  const { data: mappingFile } = await configAPI.getMappingFile();
  // configAPI.getSearchTermsWeight().then(tfidfMatrix => dispatch(setTfIdfMatrix(tfidfMatrix)));
  dispatch(setMappingFile(mappingFile));
  dispatch(setTfIdfMatrix({}));

  try {
    const { signInUserSession: user } = await auth.currentAuthenticatedUser({ bypassCache: true });
    userConfig = await configAPI.getConfig(user.idToken.jwtToken);
    dispatch(initUser(user));

    const { jwtToken } = user.idToken;
    const { data: favoriteItems } = await favoritesApi.getFavoriteItems(userConfig.data.user.username, jwtToken);
    const parsedFavoriteItems = favoriteItems
      .map((item) => ({
        ...item,
        favoritedAt: new Date(item.favoritedAt).getTime(),
        itemInfo: JSON.parse(item.itemInfo),
        marketPlaceInfo: JSON.parse(item.marketPlaceInfo),
      }))
      .sort((a, b) => b.favoritedAt - a.favoritedAt);

    dispatch(setFavoriteItems(parsedFavoriteItems));

    historyApi.getHistory(jwtToken).then((response) => dispatch(setUserHistory(response.data)));
    alertAPI.getAlerts(jwtToken).then((response) => dispatch(setUserAlerts(response.data)));
  } catch (error) {
    userConfig = await configAPI.getDefaultConfig();
  }
  initUserConfig(userConfig.data, dispatch);
  dispatch(setAppInitialized(true));
};

export const logout = async (dispatch) => {
  await auth.signOut();
  storage.setIsUserLoggedIn(false);
  storage.removeUserToken();
  document.cookie = 'search=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  document.cookie = 'searchCount=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  clearState(dispatch);
  dispatch(showToast({ text: 'Logged out', type: notificationEvents.error }));
  init(dispatch);
};

export const federatedSignIn = (socialNetwork) => {
  auth.federatedSignIn({ provider: socialNetwork });
};
