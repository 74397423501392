import helpers from 'helpers';
import { useDispatch, useSelector } from 'react-redux';
import {addShortcutTag, filterSearchResults, removeShortcutTag} from 'redux/actions/search.actions';
import ScrollablePanel from './ScrollablePanel';
import ShortcutButton from './ShortcutButton';

import 'styles/shortcutPanel.css';
import {useState} from 'react';

const maxTagsAmount = 10;

const ShortcutsPanel = ({ className }) => {
  const dispatch = useDispatch();
  const isSearchCompleted = useSelector((state) => state.app.isSearchCompleted);
  const tags = useSelector((state) => state.search.tags);
  const searchResults = useSelector((state) => state.search.searchResults);
  const [selectedShortcuts, setSelectedShortcuts] = useState({});

  const tagsToRender = tags.slice(0, maxTagsAmount);

  const addShortcut = (value) => {
    if(isSelected(value)) {
      dispatch(removeShortcutTag());
      setSelectedShortcuts({});
    }
    else {
      dispatch(addShortcutTag(value));
      setSelectedShortcuts(value);
    }
    dispatch(filterSearchResults());
  };

  const isSelected = (tag) => selectedShortcuts === tag;

  return isSearchCompleted && searchResults.length ? (
    <div className="shortcut-panel">
      <ScrollablePanel className={className}>
        {tagsToRender.map((item) => (
          <ShortcutButton key={item.tag} click={() => addShortcut(item.tag)} isSelected={isSelected(item.tag)}>
            {helpers.capitalizeFirstLetter(item.tag)}
          </ShortcutButton>
        ))}
      </ScrollablePanel>
    </div>
  ) : null;
};

export default ShortcutsPanel;
