import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import Input from 'components/Input';
import StyledButton from 'components/StyledButton/StyledButton';

const Form = ({ children, fieldsConfig, buttonLabel, onSubmit, updateFormState }) => {
  const { handleSubmit, register, formState, getValues } = useForm({ mode: 'onChange' });
  const { isSubmitting, isValid } = formState;

  const onFormSubmit = async (data) => {
    await onSubmit(data);
  };

  const onFormChange = () => {
    if (updateFormState) {
      updateFormState({ state: formState, values: getValues() });
    }
  };

  useEffect(() => {
    onFormChange();
  }, []);

  return (
    <form noValidate onSubmit={handleSubmit(onFormSubmit)} onChange={onFormChange}>
      {fieldsConfig.map((config) => (
        <Input config={config} register={register} key={config.fieldName} error={formState.errors[config.fieldName]} />
      ))}
      {children}
      <StyledButton
        label={buttonLabel}
        loading={isSubmitting}
        theme="filled-red-bg"
        disabled={isSubmitting || !isValid}
      />
    </form>
  );
};

export default Form;
