import MarketplacePanel from 'components/MarketplacePanel';
import ShortcutsPanel from 'components/ShortcutsPanel';
import { useSelector } from 'react-redux';

import 'styles/searchComponent.css';

const ShortcutsComponent = () => {
  const { isDemonstratingPanel } = useSelector((state) => state.marketplaces);

  return (
    <>
      {isDemonstratingPanel ? <MarketplacePanel /> : null}
      <ShortcutsPanel className="shortcut-panel-tags" />
    </>
  );
};

export default ShortcutsComponent;
