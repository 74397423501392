import routes from '../constants/routes';
import {addNewAlert, setIsAlertCreating} from '../redux/actions/alerts.actions';
import {alertAPI} from '../services/restapi.service';
import defaultSubscriptionFrequencies from '../constants/defaultSubscriptionFrequencies';
import analytics from '../services/analytics.service';
import analyticsEvents from '../constants/analyticsEvents';

export const createAlert = (alerts, maxAlerts, dispatch, socket, jwtToken, userTier, history) => {
  if (alerts?.length >= maxAlerts) {
    history.push(routes.alerts);
    return;
  }
  dispatch(setIsAlertCreating(true));
  history.push(routes.alerts);
  alertAPI
    .sendAlert(jwtToken, {
      submitted: socket.receipt?.submitted,
      frequency: defaultSubscriptionFrequencies[userTier],
    })
    .then((res) => {
      analytics.pushEvent(analyticsEvents.createAlert, {
        rumage_user_id: socket.config.user.username || null,
        location_count: socket.config.user?.locations.length,
        tier: socket.config.user?.tier,
      });
      dispatch(addNewAlert(res.data));
      dispatch(setIsAlertCreating(false));
    });
};
