import socketEvents from '../constants/socketEvents';
import { send } from './api.service';
import { searchResultApi } from './restapi.service';
import storage from './localStorage.service';

export const searchResultsByString = (searchString, locations, username) => {
  send(socketEvents.search, {
    term: searchString,
    locations: locations.join(';'),
    username,
  });
  storage.increaseSearchCount();
};

export const createSearchResultSet = async (token, searchId, search) => {
  const searchSet = {
    searchId,
    tags: search.tags,
    filters: {
      agents: search.agents,
      locations: search.locations,
      priceRange: search.priceRange,
      priceFilter: search.priceFilter,
      filteredAgents: search.filteredAgents,
      filteredLocations: search.filteredLocations,
      searchFilter: search.searchFilter,
      appliedTags: search.appliedTags,
      appliedIds: search.appliedIds,
    },
  };
  const { data } = await searchResultApi.createSearchResultSet(token, searchSet);

  return data;
};

export const getSearchResultSet = async (token, searchId) => {
  const { data } = await searchResultApi.getSearchResultSet(token, searchId);

  return data;
};
