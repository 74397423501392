import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import FormContainer from 'components/FormContainer';
import { reset } from 'services/auth.service';
import routes from 'constants/routes';
import { showToast } from 'redux/actions/app.actions';
import notificationEvents from 'constants/notificationEvents';
import {
  confirmPasswordFieldConfiguration,
  emailFieldConfiguration,
  passwordFieldConfiguration,
} from 'constants/defaultFieldsConfigurations';
import { requiredValidator } from 'constants/validators';
import Form from 'components/Form';
import StyledButton from 'components/StyledButton/StyledButton';

import 'styles/customInput.css';
import FormParagraph from './FormParagraph/FormParagraph';

const fieldsConfig = [
  emailFieldConfiguration,
  {
    label: 'Reset code',
    fieldName: 'resetCode',
    validators: {
      required: requiredValidator,
    },
  },
  {
    ...passwordFieldConfiguration,
    label: 'New password',
    fieldName: 'newPassword',
  },
  confirmPasswordFieldConfiguration,
];

const ResetPassword = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { user } = useSelector((state) => state);

  const handleReset = async (data) => {
    if (data.newPassword !== data.confirmPassword) {
      dispatch(showToast({ text: 'Password Confirmation Mismatch.', type: notificationEvents.error }));
      return;
    }
    await reset(data, dispatch).then((isFinishedSuccessfully) => {
      if (isFinishedSuccessfully) {
        history.push(routes.login);
      }
    });
  };

  const loginClick = () => {
    if (!user) {
      history.push(routes.login);
    }
    history.push(routes.settings);
  };

  return (
    <FormContainer formTitle="Password Reset">
      <FormParagraph>
        Please check your mail for a Reset Code. Enter the code, your new password and password
        confirmation below.
      </FormParagraph>
      <Form
        fieldsConfig={fieldsConfig}
        onSubmit={handleReset}
        buttonLabel="Reset Password"
      />
      <StyledButton
        style={{ marginTop: '10px'}}
        label="Login"
        onClick={loginClick}
      />
    </FormContainer>
  );
};

export default ResetPassword;
