import { createReducer } from '@reduxjs/toolkit';
import {
  showToast,
  showWaitResults,
  searchingLocation,
  clearToast,
  clearAppReducer,
  setSearchCompleted,
  setAppInitialized,
} from 'redux/actions/app.actions';

const initialState = {
  isInitialized: false,
  toastMessage: '',
  toastType: 'error',
  showWaitResult: true,
  searchingLocation: false,
  isSearchCompleted: false,
  isSearchStarted: false,
};

const appReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(showToast, (state, action) => ({
      ...state,
      toastMessage: action.payload.text,
      toastType: action.payload.type,
    }))
    .addCase(clearToast, (state) => ({
      ...state,
      toastMessage: null,
      toastType: null,
    }))
    .addCase(showWaitResults, (state, action) => ({
      ...state,
      showWaitResult: action.payload,
    }))
    .addCase(searchingLocation, (state) => ({
      ...state,
      searchingLocation: !state.searchingLocation,
    }))
    .addCase(clearAppReducer, () => ({
      ...initialState,
    }))
    .addCase(setSearchCompleted, (state, action) => ({
      ...state,
      isSearchCompleted: action.payload,
      isSearchStarted: true,
    }))
    .addCase(setAppInitialized, (state, action) => ({
      ...state,
      isInitialized: action.payload,
    }));
});

export default appReducer;
