const socketEvents = {
  defaultConfig: 'defaultConfig',
  config: 'config',
  locate: 'locate',
  update: 'update',
  history: 'history',
  alert: 'alert',
  search: 'search',
  receipt: 'receipt',
  userData: 'userData',
};

export default socketEvents;
