import { createAction } from '@reduxjs/toolkit';
import {
  SET_USER_ALERTS,
  CLEAR_ALERTS_REDUCER,
  REMOVE_ALERT_ITEM,
  UPDATE_ALERT_ITEM,
  ADD_NEW_ALERT,
  SET_IS_ALERT_CREATING,
} from 'redux/actions/types/alerts.types';

export const setUserAlerts = createAction(SET_USER_ALERTS);
export const clearAlertsReducer = createAction(CLEAR_ALERTS_REDUCER);
export const removeAlertItem = createAction(REMOVE_ALERT_ITEM);
export const updateAlertItem = createAction(UPDATE_ALERT_ITEM);
export const addNewAlert = createAction(ADD_NEW_ALERT);
export const setIsAlertCreating = createAction(SET_IS_ALERT_CREATING);
