import React from 'react';
import { Card } from 'primereact/card';

import '../styles/waitResults.css';

const NoSearchResults = () =>{
  setTimeout(() => {
    location.reload()
  }, 5000);
  return (
    <div className="wait-results-wrapper">
      <Card className="wait-results">
        <div className="wait-results-title">No Results? Sorry!</div>
        <div className="wait-results-content">
        Please try broadening your search to get results:
          <ul>
            <li>Increase your search range</li>
            <li>Add or change your search locations</li>
            <li>Use a single word search term</li>
          </ul>
        </div>
      </Card>
    </div>)
};

export default NoSearchResults;
