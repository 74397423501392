import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { InputSwitch } from 'primereact/inputswitch';
import CommonTab from 'components/CommonTab';
import routes from 'constants/routes';
import { logout, forgot } from 'services/auth.service';
import analytics from 'services/analytics.service';
import analyticsEvents from 'constants/analyticsEvents';
import { setEmailNotification } from 'redux/actions/socket.actions';
import userNotificationTypes from 'constants/userNotificationTypes';
import { configAPI } from 'services/restapi.service';
import useIsMount from 'hooks/useIsMount';
import withInitialization from 'higherOrderComponents/withInitialization';
import useCurrentTierConfig from 'hooks/useCurrentTierConfig';
import notificationEvents from 'constants/notificationEvents';
import { showToast } from 'redux/actions/app.actions';
import systemMessages from 'constants/systemMessages';
import StyledButton from '../StyledButton/StyledButton';

import 'components/Settings/Settings.css';

const Settings = () => {
  const config = useSelector((state) => state.socket.config);
  const { idToken } = useSelector((state) => state.user?.user);
  const history = useHistory();
  const dispatch = useDispatch();
  const { tierName } = useCurrentTierConfig();
  const isMount = useIsMount();

  const jwtToken = idToken?.jwtToken;
  const identities = idToken?.payload?.identities;
  const userEmail = config.user.email;
  const username = config.user.username
  const { locations, frequency, notify, searchprefs } = config.user;
  const buttonClassesWithoutBorder = 'p-button-text p-button-plain settings-item';
  const buttonClassesWithBorder = `${buttonClassesWithoutBorder} settings-item-border-bottom`;

  useEffect(() => {
    if (!isMount) {
      configAPI.updateConfig(jwtToken, {
        locations,
        frequency,
        notify: notify.length ? notify : ' ', // todo: remove empty string once back-end validation is adjusted
        searchprefs,
      });
    }
  }, [locations, frequency, notify, searchprefs, jwtToken, isMount]);

  if (!config) {
    return null;
  }

  const handleLogout = () => {
    logout(dispatch);
    analytics.pushEvent(analyticsEvents.logout, {
      rumage_user_id: username
    });
    history.push(routes.login);
  };

  const handleResetClick = async () => {
    forgot(config?.user.username, dispatch).then(() => {
      history.push(routes.resetPassword);
    });
  };

  const toggleEmailNotify = (event) => {
    dispatch(setEmailNotification(event.target.value));
  };

  const manageSubscriptionHandler = () => {
    if (!userEmail) {
      dispatch(
        showToast({
          text: systemMessages.shareMailSubscription,
          type: notificationEvents.warn,
        })
      );
      return;
    }
    history.push(routes.tiersInfo);
  };

  return (
    <div className="settings-wrapper">
      <CommonTab title="Settings" />
      <div className="setting-card setting-card__search">
        <span className="card-settings-title">Search</span>
        <div className="settings-fields">
          <StyledButton
            className={buttonClassesWithBorder}
            onClick={() => history.push(routes.locations)}
            useOnlyPropClass
          >
            <span>
              <i className="pi pi-map-marker settings-icon" />
              Locations
            </span>
            <span>
              <i className="pi pi-angle-right" />
            </span>
          </StyledButton>
          <StyledButton
            className={buttonClassesWithBorder}
            onClick={() => history.push(routes.alerts)}
            useOnlyPropClass
          >
            <span>
              <i className="pi pi-bell settings-icon" />
              Alerts
            </span>
            <span>
              <i className="pi pi-angle-right" />
            </span>
          </StyledButton>
          <StyledButton
            className={buttonClassesWithoutBorder}
            onClick={() => history.push(routes.favourites)}
            useOnlyPropClass
          >
            <span>
              <i className="pi pi-heart settings-icon" />
              Favourites
            </span>
            <span>
              <i className="pi pi-angle-right" />
            </span>
          </StyledButton>
        </div>
      </div>
      <div className="setting-card">
        <span className="card-settings-title">Notification</span>
        <div className="settings-fields">
          <div className="settings-item notification-block">
            <span>Email</span>
            <InputSwitch
              disabled={!userEmail}
              checked={notify.includes(userNotificationTypes.emailNotification)}
              onChange={toggleEmailNotify}
            />
          </div>
        </div>
      </div>
      <div className="setting-card">
        <span className="card-settings-title">Account</span>
        <div className="settings-fields">
          <div className="settings-item settings-subitem settings-item-border-bottom">
            <span className="settings-account-fields">Email Address</span>
            <div className="settings-account-fields settings-subscription-block">
              <span className="settings-account-fields account-email">
                {userEmail || 'Please provide an email address'}
              </span>
            </div>
            <div className="settings-subscription-buttons">
              <span>
                {!userEmail || !identities ? (
                  <StyledButton
                    label="Change"
                    onClick={() => history.push(routes.changeEmail)}
                    theme="filled-white-bg"
                    useOnlyPropClass
                  />
                ) : null}
              </span>
            </div>
          </div>
          <div className="settings-item settings-item-border-bottom">
            <span className="settings-account-fields">Username</span>
            <span className="settings-account-fields account-username">{config?.user.username}</span>
          </div>
          <div className="settings-item settings-subitem settings-item-border-bottom">
            <span className="settings-account-fields">Subscription Tier</span>
            <div className="settings-account-fields settings-subscription-block">
              <span>{tierName}</span>
            </div>
            <div className="settings-subscription-buttons">
              <span>
                <StyledButton
                  label="Manage Subscription"
                  onClick={manageSubscriptionHandler}
                  className="settings-page-button"
                />
              </span>
            </div>
          </div>
          <StyledButton
            className={buttonClassesWithBorder}
            onClick={() =>
              window.open(
                // eslint-disable-next-line
                'https://docs.google.com/forms/d/e/1FAIpQLSd7tOyhBknJAYJN76PdeR9331kbe2sun3gi_t3ANQmYQFBEkQ/viewform?ts=5ec4f6ac',
                '_blank'
              )
            }
            useOnlyPropClass
          >
            <span>Feedback</span>
            <span>
              <i className="pi pi-angle-right" />
            </span>
          </StyledButton>
          <StyledButton
            className={buttonClassesWithBorder}
            onClick={() => window.open(routes.FAQs, '_blank')}
            useOnlyPropClass
          >
            <span>FAQs</span>
            <span>
              <i className="pi pi-angle-right" />
            </span>
          </StyledButton>

          <StyledButton
            className={buttonClassesWithBorder}
            onClick={() => window.open(routes.terms, '_blank')}
            useOnlyPropClass
          >
            <span>Terms & Conditions</span>
            <span>
              <i className="pi pi-angle-right" />
            </span>
          </StyledButton>
          <StyledButton
            className={buttonClassesWithBorder}
            onClick={() => window.open(routes.privacy, '_blank')}
            useOnlyPropClass
          >
            <span>Privacy</span>
            <span>
              <i className="pi pi-angle-right" />
            </span>
          </StyledButton>
          <div className="settings-item">
            <span className="settings-account-fields">
              <StyledButton label="Reset password" onClick={handleResetClick} theme="filled-white-bg" />
            </span>
            <span className="settings-account-fields">
              <StyledButton label="Logout" onClick={handleLogout} theme="filled-white-bg" />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withInitialization(Settings);
