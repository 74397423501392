import {useHistory, useLocation} from 'react-router';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import FormContainer from 'components/FormContainer';
import StyledButton from 'components/StyledButton/StyledButton';
import SocialButtons from 'components/SocialButtons';
import Form from 'components/Form';
import { login, init } from 'services/auth.service';
import routes from 'constants/routes';
import { initUser } from 'redux/actions/user.actions';
import storage from 'services/localStorage.service';
import { showToast } from 'redux/actions/app.actions';
import notificationEvents from 'constants/notificationEvents';
import analytics from 'services/analytics.service';
import analyticsEvents from 'constants/analyticsEvents';
import { emailFieldConfiguration, passwordFieldConfiguration } from 'constants/defaultFieldsConfigurations';
import FormParagraph from 'components/FormParagraph/FormParagraph';

const fieldsConfig = [emailFieldConfiguration, passwordFieldConfiguration];

const LogIn = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const returnUrl = new URLSearchParams(location.search).get('returnUrl');

  const handleLogin = async (data) => {
    const user = await login(data, dispatch).catch((error) =>
      dispatch(
        showToast({
          text: error.message,
          type: notificationEvents.error,
        })
      )
    );

    if (!user) {
      return;
    }

    localStorage.setItem('userId', user.username);
    window.gtag('config', process.env.REACT_APP_GTAG_CODE, { rumage_user_id: localStorage.getItem('userId') });

    if (user.signInUserSession) {
      storage.setUserJwtToken(user.signInUserSession.idToken.jwtToken);
      dispatch(initUser(user.signInUserSession));
      await init(dispatch);
      analytics.pushEvent(analyticsEvents.login);
      if (returnUrl) {
        window.location.href = returnUrl
      } else {
        history.push(routes.home);
      }
    }
  };

  const formButton = (
    <StyledButton
      label="Signup"
      onClick={() => history.push(routes.signup)}
      className="login-signup-toggle-button"
      useOnlyPropClass
    />
  );

  return (
    <FormContainer formTitle="Login" headerButton={formButton}>
      <Form fieldsConfig={fieldsConfig} onSubmit={handleLogin} buttonLabel="Login">
        <Link to="/forgotpassword">
          <FormParagraph className="align-end">Forgot Password?</FormParagraph>
        </Link>
      </Form>
      <StyledButton
        label="Continue as guest"
        onClick={() => history.push(routes.home)}
        theme="filled-white-bg"
        className="mt-8"
      />
      <FormParagraph className="align-center">or</FormParagraph>
      <SocialButtons />
    </FormContainer>
  );
};

export default LogIn;
