import { useSelector } from 'react-redux';

const useCurrentTierConfig = () => {
  const tier = useSelector((state) => state.socket.config.user.tier);
  const tiers = useSelector((state) => state.socket.config.service.tiers);

  return tiers.find(({ id }) => id === tier);
};

export default useCurrentTierConfig;
