import withInitialization from 'higherOrderComponents/withInitialization';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { setSearchValue } from 'redux/actions/search.actions';
import { searchSuggestions } from 'services/restapi.service';

import './SearchDropdown.css';
import { useSearchDropdownKeyboard } from './SearchDropdown.hooks';

const SearchDropdown = ({ searchInputId }) => {
  const [suggestions, setSuggestions] = useState([]);
  const dispatch = useDispatch();
  const historyRef = useRef();
  const history = useHistory();
  const userHistory = useSelector((state) => state.user.history);
  const searchTerm = encodeURIComponent(useSelector((state) => state.search.searchTerm));
  useSearchDropdownKeyboard(searchInputId, historyRef);

  useEffect(() => {
    if (!searchTerm) {
      if (suggestions.length) {
        setSuggestions([]);
      }
      return;
    }

    const getSearchSuggestions = async () => {
      const { data: suggestions } = await searchSuggestions.get(encodeURIComponent(searchTerm));
      typeof suggestions !== 'string' &&  setSuggestions(suggestions);
    };

    const delayDebounceFn = setTimeout(() => {
      getSearchSuggestions();
    }, 500);

    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [searchTerm]);

  const onDropdownItemClick = (term) => {
    dispatch(setSearchValue(term));
    history.push(`?term=${term.replace(/ /g, '+')}`);
  };

  return (
    <>
      {userHistory.some((item) => item.term.includes(searchTerm.toLowerCase())) || suggestions.length ? (
        <div className="search-history">
          <div ref={historyRef}>
            {userHistory.map((item) => {
              if (!item.term.includes(searchTerm.toLowerCase())) {
                return null;
              }
              const index = item.term.indexOf(searchTerm.toLowerCase());

              return (
                <button
                  key={item.submitted}
                  type="button"
                  className="clear-button search-history-item"
                  onClick={() => onDropdownItemClick(item.term)}
                >
                  <div>
                    {item.term.substr(0, index)}
                    <b>{searchTerm.toLowerCase()}</b>
                    {item.term.substr(index + searchTerm.length)}
                    {item.frequency && <i className="pi pi-bell" />}
                  </div>
                  <i className="pi pi-history" />
                </button>
              );
            })}
            {suggestions.map((suggestion, i) => (
              <button
                key={`${suggestion}${i}`}
                type="button"
                className="clear-button search-history-item"
                onClick={() => onDropdownItemClick(suggestion)}
              >
                <div>{suggestion}</div>
                <i className="pi pi-search" />
              </button>
            ))}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default withInitialization(SearchDropdown);
