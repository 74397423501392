import './ItemCard.css';

const AdvertCard = ({itemInfo} ) => {
  const imagePath =itemInfo.acf.portrait_image;
  const link = itemInfo.acf.target_url
  const className = `${itemInfo.type}-${itemInfo.acf.card_type}-${itemInfo.slug}`.toLowerCase()
  if(!imagePath) {
    return null
  }
  return (
    <a href={link} className={className}  rel="noopener noreferrer"  target="_blank" >
      <div className={'item-card'} >
        <img
          src={imagePath}
          alt="item"
          height='100%'
          className="advert-card__image"
        />
      </div>
    </a>
  );
};

export default AdvertCard;
