import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {getAnonLocation} from '../services/locations';

const defaultLocation = {
  term: 'national',
  postcode: 'delivery',
  city: 'National delivery',
  showIcon: true,
  checked: true,
};

const useLocations = () => {
  const socket = useSelector((store) => store.socket);

  return useMemo(() => {
    const result = [{ ...defaultLocation }];
    const anonLocation = getAnonLocation(socket);

    if (anonLocation) result.push(anonLocation)

    if (socket.config && socket.config.user.locations.length) {
      socket.config.user.locations.forEach((location) => result.push(
        {
          term: location.term,
          postcode: location.postcode,
          city: location.city,
          showIcon: false,
          checked: true,
        },
      ));
    }

    return result;
  }, [socket]);
};

export default useLocations;
