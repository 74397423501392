const setIsUserLoggedIn = (isLoggedIn) => {
  localStorage.setItem('isLoggedIn', isLoggedIn);
};

const setUserJwtToken = (token) => {
  localStorage.setItem('userToken', token);
};

const setUserId = (userId) => {
  localStorage.setItem('userId', userId);
};

const getUserJwtToken = () => localStorage.getItem('userToken');

const getIsUserLoggedIn = () => localStorage.getItem('isLoggedIn') && JSON.parse(localStorage.getItem('isLoggedIn'));

const getSearchCount = () => Number(localStorage.getItem('searchCount')) || 0;

const increaseSearchCount = () => {
  localStorage.setItem('searchCount', getSearchCount() + 1);
};

const removeUserToken = () => localStorage.removeItem('userToken');

const removeUserId = () => localStorage.removeItem('userId');

const setCookiesAccept = () => {
  localStorage.setItem('isCookiesAccept', true);
};

const getCookiesAccept = () => localStorage.getItem('isCookiesAccept');

const setVerificationEmailResendCooldown = () => localStorage.setItem('emailVerification', +new Date() + 300000);

const getVerificationEmailResendCooldown = () => localStorage.getItem('emailVerification');

const removeVerificationEmailResendCooldown = () => localStorage.removeItem('emailVerification');

const setRecentlyViewedItems = (recentlyViewed) => localStorage.setItem('recentlyViewed', recentlyViewed);

const getRecentlyViewedItems = () =>
  localStorage.getItem('recentlyViewed') && JSON.parse(localStorage.getItem('recentlyViewed'));

const removeRecentlyViewedItems = () => localStorage.removeItem('recentlyViewed');

const setRecentSearches = (recentSearches) => localStorage.setItem('recentSearches', recentSearches);

const getRecentSearches = () =>
  localStorage.getItem('recentSearches') && JSON.parse(localStorage.getItem('recentSearches'));

const removeRecentSearches = () => localStorage.removeItem('recentSearches');

const storage = {
  setUserJwtToken,
  setUserId,
  removeUserId,
  getUserJwtToken,
  setIsUserLoggedIn,
  getIsUserLoggedIn,
  increaseSearchCount,
  getSearchCount,
  removeUserToken,
  setCookiesAccept,
  getCookiesAccept,
  setVerificationEmailResendCooldown,
  getVerificationEmailResendCooldown,
  removeVerificationEmailResendCooldown,
  setRecentlyViewedItems,
  getRecentlyViewedItems,
  removeRecentlyViewedItems,
  setRecentSearches,
  getRecentSearches,
  removeRecentSearches,
};

export default storage;
