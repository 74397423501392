const itemsPerRow = (windowWidth) => {
  let result;

  switch (true) {
    case windowWidth > 661 && windowWidth < 878:
      result = 3;
      break;
    case windowWidth > 878 && windowWidth < 1095:
      result = 4;
      break;
    case windowWidth > 1095 && windowWidth < 1314:
      result = 5;
      break;
    case windowWidth > 1314 && windowWidth < 1530:
      result = 6;
      break;
    case windowWidth > 1530 && windowWidth < 1750:
      result = 7;
      break;
    case windowWidth > 1750:
      result = 8;
      break;
    default:
      result = 6;
  }

  return result;
};

function getItemRow (index, windowWidth) {
  const _index = index + 1 + Math.ceil(index / 35)
  if(!index) {
    return ;
  }
  return Math.ceil(_index / itemsPerRow(windowWidth))
}
export default getItemRow;
