import ItemCard from 'components/ItemCard/ItemCard';
import analyticsEvents from 'constants/analyticsEvents';
import makeId from 'helpers/makeId';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import analytics from 'services/analytics.service';
import storage from 'services/localStorage.service';
import './RecentlyViewed.css';

const RecentlyViewed = () => {
  const [recentlyViewedCards, setRecentlyViewedCards] = useState(storage.getRecentlyViewedItems() || []);
  const tier = useSelector((state) => state.socket.config?.user.tier);
  const { socket } = useSelector((state) => state);
  const receipt = socket.receipt;
  const onClearAllClick = () => {
    setRecentlyViewedCards([]);
    storage.removeRecentlyViewedItems();
  };

  return (
    <>
      {recentlyViewedCards.length ? (
        <div className="recently-viewed">
          <div className="recently-viewed__title-group">
            <h2>Recently Viewed</h2>
            <p onClick={onClearAllClick}>Clear all</p>
          </div>
          <div className="recently-viewed__cards">
            {recentlyViewedCards.map((card) => (
              <a
                key={`${card.marketplace.id}${makeId()}`}
                href={card?.result?.link}
                target="_blank"
                rel="noreferrer"
                onClick={() => {
                  analytics.pushEvent(analyticsEvents.viewItem, {
                    rumage_user_id: socket?.config.user.username || null,
                    marketplace: card?.result?.agent,
                    item_price: card?.result?.price,
                    tier,
                    category_found: receipt.label[0],
                    probability: receipt.prob[0],
                    category_given: receipt.category,
                  });
                }}
              >
                <ItemCard
                  itemInfo={card.result}
                  marketPlaceInfo={card.marketplace}
                  className={'recently-viewed__card'}
                  showBlock={false}
                />
              </a>
            ))}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default RecentlyViewed;
