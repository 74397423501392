import { createAction } from '@reduxjs/toolkit';
import {
  CLEAR_SEARCH_RESULTS,
  FILTER_SEARCH_AGENTS,
  FILTER_SEARCH_LOCATIONS,
  FILTER_SEARCH_RESULTS,
  SET_SEARCH_PRICE_FILTER,
  SET_SEARCH_RESULTS,
  SET_SEARCH_SHOW_HISTORY,
  SET_SEARCH_FILTER,
  SET_SEARCH_VALUE,
  UPDATE_SEARCH_SETTINGS,
  SET_SEARCH_AGENTS_ITEM,
  SET_SEARCH_FILTERED_BY_MARKETPLACE_RESULTS,
  SET_SEARCH_LOCATIONS,
  SET_SEARCH_LOCATIONS_ITEM,
  SET_SEARCH_AGENTS_ITEM_FROM_MARKETPLACE_PANEL,
  CLEAR_SEARCH_REDUCER,
  ADD_SHORTCUT_TAG,
  REMOVE_SHORTCUT_TAG,
  CLEAR_PRICE_FILTER,
  SET_ALL_AGENTS_CHECKED_STATUS,
  PUT_ALL_LOCATIONS_INTO_FILTERED_LOCATIONS,
  REMOVE_SEARCH_LOCATIONS_ITEM,
  APPLY_SEARCH_FILTERS,
  SHOW_SECOND_NUDGE_MESSAGE, SET_TFIDF_MATRIX
} from 'redux/actions/types/search.types';

export const setTfIdfMatrix = createAction(SET_TFIDF_MATRIX);
export const setSearchValue = createAction(SET_SEARCH_VALUE);
export const setSearchResults = createAction(SET_SEARCH_RESULTS);
export const clearSearchResults = createAction(CLEAR_SEARCH_RESULTS);
export const setSearchShowHistory = createAction(SET_SEARCH_SHOW_HISTORY);
export const updateSearchSettings = createAction(UPDATE_SEARCH_SETTINGS);
export const filterSearchAgents = createAction(FILTER_SEARCH_AGENTS);
export const filterSearchLocations = createAction(FILTER_SEARCH_LOCATIONS);
export const filterSearchResults = createAction(FILTER_SEARCH_RESULTS);
export const setSearchFilter = createAction(SET_SEARCH_FILTER);
export const setSearchPriceFilter = createAction(SET_SEARCH_PRICE_FILTER);
export const setSearchLocations = createAction(SET_SEARCH_LOCATIONS);
export const setSearchAgentsItem = createAction(SET_SEARCH_AGENTS_ITEM);
export const setSearchLocationsItem = createAction(SET_SEARCH_LOCATIONS_ITEM);
export const setSearchFilteredByMarketplaceResults = createAction(
  SET_SEARCH_FILTERED_BY_MARKETPLACE_RESULTS,
);
export const setSearchAgentsItemFromMarketplacePanel = createAction(
  SET_SEARCH_AGENTS_ITEM_FROM_MARKETPLACE_PANEL,
);
export const clearSearchReducer = createAction(CLEAR_SEARCH_REDUCER);
export const addShortcutTag = createAction(ADD_SHORTCUT_TAG);
export const removeShortcutTag = createAction(REMOVE_SHORTCUT_TAG);
export const clearPriceFilter = createAction(CLEAR_PRICE_FILTER);
export const setAllAgentsCheckedStatus = createAction(SET_ALL_AGENTS_CHECKED_STATUS);
export const putAllLocationsIntoFilteredLocations = createAction(
  PUT_ALL_LOCATIONS_INTO_FILTERED_LOCATIONS,
);
export const removeSearchLocationItem = createAction(REMOVE_SEARCH_LOCATIONS_ITEM);
export const applySearchFilters = createAction(APPLY_SEARCH_FILTERS);
export const setShowSecondNudgeMessage = createAction(SHOW_SECOND_NUDGE_MESSAGE);
