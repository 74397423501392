import { useDispatch, useSelector } from 'react-redux';
import makeId from 'helpers/makeId';
import helpers from 'helpers';
import { selectJwtToken, selectMarketplaces, selectTier } from 'redux/store/selectors';
import {Fragment, useEffect, useState} from 'react';
import analyticsEvents from 'constants/analyticsEvents';
import analytics from 'services/analytics.service';
import QuickView from './QuickView/QuickView';
import 'styles/searchResults.css';
import ItemCard from 'components/ItemCard/ItemCard';
import storage from 'services/localStorage.service';
import routes from '../constants/routes';
import { useHistory } from 'react-router';
import tiersTypes from '../constants/tiersTypes';
import { showToast } from '../redux/actions/app.actions';
import systemMessages from '../constants/systemMessages';
import notificationEvents from '../constants/notificationEvents';
import { createAlert } from '../helpers/alerts';
import { isMobile } from 'react-device-detect';
import FiltersContainer from './FiltersContainer/FiltersContainer';
import filterTypes from '../constants/filterTypes';
import { filterSearchResults } from '../redux/actions/search.actions';
import SortContainer from './SortContainer/SortContainer';
import ItemCardMobile from './ItemCard/ItemCardMobile';
import GoToTopButton from './GoToTopButton/GoToTopButton';
import ShareSearchList from './ShareSearchList';
import AdvertCard from './ItemCard/AdvertCard';
import AdvertCardMobile from './ItemCard/AdvertCardMobile';
import { fetchResultCards} from '../services/api.service';
import {renderToastText} from '../helpers/renderToastText';
import Nudge from './Nudge/Nudge';
import {isAnon} from '../services/user.service';
import getItemRow from '../helpers/getItemRow';

const SearchResults = ({ headerRef }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [showQuickView, setShowQuickView] = useState(false);
  const [areFiltersShown, setAreFiltersShown] = useState(false);
  const [showSortFilters, setShowSortFilters] = useState(false);
  const [itemInfo, setItemInfo] = useState(null);
  const [marketPlaceInfo, setMarketPlaceInfo] = useState(null);
  const { socket, search, alerts, marketplaces: marketpleacesSearch } = useSelector((state) => state);
  const { filteredLocations, agents, searchResults, filteredSearchResults, filteredAgents } = search;
  const marketplaces = useSelector(selectMarketplaces);
  const userTier = useSelector(selectTier);
  const username = socket.config?.user.username;
  const maxAlerts = socket.config?.service?.tiers.filter((tier) => tier.id === userTier)[0].maxAlerts;
  const isAlertsClickable = alerts.length >= maxAlerts;
  const jwtToken = useSelector(selectJwtToken) || null;
  const userEmail = socket.config?.user.email;
  const [showShareLinks, setShowShareLinks] = useState(false);
  const searchTermCategory = socket.receipt?.category
  const searchTerm = socket.receipt?.term;
  const [relevantResultCards, setRelevantResultCards] = useState([])
  const wordpressTag = searchTermCategory?.toLowerCase().replace(' & ', '-').replace(/[/,]/g , '-').replace(' ','-')
  const mappingFile = useSelector((state) => state.user.mappingFile);
  const isUserAnon = isAnon(socket)
  const [isSignupNudgeVisible, setIsSignupNudgeVisible] = useState(true)

  const filterRelevantResultCards = (resultCardsJSON) => {
    let _resultCards =[]
    if (wordpressTag && !wordpressTag.includes('unknown')) {
      const tag = mappingFile[wordpressTag] || wordpressTag;
      _resultCards = resultCardsJSON.filter(card => {
        return card.acf.product_categories.some( category => category.value === `category-${tag}`)
      })}
    else {
      _resultCards= resultCardsJSON
    }
    setRelevantResultCards(_resultCards)
  }

  useEffect(() => {
    try{
      fetchResultCards().then(res => filterRelevantResultCards(res));
    } catch {
      setRelevantResultCards(null)
    }
  }, []);

  const headerHeight = document.querySelector('.fixed-content')?.getBoundingClientRect().height;

  const getMarketplacesWithResults = (marketplaces) => {
    return filteredAgents.length
      ? filteredAgents.length
      : marketplaces.buttons.filter((market) => market.results > 0)?.length;
  };

  const handleAlerts = () => {
    const alertsLength = alerts?.alerts?.length;
    if (username === tiersTypes.anon) {
      dispatch(
        showToast({
          text: renderToastText(systemMessages.pleaseRegister, routes.login),
          type: notificationEvents.warn,
        })
      );
      return;
    }

    if (!maxAlerts) {
      dispatch(
        showToast({
          text: systemMessages.notAuthorizedUserAlerts,
          type: notificationEvents.warn,
        })
      );
      return;
    }

    if (alertsLength >= maxAlerts) {
      dispatch(
        showToast({
          text: systemMessages.maxAlertsLimitExceed,
          type: notificationEvents.warn,
        })
      );
      return;
    }

    if (!userEmail) {
      history.push(routes.settings);
      dispatch(
        showToast({
          text: systemMessages.shareMailAlerts,
          type: notificationEvents.warn,
        })
      );
      return;
    }
    handleCreateAlert();
  };

  const handleCreateAlert = () => {
    createAlert(alerts, maxAlerts, dispatch, socket, jwtToken, userTier, history);
  };

  const isDisabledAlertButton = () => {
    if (username === tiersTypes.anon) {
      return '';
    }

    return isAlertsClickable || !search.searchResults.length ? 'disable' : '';
  };

  const toggleFilters = () => {
    if (!searchResults.length) {
      return;
    }
    setAreFiltersShown((prevState) => !prevState);
  };

  const toggleSorting = () => setShowSortFilters((prevState) => !prevState);

  const handleDoneButton = () => {
    if (!filteredLocations.length) {
      dispatch(
        showToast({
          text: `Please select at least one ${filterTypes.locations}`,
          type: notificationEvents.error,
        })
      );
      return;
    }
    if (agents.every((agent) => !agent.checked)) {
      dispatch(
        showToast({
          text: `Please select at least one ${filterTypes.marketplaces}`,
          type: notificationEvents.error,
        })
      );
      return;
    }
    dispatch(filterSearchResults());
    toggleFilters();
  };

  const searchInProgress = (marketplaces) => {
    return marketplaces.buttons.some((button) => button.isSearchInProgress);
  };

  let cardIndex = 0

  const showAdvertCards = () => {
    if (relevantResultCards.length === 0) {
      return;
    }

    const cardInfo = relevantResultCards[cardIndex];

    if(!(cardIndex === relevantResultCards.length - 1)){
      cardIndex++
    } else {
      cardIndex = 0
    }

    return isMobile ?
      <AdvertCardMobile itemInfo={cardInfo}/>
      :
      <AdvertCard itemInfo={cardInfo}/>;
  }

  const results = filteredSearchResults.filter(item => item.term === searchTerm).map((itemInfo, index) => {
    const marketPlaceInfo = helpers.getMarketplaceInfo(marketplaces, itemInfo.agent);
    const onItemCardClick = () => {
      setItemInfo({ ...itemInfo, itemIndex: index });
      setMarketPlaceInfo(marketPlaceInfo);
      setShowQuickView(true);

      analytics.pushEvent(analyticsEvents.quickViewItem, {
        rumage_user_id : username || null,
        marketplace: itemInfo?.agent,
        item_price: itemInfo?.price,
        userTier,
        target1: itemInfo.link.substring(0,100),
        target2: itemInfo.link.substring(100,200),
        target3: itemInfo.link.substring(200,300),
        target4: itemInfo.link.substring(300,400),
        target5: itemInfo.link.substring(400,500),
        click_row : getItemRow(index, window.innerWidth)
      });

      const recentlyViewedItems = storage.getRecentlyViewedItems();
      const currentItem = {
        result: {
          image: itemInfo.image,
          link: itemInfo.link,
          title: itemInfo.title,
          price: itemInfo.price,
          currency: itemInfo.currency,
          agent: itemInfo.agent,
        },
        marketplace: {
          imageUrl: marketPlaceInfo.imageUrl,
          id: marketPlaceInfo.id,
        },
      };

      if (recentlyViewedItems) {
        const currentItemValues = Object.values(currentItem.result);

        for (const item of recentlyViewedItems) {
          if (Object.values(item.result).every((value) => currentItemValues.includes(value))) {
            return;
          }
        }

        recentlyViewedItems.unshift(currentItem);

        if (recentlyViewedItems.length > 6) {
          recentlyViewedItems.pop();
        }
      }

      storage.setRecentlyViewedItems(JSON.stringify(recentlyViewedItems || [currentItem]));
    };

    return (
      <Fragment key={index}>
        {(isMobile && index % 10 === 0) && showAdvertCards()}
        {(!isMobile && index % 35 === 0) && showAdvertCards()}
        {isMobile ? (
          <ItemCardMobile
            key={`${itemInfo.agent}${makeId()}`}
            itemInfo={itemInfo}
            marketPlaceInfo={marketPlaceInfo}
            onClick={onItemCardClick}
          />
        ) : (
          <ItemCard
            key={`${itemInfo.agent}${makeId()}`}
            itemInfo={itemInfo}
            marketPlaceInfo={marketPlaceInfo}
            onClick={onItemCardClick}
          />
        )}
      </Fragment>
    )
  });

  return (
    <div className="results-container">
      {showQuickView && (
        <QuickView
          itemInfo={itemInfo}
          marketPlaceInfo={marketPlaceInfo}
          setShowQuickViewFalse={() => setShowQuickView(false)}
        />
      )}
      {!searchInProgress(marketpleacesSearch) && (
        <div className="results-headline">
          <div className="total-results">
            {results.length} results from {getMarketplacesWithResults(marketpleacesSearch)} marketplaces
          </div>
          <div>
            <span className="alert-icon" onClick={() => history.push(routes.locations)}>
              <i className="pi pi-map-marker" />
            </span>
            <span className="alert-icon share-links" onClick={() => setShowShareLinks(!showShareLinks)}>
              <i className="pi pi-share-alt" />
            </span>
            <span className={`alert-icon ${isDisabledAlertButton() ? 'disabled' : ''}`} onClick={handleAlerts}>
              <i className="pi pi-bell" />
              <span className="alert-counter">{alerts.alerts?.length || 0}</span>
            </span>
            {!isMobile && (
              <span className="alert-icon" onClick={toggleFilters}>
                <i className="pi pi-sliders-h" />
              </span>
            )}
            {!isMobile && (
              <span className="alert-icon" onClick={toggleSorting}>
                <i className="pi pi-sort-alt" />
              </span>
            )}
          </div>
        </div>
      )}
      {isUserAnon && <Nudge isVisible={isSignupNudgeVisible} setIsVisible={setIsSignupNudgeVisible}/>}
      {showShareLinks && (
        <div className="share-links-container">
          <ShareSearchList />
        </div>
      )}
      <div
        className={`results-wrapper ${isMobile ? 'mobile' : ''}`}
        style={{ height: `calc(100% - 44px - ${headerHeight})` }}
      >
        {results}
      </div>
      <FiltersContainer
        handleDoneButton={handleDoneButton}
        areFiltersShown={areFiltersShown}
        toggleFilters={toggleFilters}
      />
      <SortContainer sortFilterShown={showSortFilters} toggleSortFilters={toggleSorting} />
      <GoToTopButton pageRef={headerRef} />
      {isMobile && !searchInProgress(marketpleacesSearch) && !areFiltersShown && (
        <div className="filter-actions-mobile">
          <span onClick={toggleSorting}>
            <i className="pi pi-sort-alt" />
          </span>
          <span onClick={toggleFilters}>
            <i className="pi pi-sliders-h" />
          </span>
        </div>
      )}
    </div>
  );
};

export default SearchResults;
