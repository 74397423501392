import { emailPatternValidator, passwordMinLengthValidator, requiredValidator } from './validators';

export const emailFieldConfiguration = {
  label: 'Email address',
  fieldName: 'email',
  type: 'text',
  validators: {
    required: requiredValidator,
    pattern: emailPatternValidator,
    setValueAs: (value) => value.trim()
  },
};
export const passwordFieldConfiguration = {
  label: 'Password',
  fieldName: 'password',
  type: 'password',
  validators: {
    required: requiredValidator,
    minLength: passwordMinLengthValidator,
  },
};
export const confirmPasswordFieldConfiguration = {
  ...passwordFieldConfiguration,
  label: 'Confirm password',
  fieldName: 'confirmPassword',
};
