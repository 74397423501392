import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import MarketplaceButton from './MarketplaceButton';

import 'styles/marketplacePanel.css';
import Carousel from './Carousel/Carousel';

const MarketplacePanel = () => {
  const { marketplaces } = useSelector((state) => state);
  const { socket } = useSelector((state) => state);
  const receipt = socket.receipt;
  const [isSearching, setIsSearching] = useState(true);

  const searchInProgress = () => {
    let marketplacesToSearch = structuredClone(marketplaces) || {buttons: []};
    if(receipt?.marketplaces) {
      marketplacesToSearch.buttons =
        marketplacesToSearch.buttons
          .filter((market) => receipt.marketplaces.find((receipt) => receipt.id === market.marketplace))
    }
    return {
      inProgress: marketplacesToSearch.buttons.filter((button) => button.isSearchInProgress).length,
      marketplaces: marketplacesToSearch.buttons
    }
  }

  useEffect(() => {
    setIsSearching(marketplaces.buttons.some((button) => button.isSearchInProgress));
  }, [marketplaces]);

  if (isSearching && receipt?.marketplaces) {
    return (
      <div className="marketplace-wrapper">
        <div className="search-in-progress">Searching {searchInProgress().inProgress} marketplaces</div>
        <Carousel
          width={'40%'}
          circular
          autoplayInterval={1500}
          value={searchInProgress().marketplaces}
          itemTemplate={(item) => {
            return (
              <MarketplaceButton buttonData={item} key={item.marketplace} />
            )
          }}
        />
      </div>
    );
  }
  return (
    <></>
  );
};

export default MarketplacePanel;
