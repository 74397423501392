import { isProduction } from './environment';

const logInfo = (logFn) => {
  if (!isProduction() && logFn) {
    logFn();
  }
};

/* eslint no-console: 0 */
class Logger {
  static error(message) {
    logInfo(() => console.error(message));
  }

  static info(message) {
    logInfo(() => console.info(message));
  }

  static log(message) {
    logInfo(() => console.log(message));
  }

  static warn(message) {
    logInfo(() => console.warn(message));
  }
}

export default Logger;
