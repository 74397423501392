import { createAction } from '@reduxjs/toolkit';
import {
  INIT_USER,
  CLEAR_USER_REDUCER,
  SET_USER_HISTORY,
  SET_FAVORITE_ITEMS,
  ADD_FAVORITE_ITEM,
  DELETE_FAVORITE_ITEM,
  DELETE_ALL_FAVORITE_ITEMS, SET_MAPPING_FILE,
} from 'redux/actions/types/user.types';

export const initUser = createAction(INIT_USER);
export const setUserHistory = createAction(SET_USER_HISTORY);
export const clearUserReducer = createAction(CLEAR_USER_REDUCER);
export const setFavoriteItems = createAction(SET_FAVORITE_ITEMS);
export const setMappingFile = createAction(SET_MAPPING_FILE);
export const addFavoriteItem = createAction(ADD_FAVORITE_ITEM);
export const deleteFavoriteItem = createAction(DELETE_FAVORITE_ITEM);
export const deleteAllFavoriteItems = createAction(DELETE_ALL_FAVORITE_ITEMS);
