import { useRef, useState } from 'react';
import { useHistory } from 'react-router';
import storage from 'services/localStorage.service';
import './RecentSearches.css';

const RecentSearches = () => {
  const recentSearchesRef = useRef(storage.getRecentSearches() || []);
  const [recentSearches, setRecentSearches] = useState(recentSearchesRef.current || []);
  const history = useHistory();

  const onClearAllClick = () => {
    setRecentSearches([]);
    storage.removeRecentSearches();
  };
  return (
    <>
      {recentSearches.length ? (
        <div className="recent-searches">
          <div className="recent-searches-title-container">
            <h2>Recent Searches</h2>
            <p className="clear-recent-search" onClick={onClearAllClick}>
              Clear all
            </p>
          </div>
          <div className="recent-searches__searches">
            {recentSearches.map((recentSearch, i) => (
              <p key={`${recentSearch.term}${i}`} onClick={() => history.push(recentSearch.link, true)}>
                {decodeURIComponent(recentSearch.term)}
              </p>
            ))}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default RecentSearches;
