import { Button } from 'primereact/button';

import 'components/StyledButton/StyledButton.css';

const StyledButton = ({
  onClick,
  label,
  disabled,
  loading,
  className,
  theme,
  style,
  useOnlyPropClass,
  children,
  ...rest
}) => {
  let classes = `form-button ${className}`;

  if (theme === 'filled-red-bg') {
    classes = `form-button filled-red-bg ${className}`;
  } else if (theme === 'filled-white-bg') {
    classes = `form-button form__whiteBtn ${className}`;
  } else if (theme === 'transparent-bg') {
    classes = `form-button form__transparentBtn ${className}`;
  } else if (useOnlyPropClass) {
    classes = className;
  }

  return (
    <Button
      label={label}
      onClick={onClick}
      className={classes}
      disabled={disabled}
      loading={loading}
      style={style}
      {...rest}
    >
      {children}
    </Button>
  );
};

export default StyledButton;
