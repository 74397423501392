import logo from 'assets/img/main-logo.png';
import routes from 'constants/routes';
import { Menubar } from 'primereact/menubar';
import { useHistory } from 'react-router';
import Authentification from './Authentification';

import 'styles/menu.css';
import { isAnon } from '../services/user.service';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

const Menu = () => {
  const history = useHistory();
  const socket = useSelector((state) => state.socket);
  const [items, setItems] = useState();

  const menuItems = [
    {
      label: 'Account',
      command: () => {
        history.push(routes.settings);
      },
      icon: 'pi pi-fw pi-user',
    },
    {
      label: 'Locations',
      command: () => {
        history.push(routes.locations);
      },
      icon: 'pi pi-fw pi-map-marker',
    },
    {
      label: 'Alerts',
      command: () => {
        history.push(routes.alerts);
      },
      icon: 'pi pi-fw pi-bell',
    },
    {
      label: 'Favorites',
      command: () => {
        history.push(routes.favourites);
      },
      icon: 'pi pi-fw pi-heart',
    },
    {
      label: 'Buyer`s Guide',
      command: () => {
        window.location.href = routes.buyersGuides;
      },
      icon: 'pi pi-fw pi-book',
    },
    {
      label: 'Offers',
      command: () => {
        window.location.href = routes.rumageStreet;
      },
      icon: 'pi pi-fw pi-tag',
    },
  ];

  useEffect(() => {
    if (socket.config === null) {
      return;
    }

    if (isAnon(socket)) {
      setItems(menuItems.filter((item) => item.label !== 'Account'));
    } else {
      setItems(menuItems);
    }
  }, [socket]);

  return (
    <nav className="nav">
      {menuItems && (
        <Menubar
          className="menu"
          model={items}
          start={<img alt="menu" src={logo} className="menu-logo" onClick={() => history.push(routes.home)} />}
          end={<Authentification />}
        />
      )}
    </nav>
  );
};

export default Menu;
