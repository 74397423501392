import React from 'react';
import { useSelector } from 'react-redux';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import CommonTab from './CommonTab';
import TierButton from './TierButton';
import withInitialization from '../higherOrderComponents/withInitialization';
import routes from '../constants/routes';
import tiersTypes from '../constants/tiersTypes';
import {
  getTierAlertsAmount,
  getTierPrice,
  getTierLocationsAmount,
  getTierExecutionsAmount,
  getTierAlertFrequency,
} from '../helpers/getTierInfo';
import useWindowDimensions from '../hooks/useWindowDimensions';

import '../styles/tiersInfo.css';

const SCREEN_WIDTH_BREAKPOINT = 700;
const tierProperties = [
  {
    name: 'Tier Price',
    title: '',
    getValue: getTierPrice,
  },
  {
    name: 'alertsAmount',
    title: 'Concurrent Alerts',
    getValue: getTierAlertsAmount,
  },
  {
    name: 'locationsAmount',
    title: 'Search Locations',
    getValue: getTierLocationsAmount,
  },
  {
    name: 'alertRate',
    title: 'Alert Frequency',
    getValue: getTierAlertFrequency,
  },
  {
    name: 'alertTime',
    title: 'Email Notifications',
    getValue: getTierExecutionsAmount,
  },
  {
    name: 'button',
    title: '',
    getValue: ({ id }) => (<TierButton id={id} />),
  },
];

const TiersInfoTable = ({ tiers }) => {
  const tiersTableData = tierProperties.map(({ name, title, getValue }) => ({
    property: name,
    anon: title,
    ...tiers.reduce((result, tierInfo) => ({
      ...result,
      [tierInfo.id]: getValue(tierInfo),
    }), {}),
  }));

  return (
    <DataTable
      value={tiersTableData}
      resizableColumns
      columnResizeMode="expand"
      showGridlines
      reorderable
    >
      <Column field="anon" header="" className="tiers-column" />
      { tiers.map(({ id, tierName }) => (
        <Column
          key={id}
          field={id}
          header={tierName}
          className="tiers-column"
        />
      )) }
    </DataTable>
  );
};

const TiersInfoList = ({ tiers }) => (
  <ul className="tiers-info-list">
    {
      tiers.map((tier) => (
        <li key={tier.id} className="tier-info-card">
          <h3>{tier.tierName}</h3>
          <div>
            {
              tierProperties.map(({ name, title, getValue }) => (
                <div key={name} className="tier-property">
                  <span>
                    { title || name }
                    :
                  </span>
                  <span>{getValue(tier)}</span>
                </div>
              ))
            }
          </div>
        </li>
      ))
    }
  </ul>
);

const TiersInfo = () => {
  const { tiers } = useSelector((state) => state.socket.config.service);
  const { width } = useWindowDimensions();
  const filteredTiers = tiers
    .filter(({ id }) => id !== tiersTypes.anon)
    .sort((left, right) => left.maxLocations - right.maxLocations);

  return (
    <div className="tiers-info-wrapper">
      <CommonTab title="Subscription Options" routeRedirect={routes.settings} />
      <div className="tiers-info">
        <div className="setting-card">
          <h3>Upgrade to monitor all our marketplaces. More often, over more locations.</h3>
          { width < SCREEN_WIDTH_BREAKPOINT
            ? <TiersInfoList tiers={filteredTiers} />
            : <TiersInfoTable tiers={filteredTiers} /> }
        </div>
      </div>
    </div>
  );
};

export default withInitialization(TiersInfo);
