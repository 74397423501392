import { combineReducers } from 'redux';
import searchReducer from './search.reducer';
import userReducer from './user.reducer';
import appReducer from './app.reducer';
import socketReducer from './socket.reducer';
import marketplacePanelReducer from './marketplacePanel.reducer';
import alertsReducer from './alerts.reducer';

const rootReducer = combineReducers({
  user: userReducer,
  app: appReducer,
  socket: socketReducer,
  search: searchReducer,
  marketplaces: marketplacePanelReducer,
  alerts: alertsReducer,
});

export default rootReducer;
