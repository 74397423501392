const analyticsEvents = {
  shareList: 'share_list',
  viewList: 'view_list',
  deleteAlert: 'delete_alert',
  createAlert: 'create_alert',
  login: 'login',
  logout: 'logout',
  signup: 'signup',
  search: 'search',
  viewItem: 'view_item',
  quickViewItem: 'quick_view_item',
  itemsReceived: 'items_received',
  launch: 'launch',
  interstitial: 'interstitial'
};

export default analyticsEvents;
