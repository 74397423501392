import './ItemCardMobile.css';

const AdvertCardMobile = ({ itemInfo }) => {
  const imagePath = itemInfo.acf.landscape_image
  const link = itemInfo.acf.target_url
  const className = `${itemInfo.type}-${itemInfo.acf.card_type}-${itemInfo.slug}`.toLowerCase()
  if (!imagePath) {
    return null
  }
  return (
    <a href={link} className={className} rel="noopener noreferrer" target="_blank">
      <div className={'item-card item-card-mobile'} style={{justifyContent: 'center'}}>
        <img
          src={imagePath}
          alt="ad"
          height='100%'
          className="advert-card__image"
        />
      </div>
    </a>
  );
};

export default AdvertCardMobile;
