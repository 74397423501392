import config from '../helpers/environment';

export const init = () => {
  const initCallback = () => {
    window.FB.init({
      appId: config.facebookId,
      autoLogAppEvents: true,
      xfbml: true,
      version: 'v11.0',
    });
  };
  window.FB ? initCallback() : window.fbAsyncInit = initCallback;
};

export const shareLink = (href, quote) => {
  window.FB.ui({
    method: 'share',
    href,
    quote,
  });
};
