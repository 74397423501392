const systemMessages = {
  authVerificationSuccess: 'Thanks for verifying your email',
  authResetSuccess: 'Password successfully reset',
  authForgotPasswordEmailSentSuccess: 'New password request sent, please check your email',
  copyLinkSuccess: 'Share search link is copied',
  shareMailAlerts: 'Please share your email with us so we can send you alerts',
  shareMailSubscription: 'Please share your email address with us so you can upgrade',
  notAuthorizedUserAlerts:
    'You will need to register to save this search so we can keep looking for you.',
  pleaseRegister:
    'Please Login to Create an Alert  ',
  maxAlertsLimitExceed:
    'You have used your saved alerts, you will need to remove some before adding more.',
  maxSearchesLimitExceed: 'Please sign up with Rumage for a better service and continue bargain hunting with us!',
  authUpdateEmailSuccess: 'Thanks, we now have your new email address',
  signupNudge: 'Would you like to save this search and get new results every day for free?',
  signupNudge2: 'Don’t have time to search every day? Ok if we email new results to you every day?',
  createAlertNudge: 'Tap Create Alert to get daily updates on this search straight to your inbox for free',
  signupMessage: 'Sign up and get into Rumage Street for deals, ' +
    'discounts and offers, and get lots of local stuff sent to your inbox.',
  findItemsLocally: 'Find more items locally?',
  setAnEmailAlert: 'Set up an email alert to get new results?',
  youMustBeLoggedIn: 'You must be logged in to favorite an item.',
  commissionMessage: 'We make a tiny commission if you purchase from our partners.',
};

export default systemMessages;
