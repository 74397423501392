import CommonTab from 'components/CommonTab';
import Favorites from 'components/Favorites/Favorites';
import withInitialization from 'higherOrderComponents/withInitialization';

const FavoritesScreen = () => {
  return (
    <>
      <CommonTab title="Favourites" />
      <Favorites />
    </>
  );
};

export default withInitialization(FavoritesScreen);
