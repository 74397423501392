import RecentlyViewed from 'components/RecentlyViewed/RecentlyViewed';
import RecentSearches from 'components/RecentSearches/RecentSearches';
import './Recents.css';
import Favorites from '../Favorites/Favorites';
import {useSelector} from 'react-redux';

const Recents = () => {
  const favoriteItems = useSelector((state) => state.user.favoriteItems);
  return (
    <div className="recents-wrapper">
      <RecentlyViewed />
      <RecentSearches />
      {favoriteItems.length ? <Favorites limit={10} className="main-page-favorites" isMainPage /> : null}
    </div>
  )
}

export default Recents;
