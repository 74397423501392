import { InputText } from 'primereact/inputtext';
import { setSearchShowHistory } from 'redux/actions/search.actions';
import StyledButton from '../StyledButton/StyledButton';

import '../../styles/searchComponent.css';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

const SEARCH_INPUT_ID = 'searchInput';

const HomePageSearchComponent = ({ search, searchTerm, onValueChange, checkKeyInput, children }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  return (
    <>
      <p className="slogan-banner">
        <span className="first-row">ALL SECOND HAND</span>
        <span className="second-row"> ALL IN ONE PLACE</span>
      </p>
      <div className="search-wrapper home-page">
        <div className="search-input">
          <InputText
            id={SEARCH_INPUT_ID}
            autoComplete="off"
            value={search.searchTerm}
            onChange={onValueChange}
            onKeyUp={checkKeyInput}
            onFocus={() => dispatch(setSearchShowHistory(true))}
            onClick={() => dispatch(setSearchShowHistory(true))}
            placeholder="start searching here!"
            className="search-component-input"
          />
        </div>
        <StyledButton
          className="p-button-text search"
          aria-label="search"
          onClick={() => history.push(`/search?term=${searchTerm.replace(/ /g, '+')}`)}
          useOnlyPropClass
        >
          <i className="pi pi-search" />
        </StyledButton>
        {children}
      </div>
    </>
  );
};

export default HomePageSearchComponent;
