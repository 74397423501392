import { InputText } from 'primereact/inputtext';
import { setSearchShowHistory } from 'redux/actions/search.actions';
import { isMobile } from 'react-device-detect';
import StyledButton from '../StyledButton/StyledButton';

import '../../styles/searchComponent.css';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Menubar } from 'primereact/menubar';
import routes from '../../constants/routes';
import { isAnon } from '../../services/user.service';
import { useState, useEffect } from 'react';

const SEARCH_INPUT_ID = 'searchInput';

const SearchPageSearchComponent = ({ search, onValueChange, checkKeyInput, children }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const socket = useSelector((state) => state.socket);
  const [items, setItems] = useState();

  const menuItems = [
    {
      label: 'Account',
      command: () => {
        history.push(routes.settings);
      },
      icon: 'pi pi-fw pi-user',
    },
    {
      label: 'Locations',
      command: () => {
        history.push(routes.locations);
      },
      icon: 'pi pi-fw pi-map-marker',
    },
    {
      label: 'Alerts',
      command: () => {
        history.push(routes.alerts);
      },
      icon: 'pi pi-fw pi-bell',
    },
    {
      label: 'Favorites',
      command: () => {
        history.push(routes.favourites);
      },
      icon: 'pi pi-fw pi-heart',
    },
    {
      label: 'Buyer`s Guide',
      command: () => {
        window.location.href = routes.buyersGuides;
      },
      icon: 'pi pi-fw pi-book',
    },
  ];

  useEffect(() => {
    if (socket.config === null) {
      return;
    }

    if (isAnon(socket)) {
      setItems(menuItems.filter((item) => item.label !== 'Account'));
    } else {
      setItems(menuItems);
    }
  }, [socket]);

  return (
    <>
      <div className={'search-wrapper band'}>
        {isMobile && (
          <StyledButton
            icon="pi pi-angle-left"
            className="back-button search-page"
            onClick={() => history.push(routes.home)}
            useOnlyPropClass
          />
        )}
        <div className={`search-input ${isMobile ? 'search-page' : 'desktop'}`}>
          <InputText
            id={SEARCH_INPUT_ID}
            autoComplete="off"
            value={search.searchTerm}
            onChange={onValueChange}
            onKeyUp={checkKeyInput}
            onFocus={() => dispatch(setSearchShowHistory(true))}
            onClick={() => dispatch(setSearchShowHistory(true))}
            placeholder="start searching here!"
            className="search-component-input"
          />
        </div>
        {isMobile && menuItems && (
          <nav className="nav search-page">
            <Menubar className="menu search-page" model={items} />
          </nav>
        )}
        {children}
      </div>
    </>
  );
};

export default SearchPageSearchComponent;
