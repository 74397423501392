import { Checkbox } from 'primereact/checkbox';
import { useDispatch, useSelector } from 'react-redux';
import { setMarketplacePanelVisibility } from 'redux/actions/marketplace.actions';
import { filterSearchAgents, setSearchAgentsItem, setAllAgentsCheckedStatus } from 'redux/actions/search.actions';
import config from 'helpers/environment';
import { selectMarketplaces } from 'redux/store/selectors';
import helpers from 'helpers';
import StyledButton from 'components/StyledButton/StyledButton';

import 'styles/searchFilter.css';

const FilterMarketplaces = () => {
  const { search } = useSelector((store) => store);
  const marketplaces = useSelector(selectMarketplaces);
  const { agents, filteredAgents } = search;
  const dispatch = useDispatch();
  const updateFilterAgents = (agent) => {
    dispatch(setSearchAgentsItem(agent.name));
    dispatch(filterSearchAgents());
    dispatch(setMarketplacePanelVisibility(false));
  };
  const toggleMarketplaces = (value) => () => {
    dispatch(setAllAgentsCheckedStatus(value));
  };

  return (
    <div className="filter-section">
      <div className="filter-section-header location-section-title">
        <div>
          {filteredAgents.length ? <span className="filterCount">({filteredAgents.length})</span> : null}
        </div>
        <div className="search-all-items">
          <StyledButton
            className="p-button-text"
            style={{fontFamily: 'NunitoSansRegular'}}
            label="All"
            onClick={toggleMarketplaces(true)}
            useOnlyPropClass
          />
          <StyledButton
            className="p-button-text"
            style={{fontFamily: 'NunitoSansRegular'}}
            label="Clear"
            onClick={toggleMarketplaces(false)}
            useOnlyPropClass
          />
        </div>
      </div>
      <div className="agents-checkboxes">
        {agents.map((agent) => {
          const marketplace = helpers.getMarketplaceInfo(marketplaces, agent.name);

          return (
            <div key={agent.name} className="search-checkbox" onClick={() => updateFilterAgents(agent)}>
              <Checkbox
                className="filter-item-checkbox"
                value={agent.name}
                checked={agent.checked}
              />
              <div className="marketplace-filter-img"  >
                <img key={agent.name} src={`${config.logoUrl}${marketplace.imageUrl}`} alt={agent.name} />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default FilterMarketplaces;
