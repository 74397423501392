import { useSelector } from 'react-redux';
import analyticsEvents from 'constants/analyticsEvents';
import config from 'helpers/environment';
import analytics from 'services/analytics.service';
import fallbackImage from 'assets/img/item-card-fallback-image.png';
import StyledButton from 'components/StyledButton/StyledButton';
import ReactDOM from 'react-dom';
import { useEffect, useRef, useState } from 'react';
import dateFormat from 'helpers/dateFormat';
import htmlCurrencySymbols from 'constants/htmlCurrencySymbols';
import { favoritesApi, quickviewApi } from 'services/restapi.service';
import Carousel from 'components/Carousel/Carousel';
import helpers from 'helpers';
import { useDispatch } from 'react-redux';
import { showToast } from 'redux/actions/app.actions';
import notificationEvents from 'constants/notificationEvents';
import 'components/QuickView/QuickView.css';
import { Backdrop } from '../Backdrop/Backdrop';
import { addFavoriteItem, deleteFavoriteItem } from '../../redux/actions/user.actions';
import ShareSearchList from 'components/ShareSearchList';
import {renderToastText} from '../../helpers/renderToastText';
import systemMessages from '../../constants/systemMessages';
import getItemRow from '../../helpers/getItemRow';

const additionalImagesAgents = ['shpock', 'vinted', 'preloved', 'gumtree'];
const additionalDataAgents = ['depop'];

const QuickView = ({ itemInfo, marketPlaceInfo, setShowQuickViewFalse }) => {
  const [fullDescr, setFullDescr] = useState(false);
  const [isFavorited, setIsFavorited] = useState(false);
  const [itemData, setItemData] = useState({
    title: itemInfo?.title,
    price: itemInfo?.price,
    currency: itemInfo?.currency,
    posted: itemInfo?.posted || itemInfo?.postedAt,
    description: itemInfo?.description,
    agent: itemInfo?.agent,
    link: itemInfo?.link,
    additionalImages: [itemInfo?.image],
    imageUrl: marketPlaceInfo?.imageUrl,
    id: itemInfo?.id,
    saleType: itemInfo?.saleType,
    apiUrl: itemInfo?.apiUrl,
  });
  const dispatch = useDispatch();
  const { tier, username: userId } = useSelector((state) => state.socket.config?.user);
  const favoriteItems = useSelector((state) => state.user.favoriteItems);
  const token = useSelector((state) => state.user.user.idToken?.jwtToken);
  const favoritedItem = favoriteItems.length && favoriteItems.find((item) => item.itemInfo.link === itemInfo.link);
  const isMountRef = useRef(true);
  const [showShareLinks, setShowShareLinks] = useState(false);
  const { socket } = useSelector((state) => state);
  const receipt = socket.receipt;
  const saleType = itemData.saleType ? itemData.saleType : 'fixed'
  const returnUrl = window.location.pathname + window.location.search

  useEffect(() => {
    const handleEsc = (e) => {
      if (e.key === 'Escape') {
        setShowQuickViewFalse();
      }
    };
    window.addEventListener('keydown', handleEsc);

    return () => {
      isMountRef.current = false;
      window.removeEventListener('keydown', handleEsc);
    };
  }, []);

  useEffect(() => {
    const getAdditionalData = async (quickviewEndpoint, url) => {
      try {
        const { data } = await quickviewApi[quickviewEndpoint]({ marketplace: itemData.agent, url });
        isMountRef.current && setItemData((prevState) => ({ ...prevState, ...data }));
      } catch (err) {
        dispatch(showToast({ text: err.message, type: notificationEvents.error }));
      }
    };

    if (additionalImagesAgents.includes(itemData.agent)) {
      getAdditionalData('getAdditionalImages', itemData.link);
    } else if (additionalDataAgents.includes(itemData.agent)) {
      getAdditionalData('getAdditionalItemData', itemData.apiUrl);
    } else if (itemInfo?.additionalImages) {
      isMountRef.current &&
        setItemData((prevState) => ({ ...prevState, additionalImages: itemInfo?.additionalImages }));
    }
  }, []);

  useEffect(() => {
    if (favoritedItem) {
      isMountRef.current && setIsFavorited(true);
    }
  }, []);

  const onHeartClick = async () => {
    try {
      if (!isFavorited) {
        const { data } = await favoritesApi.setFavoriteItem({ userId, itemInfo, marketPlaceInfo }, token);
        dispatch(addFavoriteItem({ itemInfo, marketPlaceInfo, id: data.id }));
        isMountRef.current && setIsFavorited(true);
      } else {
        await favoritesApi.deleteFavoriteItem(favoritedItem.id, token);
        dispatch(deleteFavoriteItem(favoritedItem.id));
        isMountRef.current && setIsFavorited(false);
      }
    } catch (err) {

      const link = `/login?returnUrl=${returnUrl}`

      if (err.response.status === 401) {
        dispatch(showToast({
          text: renderToastText(systemMessages.youMustBeLoggedIn, link),
          type: notificationEvents.error
        }));
      } else {
        dispatch(showToast({ text: err.message, type: notificationEvents.error }));
      }
    }
  };

  return (
    <main className="quickview-container">
      <div className="quickview-container__wrapper">
        <div className="quickview-container__header">
          <div className="icons-container">
            <i
              className={`pi ${isFavorited ? 'pi-heart-fill' : 'pi-heart'} quickview-container__heart`}
              style={{ fontSize: '2rem' }}
              onClick={onHeartClick}
            />

            <i
              className=" pi pi-share-alt"
              style={{ fontSize: '2rem', color: 'var(--pink-color)', cursor:'pointer' }}
              onClick={() => setShowShareLinks(!showShareLinks)}
            />
          </div>
          <div className='agent-logo-container'>
            <img src={`${config.logoUrl}${itemData.imageUrl}`} alt={itemData.agent} />
            <i
              className=" pi pi-times-circle"
              style={{ fontSize: '2rem', color: 'var(--pink-color)', cursor:'pointer' }}
              onClick={setShowQuickViewFalse}
            />
          </div>
        </div>
        {showShareLinks && (
          <div className="links-container">
            <ShareSearchList itemLink={itemData.link} />
          </div>
        )}
        <h1>{itemData.title}</h1>
        {itemData.additionalImages.length <= 1 ? (
          <img
            src={`${itemInfo.image ? itemInfo.image.replace(/^http:/, 'https:') : fallbackImage}`}
            className="quickview-container__item-image mb-1"
            alt="product"
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = fallbackImage;
            }} />
        ) : (
          <Carousel
            value={itemData.additionalImages}
            itemTemplate={(link) => (
              <img
                className="quickview-container__item-image"
                src={link}
                alt="product"
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = fallbackImage;
                }}
              />
            )}
          />
        )}
        <div className="quickview-container__content">
          <div className="quickview-container__price-and-date">
            <div style={{display:'flex'}}>
              {itemData.price ? (
                <p className="quickview-container__bold-p">
                  {helpers.isSaleTypeAuction(itemData.saleType) ? 'Starting bid:' : 'Price:'}{' '}
                  {htmlCurrencySymbols[itemData.currency.toUpperCase()] || '£'}
                  {itemData.price}
                </p>
              ) : (
                'FREE'
              )}
              {itemData.price ? (
                <p className="quickview-container__small-p quickview-container__small-p--saleType">
                  <span className={`item-card-saleType__block ${saleType}`} />
                </p>
              ) : null}
            </div>
            {itemData.posted && (
              <p className="quickview-container__small-p">Date posted: {dateFormat(itemData.posted, 'dd.mm.yyyy')}</p>
            )}
          </div>
          {itemData.description && (
            <div className="quickview-container__category">
              <p className="quickview-container__bold-p">Description</p>
              <p className="quickview-container__small-p quickview-container__small-p--description">
                {!fullDescr && itemData.description?.length > 100
                  ? `${itemData.description.substring(0, 100)}...`
                  : itemData.description}{' '}
                {!fullDescr && itemData?.description?.length > 100 && (
                  <span onClick={() => setFullDescr(true)}>Read more</span>
                )}
              </p>
            </div>
          )}
        </div>
      </div>
        <p className='quickview-container-commission-message'>{systemMessages.commissionMessage}</p>
      <div className="quickview-container__buttons-container">
        <StyledButton
          label="&lt; Back to listing"
          theme="filled-white-bg"
          className="quickview-container__button"
          onClick={setShowQuickViewFalse}
        />
        <a
          href={itemData.link}
          target="_blank"
          rel="noreferrer"
          onClick={() => {
            analytics.pushEvent(analyticsEvents.viewItem, {
              rumage_user_id: userId || null,
              marketplace: itemData.agent,
              item_price: itemData.price,
              tier,
              category_found: receipt.label[0],
              probability: receipt.prob[0],
              category_given: receipt.category,
              target1: itemData.link.substring(0,100),
              target2: itemData.link.substring(100,200),
              target3: itemData.link.substring(200,300),
              target4: itemData.link.substring(300,400),
              target5: itemData.link.substring(400,500),
              click_row : getItemRow(itemInfo.itemIndex, window.innerWidth)
            });
          }}
        >
          <StyledButton label="Go to Item &gt;" theme="filled-red-bg" className="quickview-container__button" />
        </a>
      </div>
    </main>
  );
};

const Modal = ({ itemInfo, marketPlaceInfo, setShowQuickViewFalse, updateFavorited }) => (
  <>
    {ReactDOM.createPortal(
      <Backdrop onBackdropClick={setShowQuickViewFalse} />,
      document.getElementById('backdrop-root')
    )}
    {ReactDOM.createPortal(
      <QuickView
        itemInfo={itemInfo}
        marketPlaceInfo={marketPlaceInfo}
        setShowQuickViewFalse={setShowQuickViewFalse}
        updateFavorited={updateFavorited}
      />,
      document.getElementById('overlay-root')
    )}
  </>
);

export default Modal;
