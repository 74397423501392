import tiersTypes from '../constants/tiersTypes';
import htmlCurrencySymbols from '../constants/htmlCurrencySymbols';

const currencySymbol = htmlCurrencySymbols.GBP;

export const getTierPrice = ({ id }) => {
  switch (id) {
    case tiersTypes.business:
      return `${currencySymbol}65.00/pa`;
    case tiersTypes.premium:
      return `${currencySymbol}15.00/pa`;
    default:
      return 'Free';
  }
};
export const getTierAlertsAmount = ({ maxAlerts }) => maxAlerts;
export const getTierLocationsAmount = ({ maxLocations }) => maxLocations;
export const getTierExecutionsAmount = ({ maxAlertExecutions }) => (typeof maxAlertExecutions === 'number'
  ? `${maxAlertExecutions} alerts` : 'Infinite');
export const getTierAlertFrequency = ({ alertFrequencies }) => `Up to ${[...alertFrequencies].pop().name}`;
