
export const renderToastText = (message, link) => {
  return (
    <span>{message}{' '}
      <a
        href={link}
        target='_blank'
        style={{ textDecoration: 'underline', cursor: 'pointer', margin: 0 }}
        rel="noreferrer">Log in
      </a>
    </span>
  )
}
