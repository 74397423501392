import htmlCurrencySymbols from 'constants/htmlCurrencySymbols';
import fallbackImage from 'assets/img/item-card-fallback-image.png';
import config from 'helpers/environment';
import { getDistance } from 'helpers/distance';
import './ItemCard.css';

const ItemCard = ({ itemInfo, marketPlaceInfo, onClick, style, className, showBlock = true }) => {
  const distance = getDistance(itemInfo);
  const currency = htmlCurrencySymbols[itemInfo.currency];
  const shippingType = itemInfo.shipping ? itemInfo.shipping : 'delivery'
  return (
    <div className={`item-card ${className}`} onClick={onClick} style={style}>
      <img
        src={`${itemInfo.image ? itemInfo.image.replace(/^http:/, 'https:') : fallbackImage}`}
        alt="iamge-alt"
        data-weight={itemInfo.distance}
        data-market-relevancy={itemInfo.relevancyScore}
        onError={({ currentTarget }) => {
          currentTarget.onerror = null;
          currentTarget.src= fallbackImage;
        }}
        className="item-card__image"
      />
      <div className="item-card-details">
        <div className="item-card-details__title">{itemInfo.title}</div>
        {itemInfo.center && (shippingType !== 'delivery') && (
          <span className="item-card-details__location">
            {itemInfo.center}
            {distance && ( <>{' '}{distance} miles</>)}
          </span>
        )}
        {showBlock && (
          <div className="item-card-details__delivery-block">
            <span className={`item-card-details__block ${shippingType}`}/>
            {itemInfo.agent === 'sooperlooper' && <span className={'item-card-details__block subscription'}/>}
          </div>
        )}
        <div className="item-card-details__agent-price">
          <img src={`${config.logoUrl}${marketPlaceInfo.imageUrl}`} alt={marketPlaceInfo.id} />
          <div>
            {itemInfo.price ? (
              <span className="item-card-details__price">
                {currency}
                {itemInfo.price}
              </span>
            ) : (
              <span>FREE</span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ItemCard;
