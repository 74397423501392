import {isAnon} from './user.service';
const anonLocationKey = 'anon_location';
export const getAnonLocation = (socket) => {
  if (isAnon(socket) && sessionStorage.getItem(anonLocationKey)) {
    return JSON.parse(sessionStorage.getItem(anonLocationKey))
  }

  return null;
}

export const setAnonLocation = (socket, location) => {
  if(isAnon(socket)) {
    sessionStorage.setItem(anonLocationKey, JSON.stringify(location))
  }
}
