import { createAction } from '@reduxjs/toolkit';
import {
  SHOW_TOAST,
  SHOW_WAIT_RESULTS,
  SEARCHING_LOCATION,
  CLEAR_TOAST,
  CLEAR_APP_REDUCER,
  SET_SEARCH_COMPLETED,
  SET_APP_INITIALIZED,
} from 'redux/actions/types/app.types';

export const showToast = createAction(SHOW_TOAST);
export const clearToast = createAction(CLEAR_TOAST);
export const showWaitResults = createAction(SHOW_WAIT_RESULTS);
export const searchingLocation = createAction(SEARCHING_LOCATION);
export const clearAppReducer = createAction(CLEAR_APP_REDUCER);
export const setSearchCompleted = createAction(SET_SEARCH_COMPLETED);
export const setAppInitialized = createAction(SET_APP_INITIALIZED);
