import { useSelector } from 'react-redux';
import SubscriptionManageButton from './SubscriptionManageButton/SubscriptionManageButton';
import tiersTypes from '../constants/tiersTypes';

const UPGRADE_BUTTON_LABEL = 'Upgrade';

const TierButton = ({ id: tierId }) => {
  const { tier } = useSelector((state) => state.socket.config.user);
  const { tiers } = useSelector((state) => state.socket.config.service);
  const { chargebeeId } = tiers.find((item) => item.id === tierId);

  const upgradeButton = (
    <SubscriptionManageButton
      label={UPGRADE_BUTTON_LABEL}
      chargebeeId={chargebeeId}
    />
  );

  const manageButton = (
    <SubscriptionManageButton
      chargebeeId={chargebeeId}
    />
  );

  switch (tierId) {
    case tiersTypes.premium:
      return tier === tiersTypes.premium
        ? manageButton
        : tier === tiersTypes.freemium
          ? upgradeButton
          : null;
    case tiersTypes.business:
      return tier === tiersTypes.business
        ? manageButton
        : upgradeButton;
    default:
      return null;
  }
};

export default TierButton;
