import { Card } from 'primereact/card';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import AlertItem from './AlertItem';
import CommonTab from './CommonTab';
import helpers from 'helpers';
import tiersTypes from 'constants/tiersTypes';
import useCurrentTierConfig from 'hooks/useCurrentTierConfig';
import withInitialization from 'higherOrderComponents/withInitialization';
import routes from 'constants/routes';

import 'styles/alerts.css';

const Alerts = () => {
  const history = useHistory();
  const { alerts, isAlertCreating } = useSelector((state) => state.alerts);
  const { maxAlerts, tierName, id: userTier } = useCurrentTierConfig();
  const isEmptySlotAvailable = alerts.length < maxAlerts;

  return (
    <>
      <CommonTab title="Search Alerts" />
      <div className="settings-alerts-panel">
        <Card>
          <div id="settings-alerts-list-wrapper">
            {userTier === tiersTypes.anon ? (
              <div className="cursor-pointer" onClick={() => history.push(routes.signup)}>
                Please sign up to set an alert
              </div>
            ) : (
              <div className="cursor-pointer">
                <br />
                As part of your <b>{tierName.toLowerCase()}</b> subscription, you can create <b>{maxAlerts}</b>{' '}
                {helpers.pluralise(maxAlerts, 'alert')}
                .
                <br />
                <br />
                {alerts.map((alert, i) => (
                  <AlertItem key={`${alert.submitted}${i}`} alert={alert} />
                ))}
                {isEmptySlotAvailable && !isAlertCreating ? (
                  <Card className="settings-alert-list-slot">
                    Search, then tap the <i className="pi pi-bell alerts-clock-icon" /> Alert Icon on the search page to
                    create an alert.
                  </Card>
                ) : isEmptySlotAvailable ? (
                  <Card className="settings-alert-list-slot">
                    Creating your alert <i className="pi pi-spin pi-spinner" />
                  </Card>
                ) : null}
                {new Array(Math.max(maxAlerts - alerts.length - 1, 0)).map((_, index) => (
                  <Card className="settings-alert-list-slot" key={`Empty${index}`} />
                ))}
              </div>
            )}
          </div>
        </Card>
      </div>
    </>
  );
};

export default withInitialization(Alerts);
