// import AgentLogoComponent from '../AgentLogoComponent';
import Menu from '../Menu';
import Recents from '../Recents/Recents';
import SearchComponent from '../SearchComponent/SearchComponent';
import SplashContent from '../SplashContent/SplashContent';

import 'styles/mainPage.css';
import { useHistory } from 'react-router';
import useUrlQuery from '../../hooks/useUrlQuery';
import routes from '../../constants/routes';
import {useState} from 'react';

const MainPage = () => {
  const { term } = useUrlQuery();
  const history = useHistory();
  const [hasUserScrolled, setHasUserScrolled]= useState(false)

  if (term) {
    history.push(routes.searchWithTerm(term));
    return null;
  }
  const handleScroll = (e) => {
    if(e.target.scrollTop > 0) {
      setHasUserScrolled(true)
    }else {setHasUserScrolled(false)}
  }

  return (
    <div className="main-page" onScroll={handleScroll}>
      <link rel="stylesheet" href="https://rumage-css-dev.s3.eu-west-2.amazonaws.com/style-4938.css" />
      <link rel="stylesheet" href="https://rumage-css-dev.s3.eu-west-2.amazonaws.com/style.min.css" />
      <link rel="stylesheet" href="https://rumage-css-dev.s3.eu-west-2.amazonaws.com/main.min.css" />
      <Menu />
      <div className="fixed-content">
        <SearchComponent handleDoneButton={() => {}} areFiltersShown={false} hasUserScrolled={hasUserScrolled} />
      </div>
      <>
        <Recents />
        <SplashContent />
        {/*<AgentLogoComponent />*/}
      </>
    </div>
  );
};

export default MainPage;
