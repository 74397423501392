import React from 'react'
import './NudgeBar.css'
import nudgeBarMessages from '../../constants/nudgeBarMessages';
import StyledButton from '../StyledButton/StyledButton';
import getRandomValue from '../../helpers/getRandomValue';

const NudgeBar = () => {
  const length = Object.keys(nudgeBarMessages).length;
  const randomResult = getRandomValue(length);
  return (
    <div className='container' >
      <h3 className='nudgeText'>
        {nudgeBarMessages[randomResult]}
      </h3>
      <a href={`/signup?prompt=${randomResult}`} className='buttonContainer'>
        <StyledButton
          theme='filled-red-bg'
          style={{justifyContent: 'center'}}
        >
          <h4 className='buttonLabel'>Sign up</h4>
        </StyledButton>
      </a>
    </div>
  )
}
export default React.memo(NudgeBar);
