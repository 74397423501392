import Alerts from 'components/Alerts';
import ChangeEmail from 'components/ChangeEmail';
import ConfirmEmail from 'components/ConfirmEmail';
import ForgotPassword from 'components/ForgotPassword';
import GoogleAnalytics from 'components/GoogleAnalytics';
import Locations from 'components/Locations';
import LogIn from 'components/LogIn/LogIn';
import HomePage from 'components/HomePage/HomePage';
import SearchPage from 'components/SearchPage/SearchPage';
import Notification from 'components/Notification';
import ResetPassword from 'components/ResetPassword';
import Settings from 'components/Settings/Settings';
import Signup from 'components/SignUp';
import TiersInfo from 'components/TiersInfo';
import routes from 'constants/routes';
import { useEffect } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { BrowserRouter, Route } from 'react-router-dom';
import Favorites from 'screens/FavoritesScreen/FavoritesScreen';
import { initSocket } from 'services/api.service';
import { init } from 'services/auth.service';
import { init as initFacebook } from 'services/facebook.service';

const App = () => {
  const dispatch = useDispatch();
  const socket = useSelector((state) => state.socket);
  const receipt = socket.receipt;

  useEffect(() => {
    initFacebook();
    initSocket(dispatch, receipt);
    init(dispatch);
  }, [dispatch]);

  return (
    <BrowserRouter>
      <Route component={HomePage} path={routes.home} exact />
      <Route component={SearchPage} path={routes.search} exact />
      <Route component={ForgotPassword} path={routes.forgotPassword} />
      <Route component={ResetPassword} path={routes.resetPassword} />
      <Route component={Alerts} path={routes.alerts} />
      <Route component={ConfirmEmail} path={routes.confirmEmail} />
      <Route component={TiersInfo} path={routes.tiersInfo} />
      <Route component={Locations} path={routes.locations} />
      <Route component={Signup} path={routes.signup} />
      <Route component={Settings} path={routes.settings} />
      <Route component={ChangeEmail} path={routes.changeEmail} />
      <Route component={LogIn} path={routes.login} />
      <Route component={Favorites} path={routes.favourites} />
      <Notification />
      <GoogleAnalytics />
    </BrowserRouter>
  );
};

export default App;
