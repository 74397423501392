import shippingTypes from '../constants/shippingTypes';

export function getDistance(item = {}, getInt = false) {
  if (!item.distance || !item.center) return getInt ? Infinity : '';
  let { distance } = item;
  if (item.distance && item.distanceUnit?.toLowerCase() === 'km') {
    distance = parseInt(item.distance * 0.62, 10);
  }
  return getInt ? Number(distance.toFixed(2)) : distance.toFixed(2);
}

export function distance(results) {
  const withLocationItems = [];
  const nationalItems = [];

  results.forEach((item) => {
    const isValidLocationItem = item.shipping === shippingTypes.collect
      && item.distance && item.center;

    return (
      isValidLocationItem ? withLocationItems.push(item) : nationalItems.push(item));
  });

  const withLocationSortedItems = withLocationItems.sort((a, b) => {
    const comparisonResult = getDistance(a, true) - getDistance(b, true);

    if (!comparisonResult) {
      return a.price - b.price;
    }

    return comparisonResult;
  });

  const nationalSortedItems = nationalItems.sort((a, b) => a.price - b.price);

  return withLocationSortedItems.concat(nationalSortedItems);
}
