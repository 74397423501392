const base64Mapper = {
  mapToString(dataToMap) {
    return btoa(JSON.stringify(dataToMap));
  },
  mapFromString(dataToMap) {
    return JSON.parse(atob(dataToMap));
  },
};

export default base64Mapper;
