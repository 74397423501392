import urlQueries from '../constants/urlQueries';

const parseUrlQuery = (query) => query
  .replace(urlQueries.search, '')
  .split(urlQueries.and)
  .reduce((result, param) => {
    const [paramName, paramValue] = param.split(urlQueries.equal);

    return {
      ...result,rawQuery: query,
      [paramName]: paramValue,
    };
  }, {});

export default parseUrlQuery;
