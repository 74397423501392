import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import FormContainer from 'components/FormContainer';
import { confirm, confirmUpdatedEmail, init, resendSignup } from 'services/auth.service';
import BackButton from 'components/BackButton';
import Form from 'components/Form';
import routes from 'constants/routes';
import { emailFieldConfiguration } from 'constants/defaultFieldsConfigurations';
import { requiredValidator } from 'constants/validators';
import { isAnon } from 'services/user.service';
import { showToast } from 'redux/actions/app.actions';
import notificationEvents from 'constants/notificationEvents';
import withInitialization from 'higherOrderComponents/withInitialization';
import systemMessages from 'constants/systemMessages';
import StyledButton from 'components/StyledButton/StyledButton';

import 'styles/customInput.css';
import helpers from 'helpers';
import storage from 'services/localStorage.service';
import FormParagraph from './FormParagraph/FormParagraph';

const fieldsConfig = [
  {
    label: 'Verification code',
    fieldName: 'verificationCode',
    validators: {
      required: requiredValidator,
    },
  },
];

const ConfirmEmail = () => {
  const [formState, setFormState] = useState(null);
  const [timeLeft, setTimeLeft] = useState(null);
  const [wasResent, setWasResent] = useState(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const { socket } = useSelector((state) => state);
  const isUserAnon = isAnon(socket);
  const isResendButtonDisabled = !formState?.values.email || !!formState?.state.errors.email || timeLeft;

  useEffect(() => {
    if (!storage.getVerificationEmailResendCooldown()) {
      return;
    }

    if (storage.getVerificationEmailResendCooldown() - new Date() <= 0) {
      storage.removeVerificationEmailResendCooldown();
    }

    function updateTimer(storedCooldown) {
      const minutes = Math.floor((storedCooldown() - new Date()) / 1000 / 60);
      const seconds = Math.floor((storedCooldown() - new Date()) / 1000 - minutes * 60);

      setTimeLeft({ minutes, seconds });

      if (minutes < 0) {
        setWasResent(null);
        setTimeLeft(null);
      }
    }

    const resendCountdown =
      storage.getVerificationEmailResendCooldown() &&
      helpers.setIntervalImmediately(updateTimer.bind(null, storage.getVerificationEmailResendCooldown), 1000);

    if (!storage.getVerificationEmailResendCooldown()) {
      clearInterval(resendCountdown);
    }

    return () => clearInterval(resendCountdown);
  }, [wasResent]);

  const handleConfirm = async (data) => {
    if (!isUserAnon) {
      try {
        await confirmUpdatedEmail(data.verificationCode);
        init(dispatch);
        history.push(routes.home);
        dispatch(
          showToast({
            text: systemMessages.authUpdateEmailSuccess,
            type: notificationEvents.success,
          })
        );
      } catch (error) {
        dispatch(
          showToast({
            text: error.message,
            type: notificationEvents.error,
          })
        );
      }
    } else {
      const user = await confirm(data, dispatch);
      if (user) {
        history.push(routes.login);
      }
    }
  };
  const handleResendVerification = async () => {
    if (await resendSignup(formState.values.email, dispatch)) {
      storage.setVerificationEmailResendCooldown();
      setWasResent(true);
    }
  };

  return (
    <FormContainer formTitle="Verify your Email Address" showBackButton>
      <BackButton routeRedirect={isUserAnon ? routes.login : routes.settings} />
      <FormParagraph>
        We&#39;ve sent you a verification email. Please click the link in the email or enter your email address and the
        code below.
      </FormParagraph>
      <Form
        fieldsConfig={isUserAnon ? [emailFieldConfiguration, ...fieldsConfig] : fieldsConfig}
        onSubmit={handleConfirm}
        buttonLabel="Verify"
        updateFormState={setFormState}
      />
      {isUserAnon ? (
        <>
          <FormParagraph>
            Can&#39;t find our email? Check your spam folder? You can always click below and we&#39;ll resend it.
          </FormParagraph>
          <StyledButton
            label="Resend Verification Email"
            onClick={handleResendVerification}
            disabled={isResendButtonDisabled}
            theme="transparent-bg"
          />
          {timeLeft && (
            <FormParagraph>
              {`${timeLeft?.minutes} minutes and ${timeLeft?.seconds} seconds until you can send another verification 
              email.`}
            </FormParagraph>
          )}
        </>
      ) : null}
    </FormContainer>
  );
};

export default withInitialization(ConfirmEmail);
