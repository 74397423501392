const letterOrNumber = 'a-zA-Z0-9';
const specialCharacters = '.!#$%&\'*+/=?^_`{|}~-';

// eslint-disable-next-line
const localExpression = `[${letterOrNumber}]([${letterOrNumber}]|([${specialCharacters}](?![${specialCharacters}]))){0,62}[${letterOrNumber}]`;
const domainExpression = `[${letterOrNumber}](?:[${letterOrNumber}-]{0,61}[${letterOrNumber}])?`;
const emailRegexp = new RegExp(`^${localExpression}@${domainExpression}(?:\\.${domainExpression})+$`);

const validationPatterns = {
  email: emailRegexp,
};

export default validationPatterns;
