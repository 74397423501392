import { createReducer } from '@reduxjs/toolkit';
import {
  initUser,
  clearUserReducer,
  setUserHistory,
  setFavoriteItems,
  addFavoriteItem,
  deleteFavoriteItem,
  deleteAllFavoriteItems, setMappingFile,
} from 'redux/actions/user.actions';

const initialState = {
  user: {},
  history: [],
  favoriteItems: [],
  mappingFile: {},
};

const userReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(initUser, (state, action) => ({
      ...state,
      user: action.payload,
    }))
    .addCase(setFavoriteItems, (state, action) => ({
      ...state,
      favoriteItems: action.payload,
    }))
    .addCase(setMappingFile, (state, action) => ({
      ...state,
      mappingFile: action.payload,
    }))
    .addCase(addFavoriteItem, (state, action) => ({
      ...state,
      favoriteItems: [action.payload, ...state.favoriteItems],
    }))
    .addCase(deleteFavoriteItem, (state, action) => ({
      ...state,
      favoriteItems: state.favoriteItems.filter((item) => item.id !== action.payload),
    }))
    .addCase(deleteAllFavoriteItems, (state) => ({
      ...state,
      favoriteItems: [],
    }))
    .addCase(setUserHistory, (state, action) => ({
      ...state,
      history: action.payload,
    }))
    .addCase(clearUserReducer, () => ({
      ...initialState,
    }));
});

export default userReducer;
