import {distance} from './distance';
import trigramSimilarity from 'trigram-similarity';
import jwDistance from 'jaro-winkler';
import {receipt} from '../services/api.service';

const removeSymbols = (string) => string.replace(/[^\w\s]/gi, '');

const checkMatchTerm = (title, searchTerm) => {
  const titleArr = title.split(' ');

  return searchTerm.split(' ').filter((word) => !titleArr.includes(word)).length === 0;
};

const checkFirstPosition = (condition, index) => condition && !index;
const checkIsContain = (condition, index, title, searchTerm) => condition && index
  && (index < title.length - searchTerm.length);
const checkLastPosition = (condition, index) => condition && index;

const sumDistances = (words, match) => {
  let weight = 0;
  words.forEach(w => {
    const toAdd = match[w];
    if(toAdd) weight += match[w]
  })
  return weight;
}

const calculateWeight = (itemTitle, tfidfMatrix) => {
  const keyList = Object.keys(tfidfMatrix);
  const presence = keyList.find(s => itemTitle.toLowerCase().includes(s.toLowerCase()));
  if (presence) {
    const match = tfidfMatrix[presence];
    const words = itemTitle.split(' ');
    return sumDistances(words, match);
  }
  return 0;
}

const chunks = (array) => {
  const chunkSize = 10;
  const chunks = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    const chunk = array.slice(i, i + chunkSize);
    chunks.push(chunk);
  }
  return chunks;
}

const sortByRelevanceAndDistance = (resultsArray, term, tfidfMatrix) => {
  // keep this for test build purpose
  // Prepare the results to be sorted using distance.
  let resultsWithDistances = resultsArray.map(item => ({
    ...item,
    word: item.title,
    distance: calculateWeight(item.title, tfidfMatrix)
  }));

  if (!resultsWithDistances.find(e => e.distance > 0)) {
    return sortByRelevanceAndDistanceTrigram(resultsArray, term);
  }
  // Create a bi bidimensional array to sort using the relevancy score.
  // This will help with spreading the results.
  const sortedByWordDistanceChunks = chunks(resultsWithDistances.sort((a, b) => b.distance - a.distance));

  // Sort the chunks by relevancy score and distance - if locations are selected
  return sortedByWordDistanceChunks.map((chunk) => withRelevancyScore(distance(chunk))).flat()
}

export const sortByRelevanceAndDistanceBase = (resultsArray, term) => {
  const totalMatch = [];
  const containsOnlySearchWords = [];
  const startWithSearchTerm = [];
  const containSearchTerm = [];
  const endWithSearchTerm = [];
  const startWithSearchTermWords = [];
  const containSearchTermWords = [];
  const endWithSearchTermWords = [];
  const containsAllSearchTermWords = [];
  const containsSomeSearchTermWords = [];
  const notRelevant = [];
  const searchTerm = removeSymbols(term).toLowerCase();

  resultsArray.forEach((item) => {
    const title = removeSymbols(item.title).toLowerCase();

    if (title === searchTerm) {
      return totalMatch.push(item);
    }

    if (title.length === searchTerm.length && checkMatchTerm(title, searchTerm)) {
      return containsOnlySearchWords.push(item);
    }

    const searchTermIndex = title.indexOf(searchTerm);

    if (checkFirstPosition(title.includes(searchTerm), searchTermIndex)) {
      return startWithSearchTerm.push(item);
    }

    if (checkIsContain(title.includes(searchTerm), searchTermIndex, title, searchTerm)) {
      return containSearchTerm.push(item);
    }

    if (checkLastPosition(title.includes(searchTerm), searchTermIndex)) {
      return endWithSearchTerm.push(item);
    }

    const space = ' ';
    const termArray = searchTerm.split(space);
    const titleArray = title.split(space);
    const filteredTitleArray = titleArray.filter((word) => termArray.includes(word));
    const isContainSearchWords = filteredTitleArray.length === termArray.length;
    const isContainTogetherSearchWords = isContainSearchWords
      && titleArray.join(space).includes(filteredTitleArray.join(space));
    const [firstWord] = filteredTitleArray;
    const searchTermWordIndex = title.indexOf(firstWord);

    if (checkFirstPosition(isContainTogetherSearchWords, searchTermWordIndex)) {
      return startWithSearchTermWords.push(item);
    }

    if (checkIsContain(isContainTogetherSearchWords, searchTermWordIndex, title, searchTerm)) {
      return containSearchTermWords.push(item);
    }

    if (checkLastPosition(isContainTogetherSearchWords, searchTermWordIndex)) {
      return endWithSearchTermWords.push(item);
    }

    if (isContainSearchWords) {
      return containsAllSearchTermWords.push(item);
    }

    if (filteredTitleArray.length > 0) {
      return containsSomeSearchTermWords.push(item);
    }

    return notRelevant.push(item);
  });

  return [
    ...withRelevancyScore(distance(totalMatch)),
    ...withRelevancyScore(distance(containsOnlySearchWords)),
    ...withRelevancyScore(distance(startWithSearchTerm)),
    ...withRelevancyScore(distance(containSearchTerm)),
    ...withRelevancyScore(distance(endWithSearchTerm)),
    ...withRelevancyScore(distance(startWithSearchTermWords)),
    ...withRelevancyScore(distance(containSearchTermWords)),
    ...withRelevancyScore(distance(endWithSearchTermWords)),
    ...withRelevancyScore(distance(containsAllSearchTermWords)),
    ...withRelevancyScore(distance(containsSomeSearchTermWords)),
    ...withRelevancyScore(distance(notRelevant)),
  ];
};

const withRelevancyScore = (items) => {
  const _items = items.sort((a,b)=> b.relevancyScore - a.relevancyScore)
  const results = []
  const agents = new Map();
  _items.map(item => {
    if (!agents.has(item.agent)) {
      agents.set(item.agent, []);
    }
    agents.get(item.agent).push(item);
  })
  for (let i = 0; i < items.length; i ++) {
    for (const agent of agents.keys()) {
      const items = agents.get(agent);
      if (items.length > 0) {
        results.push(items.shift());
      }
    }
  }
  return results
}

const levenshteinDistance = (a, b) => {
  let distances = new Array(a.length + 1);
  for (let i = 0; i <= a.length; i++) {
    distances[i] = new Array(b.length + 1);
  }

  for (let i = 0; i <= a.length; i++) {
    distances[i][0] = i;
  }
  for (let j = 0; j <= b.length; j++) {
    distances[0][j] = j;
  }

  for (let i = 1; i <= a.length; i++) {
    for (let j = 1; j <= b.length; j++) {
      if (a[i - 1] === b[j - 1]) {
        distances[i][j] = distances[i - 1][j - 1];
      } else {
        distances[i][j] = Math.min(distances[i - 1][j], distances[i][j - 1], distances[i - 1][j - 1]) + 1;
      }
    }
  }

  return distances[a.length][b.length];
}

export const sortByRelevanceAndDistanceLevenshtein = (resultsArray, term) => {
  // keep this for test build purpose
  // Prepare the results to be sorted using distance.
  let resultsWithDistances = resultsArray.map(item => ({
    ...item,
    word: item.title,
    distance: levenshteinDistance(item.title, term)
  }));

  // Create a bi bidimensional array to sort using the relevancy score.
  // This will help with spreading the results.
  const sortedByWordDistanceChunks = chunks(resultsWithDistances.sort((a, b) => b.distance - a.distance));

  // Sort the chunks by relevancy score and distance - if locations are selected
  return sortedByWordDistanceChunks.map((chunk) => withRelevancyScore(distance(chunk))).flat()
}

function dotp(x, y) {
  function dotpSum(a, b) {
    return a + b;
  }
  function dotpTimes(a, i) {
    return x[i] * y[i];
  }
  return x.map(dotpTimes).reduce(dotpSum, 0);
}

function cosineSimilarity(A,B){
  return dotp(A, B) / (Math.sqrt(dotp(A, A)) * Math.sqrt(dotp(B, B)));
}

export const sortByRelevanceAndDistanceCosine = (resultsArray, term) => {
  // keep this for test build purpose
  // Prepare the results to be sorted using distance.
  let resultsWithDistances = resultsArray.map(item => ({
    ...item,
    word: item.title,
    distance: cosineSimilarity(item.title.split(), term.split())
  }));

  // Create a bi bidimensional array to sort using the relevancy score.
  // This will help with spreading the results.
  const sortedByWordDistanceChunks = chunks(resultsWithDistances.sort((a, b) => b.distance - a.distance));

  // Sort the chunks by relevancy score and distance - if locations are selected
  return sortedByWordDistanceChunks.map((chunk) => withRelevancyScore(distance(chunk))).flat()
}

const getAdditionalWeightFromFullCats = (item, category) => {
  try {
    if (!item.categories) return 0;
    return item.categories.prob.reduce(
      (accumulator, currentValue, index) => {
        if (
          currentValue > 0.2 &&
          category.prob[index] > 0.2 &&
          category.label.includes(item.categories.label[index])
        ) {
          return accumulator + 1
        } else {
          return accumulator + 0
        }
      },
      0);
  } catch {
    return 0;
  }

}

export const sortByRelevanceAndDistanceTrigram = (resultsArray, term) => {
  // keep this for test build purpose
  // Prepare the results to be sorted using distance.
  const category = receipt.getReceipt().fullcats;
  let resultsWithDistances = resultsArray.map(item => ({
    ...item,
    word: item.title,
    distance: parseFloat(
      (trigramSimilarity(item.title, term) +
      getAdditionalWeightFromFullCats(item, category)).toFixed(2)
    )
  }));

  // Create a bi bidimensional array to sort using the relevancy score.
  // This will help with spreading the results.
  const sortedByWordDistanceChunks = chunks(resultsWithDistances.sort((a, b) => b.distance - a.distance));

  // Sort the chunks by relevancy score and distance - if locations are selected
  return sortedByWordDistanceChunks.map((chunk) => withRelevancyScore(distance(chunk))).flat()
}
export const sortByRelevanceAndDistanceJW = (resultsArray, term) => {
  // keep this for test build purpose
  // Prepare the results to be sorted using distance.
  let resultsWithDistances = resultsArray.map(item => ({
    ...item,
    word: item.title,
    distance: jwDistance(item.title, term)
  }));

  // Create a bi bidimensional array to sort using the relevancy score.
  // This will help with spreading the results.
  const sortedByWordDistanceChunks = chunks(resultsWithDistances.sort((a, b) => b.distance - a.distance));

  // Sort the chunks by relevancy score and distance - if locations are selected
  return sortedByWordDistanceChunks.map((chunk) => withRelevancyScore(distance(chunk))).flat()
}

export default sortByRelevanceAndDistance;
