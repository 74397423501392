import { Carousel } from 'primereact/carousel';

const PrimeCarousel = ({ value, itemTemplate, ...props }) => (
  <Carousel
    value={value}
    numVisible={1}
    numScroll={1}
    itemTemplate={itemTemplate}
    {...props}
  />
);

export default PrimeCarousel;
