const urlQueries = {
  slash: '/',
  search: '?',
  and: '&',
  equal: '=',
  searchId: 'searchResultSetId',
  searchInfo: 'searchInfo',
  term: 'term',
};

export default urlQueries;
