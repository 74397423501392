import React from 'react'
import { Dialog } from 'primereact/dialog';
import StyledButton from 'components/StyledButton/StyledButton';

import './Nudge.css'
import nudgeBarMessages from '../../constants/nudgeBarMessages';
import getRandomValue from '../../helpers/getRandomValue';

const Nudge = ({ isVisible, setIsVisible }) => {
  const handleNoClick = () => {
    setIsVisible(false);
  };

  const length = Object.keys(nudgeBarMessages).length;
  const randomResult = getRandomValue(length);
  const renderHeader = () => (
    <p className='header'>Register?</p>
  )
  const renderFooter = () => (
    <div className="footer" >
      <StyledButton
        label="Not yet"
        onClick={handleNoClick}
        className="p-button-text no-button "
        style={{ color: 'black', backgroundColor: 'white', borderRadius: '20px'}}
        useOnlyPropClass
      />
      <a href={`/signup?prompt=${randomResult}`}>
        <StyledButton
          label="Sign up"
          className="yes-button"
          style={{ borderRadius: '20px', border: 'none'}}
          useOnlyPropClass
        />
      </a>
    </div>
  );

  return (
    <Dialog
      visible={isVisible}
      onHide={() => setIsVisible(false)}
      dismissableMask
      position="center"
      draggable={false}
      closable={false}
      header={renderHeader()}
      footer={renderFooter()}
      className="nudge-container"
    >
      <p className="content" >
        {nudgeBarMessages[randomResult]}
      </p>
    </Dialog>
  );
};

export default React.memo(Nudge);
