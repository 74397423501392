import { useHistory } from 'react-router';
import routes from 'constants/routes';

import 'styles/formContainer.css';
import 'styles/customInput.css';
import videoBg from  'assets/video/shoes2.webm'
import posterImage from 'assets/video/coverimage.jpg'

const FormContainer = ({ formTitle, children, bannerText, headerButton }) => {
  const history = useHistory();

  return (
    <>
      <video src={videoBg} poster={posterImage} autoPlay loop muted />
      <div className="form">
        <h1 className="form__logo" onClick={() => history.push(routes.home)}>
          <span className="form__title">First stop for secondhand</span>
        </h1>
        {bannerText ? <div className="form__banner">{bannerText}</div> : null}
        <div className="form__content">
          <div className="form__header">
            <h2>{formTitle}</h2>
            {headerButton}
          </div>
          {children}
        </div>
      </div>
    </>
  );
};

export default FormContainer;
