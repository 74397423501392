const routes = {
  home: '/',
  search: '/search',
  searchWithTerm: (term) => `/search?term=${term.replace(/ /g, '+')}`,
  locations: '/locations',
  alerts: '/alerts',
  blog: 'https://rumage.com/blog/',
  FAQs: 'https://rumage.com/faqs/',
  terms: 'https://rumage.com/terms/',
  privacy: 'https://rumage.com/privacy/',
  buyersGuides: 'https://rumage.com/buyers-guides/',
  rumageStreet: 'https://rumage.com/rumage-street/',
  tiersInfo: '/tiersinfo',
  confirmEmail: '/confirm',
  login: '/login',
  signup: '/signup',
  resetPassword: '/resetpassword',
  forgotPassword: '/forgotpassword',
  settings: '/settings',
  changeEmail: '/changeemail',
  favourites: '/favourites',
};

export default routes;
