import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import analytics from '../services/analytics.service';

const GoogleAnalytics = ({ history, location }) => {
  useEffect(() => {
    if (history.action === 'PUSH') {
      analytics.pushPageChangeEvent();
    }
  }, [history.action, location]);

  return null;
};

export default withRouter(GoogleAnalytics);
