import { createReducer } from '@reduxjs/toolkit';
import {
  clearMarketplacePanelReducer,
  initMarketplaceButtons,
  setInitMarketplacesButtons,
  setMarketplacePanelVisibility,
  setResultsDefaultValue,
  updateMarketplaceButtonData,
} from '../actions/marketplace.actions';

const initialState = {
  isDemonstratingPanel: false,
  buttons: [],
};

const marketplacePanelReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(initMarketplaceButtons, (state, action) => ({
      ...state,
      buttons: action.payload
        .map((item) => ({
          marketplace: item.id,
          imageUrl: item.imageUrl,
          results: 0,
          isSearchInProgress: true,
        }))
        .sort(() => Math.random() - 0.5),
    }))
    .addCase(setInitMarketplacesButtons, (state) => ({
      ...state,
      buttons: state.buttons.map((button) => ({
        ...button,
        results: 0,
        isSearchInProgress: true,
      })),
    }))
    .addCase(updateMarketplaceButtonData, (state, action) => ({
      ...state,
      buttons: state.buttons.map((button) => {
        if (button.marketplace === action.payload.store) {
          const newPortionResults = action.payload.data !== 'none' ? action.payload.data.length : 0;
          const currentResults = button.results;

          return {
            ...button,
            results: currentResults + newPortionResults,
            isSearchInProgress: false,
          };
        }
        return button;
      }),
    }))
    .addCase(setMarketplacePanelVisibility, (state, action) => ({
      ...state,
      isDemonstratingPanel: action.payload,
    }))
    .addCase(setResultsDefaultValue, (state) => ({
      ...state,
      buttons: state.buttons.map((button) => {
        if (button.isSearchInProgress) {
          return {
            ...button,
            results: 0,
            isSearchInProgress: false,
          };
        }
        return button;
      }),
    }))
    .addCase(clearMarketplacePanelReducer, () => ({
      ...initialState,
    }));
});

export default marketplacePanelReducer;
