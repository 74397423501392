import { createReducer, original } from '@reduxjs/toolkit';
import orderValues from 'constants/orderValues';
import helpers from 'helpers';
import {
  addShortcutTag,
  applySearchFilters,
  clearPriceFilter,
  clearSearchReducer,
  clearSearchResults,
  filterSearchAgents,
  filterSearchLocations,
  filterSearchResults,
  putAllLocationsIntoFilteredLocations,
  removeSearchLocationItem,
  removeShortcutTag,
  setAllAgentsCheckedStatus,
  setSearchAgentsItem,
  setSearchAgentsItemFromMarketplacePanel,
  setSearchFilter,
  setSearchFilteredByMarketplaceResults,
  setSearchLocations,
  setSearchLocationsItem,
  setSearchPriceFilter,
  setSearchResults,
  setSearchShowHistory,
  setSearchValue,
  setShowSecondNudgeMessage,
  updateSearchSettings,
  setTfIdfMatrix
} from 'redux/actions/search.actions';

const initialState = {
  searchTerm: '',
  searchResults: [],
  agents: [],
  locations: [],
  toggleFilter: false,
  canShowHistory: false,
  priceFilter: {
    min: Number.MIN_SAFE_INTEGER,
    max: Number.MAX_SAFE_INTEGER,
  },
  filterEnabled: false,
  filteredAgents: [],
  filteredLocations: [],
  filteredSearchResults: [],
  searchFilter: orderValues.bestMatch,
  tags: [],
  appliedTags: [],
  appliedIds: [],
  priceRange: {
    min: 0,
    max: Number.MAX_SAFE_INTEGER,
  },
  showSecondNudgeMessage: false,
  tfidfMatrix: {}
};

const getFilteredSearchResults = (state) =>
  helpers.sortProducts(
    state.searchResults.filter(
      (item) =>
        state.filteredLocations.includes(helpers.getItemCenter(item)) &&
        (!state.filteredAgents.length || state.filteredAgents.includes(item.agent)) &&
        item.price >= state.priceFilter.min &&
        item.price <= state.priceFilter.max &&
        helpers.isTitleApplicableByTags(item.id, state.appliedIds)
    ),
    state.searchFilter,
    state.searchTerm,
    state.tfidfMatrix,
  );

const searchReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setTfIdfMatrix, (state, action) => ({
      ...state,
      tfidfMatrix: action.payload,
    }))
    .addCase(setSearchValue, (state, action) => ({
      ...state,
      searchTerm: action.payload,
    }))
    .addCase(setSearchResults, (state, action) => ({
      ...state,
      searchResults: [...state.searchResults, ...action.payload],
    }))
    .addCase(clearSearchResults, (state) => ({
      ...state,
      searchResults: [],
      filteredSearchResults: [],
      agents: [],
      filteredAgents: [],
      tags: [],
      appliedTags: [],
    }))
    .addCase(setSearchShowHistory, (state, action) => ({
      ...state,
      canShowHistory: action.payload,
    }))
    .addCase(filterSearchAgents, (state) => ({
      ...state,
      filteredAgents: state.agents.filter(({ checked }) => checked).map((agent) => agent.name),
    }))
    .addCase(filterSearchLocations, (state) => ({
      ...state,
      filteredLocations: state.locations.filter((location) => location.checked).map((location) => location.postcode),
    }))
    .addCase(filterSearchResults, (state) => ({
      ...state,
      filteredSearchResults: getFilteredSearchResults(state),
    }))
    .addCase(setSearchFilter, (state, action) => ({
      ...state,
      searchFilter: action.payload,
      filteredSearchResults:
        helpers.sortProducts([...state.filteredSearchResults], action.payload, state.searchTerm, state.tfidfMatrix),
    }))
    .addCase(setSearchPriceFilter, (state, action) => ({
      ...state,
      priceFilter: action.payload,
    }))
    .addCase(updateSearchSettings, (state, action) => {
      const newSearchResult = original(state.searchResults).concat(action.payload.searchResults);
      const newAgents = [...new Set(newSearchResult.map((item) => item.agent))].map((name) => ({
        name,
        checked: state.agents.find((agent) => agent.name === name)?.checked ?? true,
      }));
      const newPriceFilter = {
        min: 0,
        max: Math.max(...newSearchResult.map((result) => parseFloat(result.price))),
      };
      const newState = {
        ...state,
        searchResults: newSearchResult,
        agents: newAgents,
        filteredAgents: state.filteredAgents.length
          ? newAgents.filter(({ checked }) => checked).map(({ name }) => name)
          : state.filteredAgents,
        tags: helpers
          .getUniqueArrObjects([...state.tags, ...action.payload.tags])
          .sort((left, right) => right.frequency - left.frequency),
        priceFilter: {
          ...newPriceFilter,
        },
        priceRange: {
          ...newPriceFilter,
        },
      };
      const filteredSearchResults = getFilteredSearchResults(newState);

      return {
        ...newState,
        filteredSearchResults,
      };
    })
    .addCase(setSearchLocations, (state, action) => ({
      ...state,
      locations: action.payload,
      filteredLocations: action.payload.map((location) => location.postcode),
    }))
    .addCase(setSearchAgentsItem, (state, action) => ({
      ...state,
      agents: state.agents.map((agent) => {
        if (agent.name === action.payload) {
          return { ...agent, checked: !agent.checked };
        }
        return agent;
      }),
    }))
    .addCase(setSearchAgentsItemFromMarketplacePanel, (state, action) => ({
      ...state,
      agents: state.agents.map((agentForFilter) => ({
        ...agentForFilter,
        checked: agentForFilter.name === action.payload.marketplaceName,
      })),
    }))
    .addCase(setSearchLocationsItem, (state, action) => ({
      ...state,
      locations: state.locations.map((location) => {
        if (location.postcode === action.payload) {
          return { ...location, checked: !location.checked };
        }
        return location;
      }),
    }))
    .addCase(removeSearchLocationItem, (state, action) => {
      const isAnyLocationChecked = state.locations.every((item) => !item.checked);

      return {
        ...state,
        locations: isAnyLocationChecked
          ? state.locations.map((location) => ({
            ...location,
            checked: location.postcode !== action.payload,
          }))
          : state.locations.map((location) => {
            if (location.postcode === action.payload) {
              return { ...location, checked: false };
            }
            return location;
          }),
      };
    })
    .addCase(setSearchFilteredByMarketplaceResults, (state, action) => {
      if (action.payload.checked === false) {
        return {
          ...state,
          filteredSearchResults: [
            ...state.filteredSearchResults,
            ...state.searchResults.filter((item) => item.agent === action.payload.name),
          ],
        };
      }
      return {
        ...state,
        filteredSearchResults: state.filteredSearchResults.filter((item) => item.agent !== action.payload.name),
      };
    })
    .addCase(clearSearchReducer, () => ({
      ...initialState,
    }))
    .addCase(addShortcutTag, (state, action) => {
      const tag = state.tags.find((item) => item.tag === action.payload);
      return {
        ...state,
        appliedTags: action.payload,
        appliedIds: tag.ids,
      };
    })
    .addCase(removeShortcutTag, (state) => {
      return {
        ...state,
        appliedTags: [],
        appliedIds: [],
      };
    })
    .addCase(clearPriceFilter, (state) => ({
      ...state,
      priceFilter: {
        ...state.priceRange,
      },
    }))
    .addCase(setAllAgentsCheckedStatus, (state, action) => ({
      ...state,
      filteredAgents: [],
      agents: state.agents.map((agent) => ({ ...agent, checked: action.payload })),
    }))
    .addCase(putAllLocationsIntoFilteredLocations, (state, action) => ({
      ...state,
      filteredLocations: action.payload ? state.locations.map((location) => location.postcode) : [],
      locations: state.locations.map((location) => ({ ...location, checked: action.payload })),
    }))
    .addCase(applySearchFilters, (state, action) => ({
      ...state,
      ...action.payload,
    }))
    .addCase(setShowSecondNudgeMessage, (state, action) => ({
      ...state,
      showSecondNudgeMessage: action.payload,
    }));
});

export default searchReducer;
