import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import StyledButton from './StyledButton/StyledButton';

import 'styles/menu.css';
import {useEffect, useState} from 'react';

const Authentification = () => {
  const [username, setUsername] = useState(null);
  const { socket } = useSelector((state) => state);
  const { isInitialized } = useSelector((state) => state.app);

  useEffect(() => {
    setUsername(socket?.config?.user.username);
  }, [socket]);

  if (!isInitialized) {
    return null;
  }

  if (username && username !== 'anon') {
    return null;
  }

  return (
    <Link to="/login">
      <StyledButton label="Login" className="login-button" useOnlyPropClass />
    </Link>
  );
};

export default Authentification;
