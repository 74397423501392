import htmlCurrencySymbols from 'constants/htmlCurrencySymbols';
import fallbackImage from 'assets/img/item-card-fallback-image.png';
import config from 'helpers/environment';
import { getDistance } from 'helpers/distance';
import './ItemCardMobile.css';
import {removeDecimal} from '../../helpers/formatNumber';
import {useDispatch, useSelector} from 'react-redux';
import {favoritesApi} from '../../services/restapi.service';
import {addFavoriteItem, deleteFavoriteItem} from '../../redux/actions/user.actions';
import {showToast} from '../../redux/actions/app.actions';
import notificationEvents from '../../constants/notificationEvents';
import {renderToastText} from '../../helpers/renderToastText';
import systemMessages from '../../constants/systemMessages';

const ItemCardMobile = ({ itemInfo, marketPlaceInfo, onClick, style, className, showBlock = true }) => {
  const dispatch = useDispatch();
  const distance = getDistance(itemInfo);
  const favoriteItems = useSelector((state) => state.user.favoriteItems);
  const favoritedItem = favoriteItems.length && favoriteItems.find((item) => item.itemInfo.link === itemInfo.link);
  const token = useSelector((state) => state.user.user.idToken?.jwtToken);
  const { username: userId } = useSelector((state) => state.socket.config?.user);
  const returnUrl = window.location.pathname + window.location.search

  const price = itemInfo.price ? `${htmlCurrencySymbols[itemInfo.currency]}${itemInfo.price}` : 'Free'

  const onHeartClick = async (e) => {
    e.stopPropagation();
    try {
      if (!favoritedItem) {
        const { data } = await favoritesApi.setFavoriteItem({ userId, itemInfo, marketPlaceInfo }, token);
        dispatch(addFavoriteItem({ itemInfo, marketPlaceInfo, id: data.id }));
      } else {
        await favoritesApi.deleteFavoriteItem(favoritedItem.id, token);
        dispatch(deleteFavoriteItem(favoritedItem.id));
      }
    } catch (err) {
      if (err.response.status === 401) {

        const link = `/login?returnUrl=${returnUrl}`

        dispatch(showToast({
          text: renderToastText(systemMessages.youMustBeLoggedIn, link),
          type: notificationEvents.error
        }));
      } else {
        dispatch(showToast({ text: err.message, type: notificationEvents.error }));
      }
    }
  };

  return (
    <div className={`item-card item-card-mobile ${className}`} onClick={onClick} style={style}>
      <img
        src={`${itemInfo.image ? itemInfo.image.replace(/^http:/, 'https:') : fallbackImage}`}
        alt="item"
        onError={({ currentTarget }) => {
          currentTarget.onerror = null;
          currentTarget.src= fallbackImage;
        }}
        className="item-card-image"
      />
      <div>
        <div className="item-card-title">{itemInfo.title}</div>
        {distance && (
          <span className="item-card-details__location">
            {itemInfo.center} {distance}
            miles
          </span>
        )}
        {!distance && showBlock && (
          <div className="item-card-details__delivery-block">
            <span className="item-card-details__block" />
          </div>
        )}
        <div className="item-card-data">
          <div className="item-card-price">{removeDecimal(price)}</div>
          <div className="item-card-details__agent-price">
            <img src={`${config.logoUrl}${marketPlaceInfo.imageUrl}`} alt={marketPlaceInfo.id} />
          </div>
          <div>
            <i
              onClick={onHeartClick}
              className={`pi ${favoritedItem ? 'pi-heart-fill' : 'pi-heart'}`}
              style={{ fontSize: '2rem' }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ItemCardMobile;
