import eventListenerTypes from 'constants/eventListenerTypes';
import keyboardKeys from 'constants/keyboardKeys';
import helpers from 'helpers';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setSearchShowHistory } from 'redux/actions/search.actions';

export const useSearchDropdownKeyboard = (searchInputId, historyRef) => {
  const [currentHistoryFocused, setCurrentHistoryFocused] = useState(-1);
  const dispatch = useDispatch();

  const onOutsideClick = (e) => {
    if (e.target.id !== searchInputId || e.key === 'Escape') {
      dispatch(setSearchShowHistory(false));
    }
  };

  const onEscapeKeyDown = (e) => {
    if (e.key === 'Escape') {
      dispatch(setSearchShowHistory(false));
    }
  };

  const onArrowKeyNavigation = (e) => {
    if (e.key === keyboardKeys.arrowUp || e.key === keyboardKeys.arrowDown) {
      e.preventDefault();
    }

    helpers.handleKeyDownSearchHistoryEvent(
      e,
      historyRef,
      currentHistoryFocused,
      setCurrentHistoryFocused,
      searchInputId,
      dispatch
    );
  };

  useEffect(() => {
    window.addEventListener(eventListenerTypes.keydown, onArrowKeyNavigation);
    window.addEventListener(eventListenerTypes.click, onOutsideClick);
    window.addEventListener(eventListenerTypes.keydown, onEscapeKeyDown);

    return () => {
      window.removeEventListener(eventListenerTypes.keydown, onArrowKeyNavigation);
      window.removeEventListener(eventListenerTypes.click, onOutsideClick);
      window.removeEventListener(eventListenerTypes.keydown, onEscapeKeyDown);
    };
  }, [currentHistoryFocused]);
};
