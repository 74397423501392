import React from 'react';
import { InputText } from 'primereact/inputtext';

import '../styles/customInput.css';

const Input = ({
  config: {
    label,
    fieldName,
    validators,
    type = 'text',
  },
  register,
  error,
}) => (
  <div className={`form-input-wrapper ${error ? 'invalid' : ''}`}>
    <label>
      { label }
      <InputText type={type} {...register(fieldName, validators)} />
    </label>
    {
      error
        ? <div className="form-input-error">{error.message}</div>
        : null
    }
  </div>
);

export default Input;
