import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import FormContainer from 'components/FormContainer';
import Form from 'components/Form';
import routes from 'constants/routes';
import { emailFieldConfiguration } from 'constants/defaultFieldsConfigurations';
import { updateEmail } from 'services/auth.service';
import StyledButton from 'components/StyledButton/StyledButton';

import 'styles/customInput.css';
import FormParagraph from './FormParagraph/FormParagraph';

const fieldsConfig = [
  emailFieldConfiguration,
];

const ChangeEmail = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { user } = useSelector((state) => state);

  const handleChangeEmail = async ({ email }) => {
    await updateEmail(email, dispatch).then((isFinishedSuccessfully) => {
      if (isFinishedSuccessfully) {
        history.push(routes.confirmEmail);
      }
    });
  };

  const cancelClick = () => {
    if (!user) {
      history.push(routes.login);
    }
    history.push(routes.settings);
  };

  return (
    <FormContainer formTitle="Change your Email">
      <FormParagraph>
        Please enter your new email address
      </FormParagraph>
      <Form
        fieldsConfig={fieldsConfig}
        onSubmit={handleChangeEmail}
        buttonLabel="Use this email address"
      />
      <StyledButton
        label="Cancel"
        onClick={cancelClick}
      />
    </FormContainer>
  );
};

export default ChangeEmail;
