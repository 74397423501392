import { useDispatch, useSelector } from 'react-redux';
import { setMarketplacePanelVisibility } from 'redux/actions/marketplace.actions';
import {
  setSearchAgentsItemFromMarketplacePanel,
  filterSearchAgents,
  filterSearchResults,
} from 'redux/actions/search.actions';
import config from 'helpers/environment';
import 'styles/marketplacePanel.css';
import StyledButton from 'components/StyledButton/StyledButton';

const ResultsCircle = ({ number }) => <div className="marketplace-results">{number}</div>;

const MarketplaceButton = ({ buttonData }) => {
  const dispatch = useDispatch();
  const { marketplace, imageUrl } = buttonData;
  const isSearchCompleted = useSelector((state) => state.app.isSearchCompleted);
  const handleMarketplaceButton = () => {
    dispatch(setSearchAgentsItemFromMarketplacePanel({ marketplaceName: marketplace, imageUrl }));
    dispatch(filterSearchAgents());
    dispatch(filterSearchResults());
    dispatch(setMarketplacePanelVisibility(false));
  };

  return (
    <StyledButton
      className="marketplace-button"
      onClick={!isSearchCompleted ? () => {} : handleMarketplaceButton}
      useOnlyPropClass
    >
      <img key={marketplace} src={`${config.logoUrl}${imageUrl}`} alt={marketplace} />
      {buttonData?.results ? (
        <ResultsCircle number={buttonData.results} />
      ) : (
        <i className="pi pi-spin pi-spinner pink-color-secondary" />
      )}
    </StyledButton>
  );
};

export default MarketplaceButton;
