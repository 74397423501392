export const searchTermFixes = [
  {
    fix:'secondhand',
    tokens:['used','preloved','preowned','secondhand','for sale'],
    effect:['remove'],
    toastMessage: 'Everything we show is secondhand, so we`ve removed token from your request'
  },
  {
    fix:'cheapest',
    tokens:['cheap','inexpensive', 'cheapest'],
    effect:['remove','sortLowToHigh'],
    toastMessage: 'We`re showing you the cheapest stuff first based on your search request'
  },
  {
    fix:'expensive',
    tokens:['expensive','pricey'],
    effect:['remove','sortHighToLow'],
    toastMessage: 'We`re showing you the pricey stuff first based on your search request'
  },
  {
    fix:'uk',
    tokens:['uk','united kingdom'],
    effect:['remove'],
    toastMessage: 'All our products are from the UK so we`ve removed that from your search request'
  },
  {
    fix:'free',
    tokens:['free'],
    effect:['remove','sortLowToHigh'],
    toastMessage: 'We`re showing you free stuff first based on your search request'
  },
]
