import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import useIsMount from 'hooks/useIsMount';
import withInitialization from 'higherOrderComponents/withInitialization';
import routes from 'constants/routes';
import tiersTypes from 'constants/tiersTypes';
import { deleteUserLocation } from 'redux/actions/socket.actions';
import { clearSearchReducer } from 'redux/actions/search.actions';
import {configAPI} from 'services/restapi.service';
import useCurrentTierConfig from 'hooks/useCurrentTierConfig';
import CommonTab from 'components/CommonTab';
import LocationCard from 'components/LocationCard';
import StyledButton from 'components/StyledButton/StyledButton';

import 'styles/conditionWrapper.css';
import {isAnon} from '../services/user.service';
import {getAnonLocation} from '../services/locations';

const LocationContainer = () => {
  const { socket } = useSelector((state) => state);
  const { maxLocations, tierName, id: tier } = useCurrentTierConfig();
  const [addingLocation, setAddingLocation] = useState(false);
  const isMount = useIsMount();
  const history = useHistory();
  const dispatch = useDispatch();
  const { locations, frequency, notify, searchprefs } = useSelector((state) => state.socket.config.user);
  const jwtToken = useSelector((state) => state.user?.user?.idToken?.jwtToken) || null;

  const userLocations =
    socket.config.user.locations.slice(0, maxLocations).filter((location) => location && location.term) || [];
  const anonLocation = getAnonLocation(socket);

  if (anonLocation) {
    userLocations.splice(0, 1)
    userLocations.push(anonLocation)
  }

  useEffect(() => {
    if (jwtToken && isMount) {
      const locationsWithPostcode = locations.filter((location) => location.postcode !== false);

      configAPI.updateConfig(jwtToken, {
        locations: locationsWithPostcode,
        frequency,
        notify,
        searchprefs,
      });
    }

  }, [jwtToken, locations, frequency, notify, searchprefs, isMount]);

  const deleteLocation = (slot) => {
    if (isAnon(socket)) {
      sessionStorage.removeItem('anon_location')
    }

    dispatch(deleteUserLocation(slot));
    dispatch(clearSearchReducer());
    setAddingLocation(false);
  };

  const locationCards = userLocations.map((userLocation, i) => (
    <LocationCard
      deleteLocation={deleteLocation}
      userLocation={userLocation}
      slot={i + 1}
      key={`${userLocation.latitude}${userLocation.longitude}`}
      addingLocation={addingLocation}
      setAddingLocation={setAddingLocation}
      userLocations={userLocations}
    />
  ));

  if (addingLocation && locationCards.length < maxLocations) {
    locationCards.push(
      <LocationCard
        deleteLocation={deleteLocation}
        slot={userLocations.length + 1}
        key={userLocations.length + 1}
        addingLocation={addingLocation}
        setAddingLocation={setAddingLocation}
        userLocations={userLocations}
      />
    );
  }

  const addLocationCardBlock = () =>
    !addingLocation && locationCards.length < maxLocations ? (
      <div onClick={() => setAddingLocation(true)} className="add-location-button">
        <strong>Tap here to add a location for all your searches</strong>
        .
        <br />
        <br />
        <span className="small">
          Most marketplaces require a full postcode, so please enter a street address.
          <br />
          Click <i className="pi pi-map-marker" /> on the card to use your device&apos;s current location.
        </span>
      </div>
    ) : null;

  const addBlankBlock = () => {
    const count = maxLocations - locationCards.length - (addingLocation ? 0 : 1);
    const arr = [];
    for (let i = 1; i <= count; i++) {
      arr.push(<div key={i} onClick={() => setAddingLocation(true)} className="add-location-button" />);
    }
    return arr;
  };

  return (
    <div>
      <CommonTab title="Search Locations" />
      <div>
        <div className="locations-wrapper">
          <div className="locations-container">
            <div className="location-container-title">
              {tier === tiersTypes.anon ? (
                <span>
                  Search locally in <b>one</b> location.<br/> <a href='/signup'><u><b>Sign up</b></u></a> to search
                  across multiple locations at once.
                </span>
              ) : (
                <span>
                  As part of your <b>{tierName.toLowerCase()}</b> subscription, you can search across{' '}
                  <b>{maxLocations}</b> locations simultaneously.
                </span>
              )}
            </div>
            <br />
            <br />
            {locationCards}
            {addLocationCardBlock()}
            {addBlankBlock()}
            <StyledButton
              className="search-button"
              // eslint-disable-next-line
              label={!userLocations.length ?  "Where should we search?" : 'Let’s Rumage!'}
              disabled={!userLocations.length}
              onClick={() => history.push(routes.home)}
              useOnlyPropClass
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default withInitialization(LocationContainer);
