import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Toast } from 'primereact/toast';
import { clearToast } from '../redux/actions/app.actions';

import '../styles/toast.css';

const Notification = () => {
  const dispatch = useDispatch();
  const toast = useRef(null);
  const { toastType, toastMessage } = useSelector((state) => state.app);
  const toastLifeTime = 3000;

  useEffect(() => {
    const toastConfiguration = {
      severity: toastType,
      summary: '',
      detail: toastMessage,
      life: toastLifeTime,
    };
    const delayedDestroyToast = () => setTimeout(() => dispatch(clearToast()), toastLifeTime);
    const showToast = () => {
      toast.current.show(toastConfiguration);
      delayedDestroyToast();
    };

    if (toastMessage) {
      showToast();
    }
  }, [toastMessage, toastType, dispatch]);

  return <Toast
    ref={toast}
    baseZIndex="100000"
    position='top-center'
    className="toast-center"
    style={{opacity:'1', marginTop: window.location.pathname.includes('login') && '85px'}} />;
};

export default Notification;
