import * as axios from 'axios';
import routesREST from '../constants/routesREST';
import urlQueries from '../constants/urlQueries';
import config from '../helpers/environment';

const { searchId, search, equal } = urlQueries;
const buildOptions = (token) => ({
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  },
});

const axiosInstance = axios.create({
  baseURL: config.restApiUrl,
  withCredentials: false,
});

export const restAPI = {
  getSplash() {
    return axiosInstance.get('splash?tier=freemium').then((res) => res.data);
  },
};

export const configAPI = {
  getDefaultConfig() {
    return axiosInstance.get(routesREST.defaultConfig);
  },

  getConfig(token) {
    return axiosInstance.get(routesREST.config, buildOptions(token));
  },

  updateConfig(token, userConfig) {
    return axiosInstance.put(routesREST.config, JSON.stringify(userConfig), buildOptions(token));
  },

  getMappingFile() {
    return axios.get(routesREST.getMappingFile);
  },

  getSearchTermsWeight() {
    return axiosInstance.get(routesREST.getSearchTermsWeights).catch(() => ({ data: [] }));
  }
};

export const locationAPI = {
  getLocation(lat, lon, address) {
    if (!lat && !lon) {
      return axiosInstance.get(`${routesREST.location}?address=${address}`);
    }
    return axiosInstance.get(`${routesREST.location}?lat=${lat}&lon=${lon}`);
  },
};

export const alertAPI = {
  getAlerts(token) {
    return axiosInstance.get(routesREST.alerts, buildOptions(token));
  },

  sendAlert(token, alert) {
    return axiosInstance.post(routesREST.alert, JSON.stringify(alert), buildOptions(token));
  },

  updateAlert(token, alert) {
    return axiosInstance.put(routesREST.alert, JSON.stringify(alert), buildOptions(token));
  },

  deleteAlert(token, alert) {
    return axiosInstance.delete(routesREST.alert, {
      data: JSON.stringify(alert),
      headers: buildOptions(token).headers,
    });
  },
};

export const recaptchaApi = {
  verifyGoogleToken(token, googleToken) {
    return axiosInstance
      .post(routesREST.checkoutRecaptcha, { googleToken }, buildOptions(token))
      .then((response) => response.data);
  },
};

export const historyApi = {
  getHistory(token) {
    return axiosInstance.get(routesREST.history, buildOptions(token));
  },
};

export const subscriptionManageApi = {
  getRedirectUrl(token, hasSubscription, chargebeeId) {
    return hasSubscription ? this.getSubscriptionManageUrl(token) : this.getCheckoutUrl(token, chargebeeId);
  },

  getSubscriptionManageUrl(token) {
    return axiosInstance
      .get(routesREST.subscriptionManagePortal, buildOptions(token))
      .then(({ data: { portalAccessUrl } }) => portalAccessUrl);
  },

  getCheckoutUrl(token, chargebeeId) {
    return axiosInstance
      .get(`${routesREST.checkout}?plan=${chargebeeId}`, buildOptions(token))
      .then(({ data: { checkoutUrl } }) => checkoutUrl);
  },
};

export const searchResultApi = {
  createSearchResultSet(token, searchSet) {
    return axiosInstance.post(routesREST.searchResult, JSON.stringify(searchSet), buildOptions(token));
  },

  getSearchResultSet(token, id) {
    return axiosInstance.get(`${routesREST.searchResult}${search}${searchId}${equal}${id}`, buildOptions(token));
  },
};

export const quickviewApi = {
  getAdditionalImages(data) {
    return axiosInstance.post(routesREST.getQuickviewPhotos, data);
  },

  getAdditionalItemData(data) {
    return axiosInstance.post(routesREST.getAdditionalItemData, data);
  },
};

export const favoritesApi = {
  getFavoriteItems(userId, token) {
    return axiosInstance.get(`${routesREST.getFavoriteItems}?userId=${userId}`, buildOptions(token));
  },

  setFavoriteItem(data, token) {
    return axiosInstance.post(routesREST.setFavoriteItem, data, buildOptions(token));
  },

  deleteFavoriteItem(id, token) {
    return axiosInstance.delete(routesREST.deleteFavoriteItem, {
      data: { id },
      ...buildOptions(token),
    });
  },
  deleteAllFavoriteItems(userId, token) {
    return axiosInstance.delete(routesREST.deleteAllFavoriteItems, {
      data : { userId },
      ...buildOptions(token),
    });
  },
};

export const searchSuggestions = {
  get(searchTerm) {
    return axiosInstance.get(`${routesREST.getSearchSuggestions}?searchTerm=${searchTerm}`);
  },
};
