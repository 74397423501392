import SearchDropdown from 'components/SearchDropdown/SearchDropdown';
import analyticsEvents from 'constants/analyticsEvents';
import routes from 'constants/routes';
import base64Mapper from 'helpers/base64Mapper';
import config from 'helpers/environment';
import useLocations from 'hooks/useLocations';
import useUrlQuery from 'hooks/useUrlQuery';
import { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import {setSearchCompleted, showToast, showWaitResults} from 'redux/actions/app.actions';
import {
  setInitMarketplacesButtons,
  setMarketplacePanelVisibility,
  setResultsDefaultValue,
} from 'redux/actions/marketplace.actions';
import {
  applySearchFilters,
  clearSearchResults,
  filterSearchResults, setSearchFilter,
  setSearchLocations,
  setSearchShowHistory,
  setSearchValue,
  updateSearchSettings,
} from 'redux/actions/search.actions';
import { selectJwtToken } from 'redux/store/selectors';
import analytics from 'services/analytics.service';
import storage from 'services/localStorage.service';
import { recaptchaApi } from 'services/restapi.service';
import { getSearchResultSet, searchResultsByString } from 'services/search.service';
import { getUserLocationsPostcode } from 'services/user.service';

import '../../styles/searchComponent.css';

import HomePageSearchComponent from './HomePageSearchComponent';
import SearchPageSearchComponent from './SearchPageSearchComponent';
import {searchTermFixes} from '../../constants/searchTermFixes';
import notificationEvents from '../../constants/notificationEvents';
import orderValues from '../../constants/orderValues';

const searchFieldSymbolsMaxLimit = 60;
const searchFieldSymbolsMinLimit = 2;
const searchStringPattern = /[^а-яА-ЯёЁa-zA-Z0-9]/g;
const IS_SEARCH_ROBOT = 0.5;
const SEARCH_INPUT_ID = 'searchInput';

const SearchComponent = ({ isSearchPage, hasUserScrolled }) => {
  const { socket, search } = useSelector((state) => state);
  const isInitialized = useSelector((state) => state.app.isInitialized);
  const canShowHistory = useSelector((state) => state.search.canShowHistory);
  const timerRef = useRef(0);
  const jwtToken = useSelector(selectJwtToken) || null;
  const dispatch = useDispatch();
  const history = useHistory();
  const locations = useLocations();
  const location = useLocation();

  const { searchTerm } = search;
  const { searchResultSetId, searchInfo, term, rawQuery } = useUrlQuery()
  const isTermInvalid = (term) => term.replace(searchStringPattern, '').length < searchFieldSymbolsMinLimit;
  const trimTerm = (term) => term.trim().replace(/\s+/g, ' ');

  const userTier = socket.config?.user.tier;
  const username = socket.config?.user.username;

  const prepareSearchReducer = useCallback(() => {
    dispatch(setInitMarketplacesButtons());
    dispatch(setMarketplacePanelVisibility(true));
    dispatch(showWaitResults(true));
    setTimeout(() => {
      dispatch(setResultsDefaultValue());
      dispatch(setSearchCompleted(true));
    }, config.marketplaceSearchDelay);
    dispatch(setSearchShowHistory(false));
    dispatch(setSearchLocations(locations));
    dispatch(clearSearchResults());
    dispatch(setSearchCompleted(false));
  }, [dispatch, locations]);

  // We use this timer so the search results page doesn't fully rerender when the websocket closes.
  // The timer reset each time a new search is made
  useEffect(() => {
    timerRef.current = 0;

    const interval = setInterval(() => {
      timerRef.current++;
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [location.search]);

  useEffect(() => {
    if (timerRef.current > 60) {
      return;
    }

    const searchResultsFromQuery = async () => {
      const { linkType, term } = base64Mapper.mapFromString(decodeURIComponent(searchInfo));

      dispatch(setSearchValue(term));
      prepareSearchReducer();

      const { data, tags, filters } = await getSearchResultSet(jwtToken, searchResultSetId);

      dispatch(updateSearchSettings({ searchResults: data, tags }));
      dispatch(applySearchFilters(filters));
      dispatch(filterSearchResults());
      dispatch(setSearchCompleted(true));

      analytics.pushEvent(analyticsEvents.viewList, {
        rumage_user_id: username || null,
        search_term: term,
        channel: linkType,
        item_count: data.length,
        tier: userTier,
      });
    };

    if (searchResultSetId && socket.config) {
      searchResultsFromQuery();
    }
  }, [socket.config]);

  const searchResults = (value = searchTerm) => {
    const trimmedValue = trimTerm(value);
    dispatch(setSearchValue(trimmedValue));

    if (isTermInvalid(trimmedValue)) {
      return;
    }

    prepareSearchReducer();

    const allFixableTokens = searchTermFixes.map(item => {
      return item.tokens
    }).flat(1)

    const splitSearchTerm = value.split(' ')

    const token = allFixableTokens.find(token => splitSearchTerm.includes(token))
      ||
      value.includes('for sale') && 'for sale';
    const fixedTerm =  searchTermFixes.find((item)=> {
      return  item.tokens.includes(token)
    })

    if (fixedTerm) {
      dispatch(showToast({
        text: fixedTerm.toastMessage.replace('token',`'${token}'`),
        type: notificationEvents.success }));
      history.push(`/search?term=${trimmedValue.replace(`${token}`, '').replace(/ /g, '+')}`);

      if(fixedTerm.effect.includes('remove')){
        dispatch(setSearchValue(trimmedValue.replace(`${token}`, '')))
      }

      if(fixedTerm.effect.includes('sortLowToHigh')) {
        dispatch(setSearchFilter(orderValues.priceFromLowToHigh))
      }

      if(fixedTerm.effect.includes('sortHighToLow')){
        dispatch(setSearchFilter(orderValues.priceFromHighToLow))
      }

    }
    !fixedTerm && history.push(`/search${rawQuery}`);

    analytics.pushEvent(analyticsEvents.search, {
      rumage_user_id: username || null,
      search_term: value,
      tier: socket.config?.user.tier,
      location_count: socket.config.user?.locations.length,
    });
    const searchLocations = getUserLocationsPostcode(socket);
    searchResultsByString(trimmedValue, searchLocations, username);
  };

  const verifyGoogleToken = (urlTerm) => {
    window.grecaptcha.ready(() => {
      window.grecaptcha.execute(config.googleSecretKey).then(async (googleToken) => {
        // Send form value as well as token to the server
        const result = await recaptchaApi.verifyGoogleToken(jwtToken, googleToken);

        if (result.score < IS_SEARCH_ROBOT) {
          history.push(routes.home);
        } else {
          searchResults(urlTerm);
        }
      });
    });
  };

  useEffect(() => {
    if (timerRef.current > 60) {
      return;
    }

    if (!term || term?.length >= searchFieldSymbolsMaxLimit) {
      return;
    }

    const urlTerm = trimTerm(term.replace(/\+/g, ' '));
    if (urlTerm === searchTerm) {
      dispatch(setSearchLocations(locations));
      return;
    }

    if (isInitialized && socket.isSocketInitialized) {
      const urlTerm = trimTerm(term.replace(/\+/g, ' '));

      dispatch(setSearchValue(decodeURIComponent(urlTerm)));
      verifyGoogleToken(decodeURIComponent(urlTerm));

      if (history?.location.state) {
        return;
      }

      const recentSearches = storage.getRecentSearches();
      const currentSearch = { term: urlTerm, link: `/?term=${term}` };

      if (recentSearches) {
        for (const recentSearch of recentSearches) {
          if (recentSearch.term === currentSearch.term) {
            return;
          }
        }

        recentSearches.unshift(currentSearch);

        if (recentSearches.length > 5) {
          recentSearches.pop();
          // Added this to affect users coming from version with 7 or 8 recent searches.
          if (recentSearches.length === 7) {
            recentSearches.splice(4, 2);
          } else if (recentSearches.length === 8) {
            recentSearches.pop();
          }
        }
      }

      storage.setRecentSearches(JSON.stringify(recentSearches || [currentSearch]));
    }
  }, [isInitialized, socket.isSocketInitialized, location.search]);

  const onValueChange = (e) => {
    if (e.target.value.length <= searchFieldSymbolsMaxLimit) {
      dispatch(setSearchValue(e.target.value));
    }
  };

  const checkKeyInput = (e) => {
    if (e.key === 'Enter') {
      history.push(`/search?term=${searchTerm.replace(/ /g, '+')}`);
      window.location.reload();
    }
  };

  return (
    <>
      {isSearchPage ? (
        <SearchPageSearchComponent
          search={search}
          searchTerm={searchTerm}
          onValueChange={onValueChange}
          checkKeyInput={checkKeyInput}
        >
          {canShowHistory && !hasUserScrolled &&
            <SearchDropdown searchInputId={SEARCH_INPUT_ID} />}
        </SearchPageSearchComponent>
      ) : (
        <HomePageSearchComponent
          search={search}
          searchTerm={searchTerm}
          onValueChange={onValueChange}
          checkKeyInput={checkKeyInput}
        >
          {canShowHistory && !hasUserScrolled &&
            <SearchDropdown searchInputId={SEARCH_INPUT_ID} />}
        </HomePageSearchComponent>
      )}
    </>
  );
};

export default SearchComponent;
