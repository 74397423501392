const nudgeBarMessages = {
  1 : 'Register to get fresh results emailed daily',
  2: 'Register to search in your local area',
  3: 'Register to save your faves',
  4: 'Register to share finds with your friends',
  5: 'Search Second Hand Near You with a FREE Account',
  6: 'Save more time and money with a FREE account',
  7: 'Sign up for FREE',
  8: 'Get new features first with a FREE account',
  9: 'Join 1,000\'s of other Rumagers with a FREE account',
  10: 'Look locally with a FREE account',
  11: 'FREE account for more features',
}
export default nudgeBarMessages
