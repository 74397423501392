import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import FormContainer from 'components/FormContainer';
import Form from 'components/Form';
import { forgot } from 'services/auth.service';
import routes from 'constants/routes';
import { emailFieldConfiguration } from 'constants/defaultFieldsConfigurations';
import StyledButton from 'components/StyledButton/StyledButton';

import 'styles/customInput.css';
import FormParagraph from './FormParagraph/FormParagraph';

const fieldsConfig = [emailFieldConfiguration];

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const loginClick = () => history.push(routes.login);

  const handleResetPassword = async ({ email }) => {
    const user = await forgot(email, dispatch);

    if (!user) {
      return;
    }

    history.push(routes.resetPassword);
  };

  return (
    <FormContainer formTitle="Request Password Reset">
      <FormParagraph>
        Enter your email address and we&#39;ll send you
        an email with a link to reset your password.
      </FormParagraph>
      <Form
        fieldsConfig={fieldsConfig}
        onSubmit={handleResetPassword}
        buttonLabel="Request Password Reset"
      />
      <StyledButton
        style={{ marginTop: '10px'}}
        label="Login"
        onClick={loginClick}
      />
    </FormContainer>
  );
};

export default ForgotPassword;
