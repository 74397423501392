const routesREST = {
  location: '/location',
  alert: '/alert',
  alerts: '/alerts',
  defaultConfig: '/config/default',
  config: '/config',
  history: '/history',
  subscriptionManagePortal: '/subscription-management-portal/url',
  checkout: 'checkout/url',
  checkoutRecaptcha: 'checkout/recaptcha',
  searchResult: 'search/result',
  getQuickviewPhotos: 'getQuickviewPhotos',
  getAdditionalItemData: 'getAdditionalItemData',
  getFavoriteItems: 'getFavoriteItems',
  setFavoriteItem: 'setFavoriteItem',
  deleteFavoriteItem: 'deleteFavoriteItem',
  deleteAllFavoriteItems: 'deleteAllFavoriteItems',
  getSearchSuggestions: 'getSearchSuggestions',
  getSearchTermsWeights: 'getSearchTermsWeight',
  getMappingFile: 'https://rumage-category-mapping.s3.eu-west-2.amazonaws.com/mapping.json',
};

export default routesREST;
