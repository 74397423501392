import axios from 'axios';
import { useEffect, useState } from 'react';

const SplashContent = () => {
  const [splashContent, setSplashContent] = useState('');

  useEffect(() => {
    const fetchSplashContent = async () => {
      const { data: splashContent } = await axios.get(`https://rumage.com/wp-json/wp/v2/pages/${process.env.REACT_APP_SPLASH_CONTENT_POST_ID}`);
      setSplashContent(splashContent.content.rendered);
    };

    fetchSplashContent();
  }, []);

  return <div dangerouslySetInnerHTML={{ __html: splashContent }} />;
};

export default SplashContent;
