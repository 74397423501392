import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card } from 'primereact/card';
import { Dropdown } from 'primereact/dropdown';
import helpers from '../helpers';
import DateFormat from '../helpers/dateFormat';
import { alertAPI } from '../services/restapi.service';
import analytics from '../services/analytics.service';
import analyticsEvents from '../constants/analyticsEvents';
import { removeAlertItem, updateAlertItem } from '../redux/actions/alerts.actions';

const AlertItem = ({ alert = {} }) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const socket = useSelector((state) => state.socket);
  const jwtToken = useSelector((state) => state.user?.user?.idToken?.jwtToken) || null;
  const userTier = socket.config.user.tier;
  const allTiers = socket.config.service.tiers;
  const username = socket.config.user.username;
  const { maxLocations } = allTiers.filter((tier) => tier.id === userTier)[0];
  const maxExecutions = allTiers.filter((tier) => tier.id === userTier)[0].maxAlertExecutions;

  const deleteAlertHandle = () => {
    setIsLoading(true);
    alertAPI.deleteAlert(jwtToken, { submitted: alert.submitted }).then(() => {
      dispatch(removeAlertItem({ submitted: alert.submitted }));
      analytics.pushEvent(analyticsEvents.deleteAlert, {
        rumage_user_id: username || null,
        location_count: socket.config.user.locations.length,
        tier: socket.config.user.tier,
      });
    }).catch(() => setIsLoading(false));
  };
  const updateAlertHandle = ({ target: { value } }) => {
    dispatch(updateAlertItem({ submitted: alert.submitted, frequency: value.id }));
    alertAPI.updateAlert(jwtToken, {
      submitted: alert.submitted,
      frequency: value.id,
    });
  };

  const htmlGetFrequencyOptions = (frequency, submitted) => {
    const frequencies = socket.config.user.alertFrequencies;
    const isFrequencyAvailable = frequencies.find(({ id }) => id === frequency);
    const options = frequencies.reduce((output, value) => {
      output.push({
        name: value.name,
        value,
      });

      return output;
    }, []);

    if (!isFrequencyAvailable) {
      const alertFrequency = allTiers
        .reduce((result, tier) => [...result, ...tier.alertFrequencies], [])
        .find(({ id }) => id === frequency);

      options.push({
        name: alertFrequency.name,
        value: alertFrequency,
      });
    }

    const { value } = options.find(({ value: { id } }) => id === frequency);

    return (
      <Dropdown
        className="settings-alert-frequency"
        data-submitted={submitted}
        onChange={updateAlertHandle}
        value={value}
        options={options}
        optionLabel="name"
      />
    );
  };
  const locationDescriptions = alert.locations.split(';').map((location) => (
    <div key={`Location${location}`} className="location-description">
      {`${location.replace(/@/, ' ')}miles`}
    </div>
  ));

  return (
    <Card className="settings-alert-list-item" data-submitted={alert.submitted}>
      <button
        type="button"
        className="delete-alert clear-button"
        onClick={deleteAlertHandle}
      >
        <i className={isLoading ? 'pi pi-spin pi-spinner' : 'pi pi-times-circle'} />
      </button>
      <span className="settings-alert-term">{alert.term}</span>
      <div className="alert-details">
        <div className="alert-details-block">
          <i className="pi pi-bell" />
          {' '}
          {htmlGetFrequencyOptions(alert.frequency, alert.submitted)}
          {' '}
          <strong>
            {typeof alert.executions !== 'undefined' ? alert.executions : maxExecutions}
            {' '}
            {helpers.pluralise(maxExecutions, 'search')}
            {' '}
            left
          </strong>
          <br />
          <span className="small">
            {DateFormat(new Date(alert.scheduled), 'dd mmm yy "after" HH:MM')}
          </span>
        </div>
        <div className="alert-details-block">
          <i className="pi pi-map-marker" />
          {' '}
          {typeof alert.locations !== 'undefined' && (
            <>
              <strong>
                {alert.locations.split(';').length}
                {' '}
                {helpers.pluralise(alert.locations.split(';').length, 'location')}
              </strong>
              <br />
              <span className="small">{locationDescriptions}</span>
            </>
          )}
          {typeof alert.locations === 'undefined' && `${maxLocations} locations`}
        </div>
      </div>
    </Card>
  );
};

export default AlertItem;
