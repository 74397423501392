import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { Slider } from 'primereact/slider';
import {
  setSearchPriceFilter,
  filterSearchResults,
  clearPriceFilter,
  setAllAgentsCheckedStatus,
  putAllLocationsIntoFilteredLocations,
} from 'redux/actions/search.actions';
import FilterLocations from 'components/FilterLocations';
import FilterMarketplaces from 'components/FilterMarketplaces';
import htmlCurrencySymbols from 'constants/htmlCurrencySymbols';
import StyledButton from 'components/StyledButton/StyledButton';

import 'styles/searchFilter.css';
import ReactDOM from 'react-dom';
import { Backdrop } from '../Backdrop/Backdrop';

const FiltersContainer = ({ areFiltersShown, toggleFilters, handleDoneButton }) => {
  const currencySymbol = htmlCurrencySymbols.GBP;
  const priceFilter = useSelector((store) => store.search.priceFilter);
  const priceRange = useSelector((store) => store.search.priceRange);
  const dispatch = useDispatch();
  const [activeFilterTab, setActiveFilterTab] = useState('price');

  const updatePriceFilter = (event) => {
    const val = {
      min: event.value[0],
      max: event.value[1] === 0 ? 1 : event.value[1],
    };

    dispatch(setSearchPriceFilter(val));
  };

  const handleSelectAllButton = () => {
    dispatch(setAllAgentsCheckedStatus(true));
    dispatch(putAllLocationsIntoFilteredLocations(true));
    dispatch(clearPriceFilter());
    dispatch(filterSearchResults());
    toggleFilters();
  };

  return (
    <>
      {areFiltersShown &&
        ReactDOM.createPortal(<Backdrop onBackdropClick={toggleFilters} />, document.getElementById('backdrop-root'))}
      <div key="filterContainer" className={`search-filter${areFiltersShown ? ' open' : ''}`}>
        <div className="filters-header">
          <span className="filter-title" style={{fontWeight: '400'}}>
            Filter by
          </span>
          <div
            className={activeFilterTab === 'price' ? 'filter-title active' : 'filter-title'}
            onClick={() => setActiveFilterTab('price')}
          >
            <span>Price</span>
          </div>
          <div
            className={activeFilterTab === 'location' ? 'filter-title active' : 'filter-title'}
            onClick={() => setActiveFilterTab('location')}
          >
            <span>Location</span>
          </div>
          <div
            className={activeFilterTab === 'markets' ? 'filter-title active' : 'filter-title'}
            onClick={() => setActiveFilterTab('markets')}
          >
            <span>Market</span>
          </div>
        </div>
        <div className="filters-wrapper">
          {priceFilter.max && activeFilterTab === 'price' ? (
            <div className="filter-section">
              <div className="filter-section-header">Price</div>
              <div className="price-filter-slider">
                <span>
                  <span>
                    {currencySymbol} {priceFilter.min}
                  </span>{' '}
                  to{' '}
                  <span>
                    {currencySymbol} {priceFilter.max}
                  </span>
                </span>
                <div className="price-slider">
                  <Slider
                    value={[priceFilter.min, priceFilter.max]}
                    onChange={(event) => updatePriceFilter(event)}
                    onSlideEnd={()=>  dispatch(filterSearchResults())}
                    min={priceRange.min}
                    max={priceRange.max}
                    range
                  />
                </div>
              </div>
            </div>
          ) : null}
          {activeFilterTab === 'location' && (
            <FilterLocations  />
          )}

          {activeFilterTab === 'markets' && <FilterMarketplaces />}
        </div>
        <div className="filter-buttons-wrapper">
          <StyledButton
            label="Remove Filters"
            className="filter-button"
            onClick={handleSelectAllButton}
            useOnlyPropClass
          />
          <StyledButton label="Done" className="filter-button" onClick={handleDoneButton} useOnlyPropClass />
        </div>
      </div>
    </>
  );
};

export default FiltersContainer;
