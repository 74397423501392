import { clearAppReducer } from './app.actions';
import { clearMarketplacePanelReducer } from './marketplace.actions';
import { clearSearchReducer } from './search.actions';
import { clearSocketReducer } from './socket.actions';
import { clearUserReducer } from './user.actions';
import { clearAlertsReducer } from './alerts.actions';

export const clearState = (dispatch) => {
  dispatch(clearAppReducer());
  dispatch(clearMarketplacePanelReducer());
  dispatch(clearSearchReducer());
  dispatch(clearSocketReducer());
  dispatch(clearUserReducer());
  dispatch(clearAlertsReducer());
};
