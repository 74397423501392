import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { showWaitResults } from 'redux/actions/app.actions';
import {selectJwtToken, selectSearchState} from 'redux/store/selectors';
import Menu from '../Menu';
import SearchComponent from '../SearchComponent/SearchComponent';
import SearchResults from '../SearchResults';
import WaitResults from '../WaitResults';
import { isMobile } from 'react-device-detect';

import 'styles/mainPage.css';
import ShortcutsComponent from '../ShortcutsComponent/ShortcutsComponent';
import NoSearchResults from 'components/NoSearchResults';
import NoConnection from 'components/NoConnection';
import SignupNudge from '../CreateAlertNudge';
import {createAlert} from '../../helpers/alerts';
import storage from '../../services/localStorage.service';
import { isAnon } from 'services/user.service';
import NudgeBar from '../NudgeBar/NudgeBar';
import config from '../../helpers/environment';


const MainPage = () => {
  const dispatch = useDispatch();
  const headerRef = useRef();
  const jwtToken = useSelector(selectJwtToken) || null;
  const { searchResults } = useSelector(selectSearchState);
  const { showWaitResult } = useSelector((state) => state.app);
  const { alerts } = useSelector((state) => state.alerts);
  const {socket} = useSelector(state => state)
  const isSocketInitialized = socket.isSocketInitialized
  const isCookieAccepted = storage.getCookiesAccept()
  const [showNoResults, setShowNoResults] = useState(false);
  const [isOnline] = useState(window.navigator.onLine)
  const [signupNudgeVisible, setSignupNudgeVisible] = useState(false);
  const [hasUserInteractedWithResults, setHasUserInteractedWithResults] = useState(false)
  const [hasUserScrolled, setHasUserScrolled] = useState(false)
  const isUserAnon = isAnon(socket)

  const userTier = socket.config?.user.tier;
  const maxAlerts = socket.config?.service?.tiers.filter((tier) => tier.id === userTier)[0].maxAlerts;
  const maxSearchesBeforeNudge = socket.config?.service?.tiers.filter((tier) => tier.id === userTier)[0]
    .maxSearchesBeforeNudge;

  const handleScroll = (e) => {
    if (e.target.scrollTop >= 1500) {
      setHasUserInteractedWithResults(true)
    }
    if(e.target.scrollTop > 0) {
      setHasUserScrolled(true)
    }else {setHasUserScrolled(false)}
  }

  useEffect(() => {
    if (searchResults.length) {
      dispatch(showWaitResults(false));
    } else if (searchResults.length === 0) {
      setTimeout(() => {
        dispatch(showWaitResults(false));
        setShowNoResults(true);
      }, config.marketplaceSearchDelay);
    }
  }, [searchResults, dispatch]);

  useEffect(()=>{
    const searchCount = storage.getSearchCount() + 1
    if (searchCount >= maxSearchesBeforeNudge && searchCount % maxSearchesBeforeNudge === 0) {
      if (!alerts.length && isCookieAccepted && hasUserInteractedWithResults) setSignupNudgeVisible(true);
    }
  },[hasUserInteractedWithResults])

  const renderContent = () => {
    if (showWaitResult) {
      return <WaitResults />;
    } else if (!isSocketInitialized || !isOnline) {
      return <NoConnection />
    } else if (showNoResults && !searchResults.length) {
      return <NoSearchResults />;
    }
    return <SearchResults headerRef={headerRef} />;
  };
  const handleCreateAlert = () => {
    createAlert(alerts, maxAlerts, dispatch, socket, jwtToken, userTier, history);
  };

  return (
    <div className="main-page" onScroll={handleScroll}>
      {!isMobile && <Menu />}
      <div className="fixed-content no-padding" ref={headerRef}>
        <SearchComponent isSearchPage hasUserScrolled={hasUserScrolled} />
        {isUserAnon && <NudgeBar /> }
        <ShortcutsComponent />
      </div>
      {renderContent()}
      <SignupNudge isVisible={signupNudgeVisible} setIsVisible={setSignupNudgeVisible} tapAction={handleCreateAlert} />
    </div>
  );
};

export default MainPage;
