import { createReducer } from '@reduxjs/toolkit';
import {
  setUserAlerts,
  clearAlertsReducer,
  removeAlertItem,
  updateAlertItem,
  addNewAlert,
  setIsAlertCreating,
} from '../actions/alerts.actions';

const initialState = {
  alerts: [],
  isAlertCreating: false,
};

const alertsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setUserAlerts, (state, action) => ({
      ...state,
      alerts: action.payload,
    }))
    .addCase(clearAlertsReducer, () => ({
      ...initialState,
    }))
    .addCase(removeAlertItem, (state, action) => ({
      ...state,
      alerts: state.alerts.filter((alert) => alert.submitted !== action.payload.submitted),
    }))
    .addCase(updateAlertItem, (state, action) => ({
      ...state,
      alerts: state.alerts.map(
        (alert) => (alert.submitted === action.payload.submitted
          ? ({ ...alert, ...action.payload }) : alert),
      ),
    }))
    .addCase(addNewAlert, (state, action) => ({
      ...state,
      alerts: [...state.alerts, action.payload],
    }))
    .addCase(setIsAlertCreating, (state, action) => ({
      ...state,
      isAlertCreating: action.payload,
    }));
});

export default alertsReducer;
