const timestamp = Date.now()
const analytics = {
  pushEvent(eventName, options = null) {
    let _options= {...options}
    if (options?.rumage_user_id) {
      _options = options;
    } else if (options && !options.rumage_user_id) {
      _options = {...options, rumage_user_id: localStorage.getItem('userId') };
    } else {
      _options = { rumage_user_id: localStorage.getItem('userId') };
    }
    window.gtag('event', eventName, {..._options, rumage_timestamp:  timestamp});
  },

  pushPageChangeEvent() {

    if (typeof window.gtag === 'function') {
      window.gtag('config', process.env.REACT_APP_GTAG_CODE, {
        rumage_user_id: localStorage.getItem('userId'),
        page_title: document.title,
        page_location: window.location.href,
        page_path: window.location.pathname,
        rumage_timestamp:  timestamp
      });
    }
  },
};

export default analytics;
