import BackButton from 'components/BackButton';
import routes from 'constants/routes';

import 'styles/topPanel.css';

const CommonTab = ({ title, routeRedirect = routes.home }) => (
  <div className="top-panel">
    <BackButton routeRedirect={routeRedirect} />
    <span className="tab-title">{title}</span>
  </div>
);

export default CommonTab;
