import { Dialog } from 'primereact/dialog';
import {useDispatch, useSelector} from 'react-redux';
import { useHistory } from 'react-router';
import systemMessages from 'constants/systemMessages';
import routes from 'constants/routes';
import StyledButton from 'components/StyledButton/StyledButton';

import 'styles/createAlertNudge.css';
import {createAlert} from '../helpers/alerts';
import {selectJwtToken, selectTier} from '../redux/store/selectors';
import tiersTypes from '../constants/tiersTypes';
import {showToast} from '../redux/actions/app.actions';
import notificationEvents from '../constants/notificationEvents';

const UserNudgeDialog = ({ isVisible, setIsVisible }) => {
  const history = useHistory();
  const { user } = useSelector((state) => state.socket.config || {});
  const locations = user?.locations || []
  const { socket, alerts } = useSelector((state) => state);
  const dispatch = useDispatch()
  const jwtToken = useSelector(selectJwtToken) || null;
  const userTier = useSelector(selectTier);
  const maxAlerts = socket.config?.service?.tiers.filter((tier) => tier.id === userTier)[0].maxAlerts;
  const username = socket.config?.user.username;
  const userEmail = socket.config?.user.email;
  const handleNoClick = () => {
    setIsVisible(false);
  };
  const handleCreateAlerts = () => {
    const alertsLength = alerts?.length;

    if (username === tiersTypes.anon) {
      history.push(routes.signup);
      dispatch(
        showToast({
          text: systemMessages.pleaseRegister,
          type: notificationEvents.warn,
        })
      );
      return;
    }

    if (!maxAlerts) {
      dispatch(
        showToast({
          text: systemMessages.notAuthorizedUserAlerts,
          type: notificationEvents.warn,
        })
      );
      return;
    }

    if (alertsLength >= maxAlerts) {
      dispatch(
        showToast({
          text: systemMessages.maxAlertsLimitExceed,
          type: notificationEvents.warn,
        })
      );
      return;
    }

    if (!userEmail) {
      history.push(routes.settings);
      dispatch(
        showToast({
          text: systemMessages.shareMailAlerts,
          type: notificationEvents.warn,
        })
      );
      return;
    }
    createAlert(alerts, maxAlerts, dispatch, socket, jwtToken, userTier, history);
  };

  const renderFooter = () => (
    <div className="signup-nudge-footer" >
      <StyledButton
        label="Not yet"
        onClick={handleNoClick}
        className="p-button-text no-button "
        style={{ color: 'black', backgroundColor: 'white', borderRadius: '20px'}}
        useOnlyPropClass
      />
      <StyledButton
        label="Yes"
        onClick={() => history.push(locations.length ?  handleCreateAlerts() : routes.locations)}
        className="yes-button"
        style={{ borderRadius: '20px', border: 'none'}}
        useOnlyPropClass
      />
    </div>
  );

  return (
    <Dialog
      visible={isVisible}
      onHide={() => setIsVisible(false)}
      dismissableMask
      position="center"
      draggable={false}
      closable={false}
      footer={renderFooter()}
      className="nudge-container"
    >
      <p className="signup-nudge-content" >
        {locations.length ? systemMessages.setAnEmailAlert : systemMessages.findItemsLocally}
      </p>
    </Dialog>
  );
};

// eslint-disable-next-line no-unused-vars
const NudgeDialog = ({ isVisible, setIsVisible, tapAction }) => (
  <Dialog
    visible={isVisible}
    onHide={() => setIsVisible(false)}
    dismissableMask
    position="bottom-right"
    draggable={false}
    closable={false}
    className="create-alert-nudge-dialog"
  >
    <p className="create-alert-nudge-content" onClick={tapAction}>
      <span>
        <i className="pi pi-bell pi-bell-search" />
      </span>
      <span>{systemMessages.createAlertNudge}</span>
      <span
        onClick={(e) => {
          e.stopPropagation();
          setIsVisible(false);
        }}
      >
        <i className="pi pi-times" />
      </span>
    </p>
  </Dialog>
);

const CreateAlertNudge = ({ isVisible, setIsVisible }) => {

  return (
    <UserNudgeDialog isVisible={isVisible} setIsVisible={setIsVisible} />
  );
};

export default CreateAlertNudge;
