import { createReducer } from '@reduxjs/toolkit';
import {
  setSocketConfig,
  setSocketUserLocation,
  deleteUserLocation,
  setSocketReceipt,
  clearSocketReducer,
  setEmailNotification,
  setSocketInitialized,
} from 'redux/actions/socket.actions';
import userNotificationTypes from 'constants/userNotificationTypes';

const initialState = {
  isSocketInitialized: false,
  config: null,
  receipt: null,
};
const notifySeparator = ';';

const socketReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setSocketConfig, (state, action) => ({
      ...state,
      config: action.payload,
    }))
    .addCase(setSocketUserLocation, (state, action) => ({
      ...state,
      config: {
        ...state.config,
        user: {
          ...state.config.user,
          locations: [
            ...state.config.user.locations.slice(0, action.payload.slot - 1),
            { ...action.payload.data },
            ...state.config.user.locations.slice(action.payload.slot),
          ],
        },
      },
    }))
    .addCase(deleteUserLocation, (state, action) => ({
      ...state,
      config: {
        ...state.config,
        user: {
          ...state.config.user,
          locations: state.config.user.locations.filter(
            (item, index) => index !== action.payload - 1,
          ),
        },
      },
    }))
    .addCase(setSocketReceipt, (state, action) => ({
      ...state,
      receipt: action.payload,
    }))
    .addCase(clearSocketReducer, () => ({
      ...initialState,
    }))
    .addCase(setEmailNotification, (state, action) => {
      const oldNotify = state.config.user.notify.split(notifySeparator);
      const newNotify = (action.payload
        ? [...oldNotify, userNotificationTypes.emailNotification]
        : oldNotify.filter((item) => item !== userNotificationTypes.emailNotification)
      ).join(notifySeparator);
      return {
        ...state,
        config: {
          ...state.config,
          user: {
            ...state.config.user,
            notify: newNotify,
          },
        },
      };
    })
    .addCase(setSocketInitialized, (state, action) => ({
      ...state,
      isSocketInitialized: action.payload,
    }));
});
export default socketReducer;
