import React from 'react';
import { Card } from 'primereact/card';

import '../styles/waitResults.css';

const NoConnection = () => (
  <div className="wait-results-wrapper">
    <Card className="wait-results">
      <div className="wait-results-title">No connection!</div>
      <div className="wait-results-content">
        Please check your internet connection and come back!
      </div>
    </Card>
  </div>
);

export default NoConnection;
