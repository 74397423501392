import React from 'react';
import './GoToTopButton.css'

const ScrollButton = ({ pageRef }) =>{

  const scrollToTop = () => {
    pageRef.current.scrollIntoView();
  };

  return (
    <div className="scroll-top-button" onClick={scrollToTop} >
      <i className="pi pi-arrow-up" />
    </div>
  );
}

export default ScrollButton;

